import { TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { RootState } from '../../redux/store';
import { PERMISSION_TYPE, userPermission } from './../../helper/permission';
import PayoutGrid from './../payout/PayoutGrid';

const ConnectorList = () => {

  const connectorForm = {
    id: "",
    name: "",
    firm_name: "",
    email: "",
    password: "",
    mobile: "",
    pan_no: "",
    state: "",
    city: "",
    postal_code: "",
    payout_grid: [],
    name_error: null,
    firm_name_error: null,
    email_error: null,
    password_error: null,
    mobile_error: null,
    pan_no_error: null,
    state_error: null,
    city_error: null,
    postal_code_error: null
  }

  const [open, setOpen] = useState({
    isOpen: false,
    type: "Add"
  });
  const [formData, setFormData] = useState<any>(connectorForm);
  const [connectorDetail, setConnectorDetail] = useState([]);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [city, setCity] = useState<any>("");
  const [loader, setLoader] = useState(true);
  const [bankData, setBankData] = useState<any>({});
  const [permission, setPermission] = useState<any>({});
  const [addNewRow, setAddNewRow] = React.useState(false);
  const [popupStep, setPopUpStap] = useState<any>(false);
  const user = useSelector((state: RootState) => state?.authUser?.userData?.user)

  const getData = () => {
    ApiGet(`connector?page=${state.page}&limit=${state.limit}`)
      .then((res: any) => {
        let finalData = res.data.result.docs.map((item: any, index: number) => {
          return {
            id: item._id,
            SRNO: (((state.page - 1) * state.limit) + index + 1),
            name: item.name,
            firm_name: item.firm_name,
            email: item.email,
            mobile: item.mobile,
            pan_no: item.pan_no,
            state: item.state,
            city: item.city,
            postal_code: item.postal_code ?? [],
            payout_grid: item.payout_grid
          }
        })
        setConnectorDetail(finalData)

        const tempPermission = {
          [PERMISSION_TYPE.CONNECTOR_EDIT]: userPermission(user, PERMISSION_TYPE.CONNECTOR_EDIT),
          [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
        }
        setPermission(tempPermission)

        setState({
          ...state,
          totalItems: res.data.result.total
        })
      })
      .catch((error) => {
        const tempPermission = {
          [PERMISSION_TYPE.CONNECTOR_EDIT]: userPermission(user, PERMISSION_TYPE.CONNECTOR_EDIT),
          [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
        }
        setPermission(tempPermission)
        setConnectorDetail([])
      })
  }

  const getLoginCodeList = async () => {
    await ApiGet(`logincodelist`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            bank: item.bank_name,
            product: item.product,
            id: item._id
          }
        })
        const bankData = _.groupBy(temp, function (b) { return b.bank })
        setBankData(bankData)
      })
  }

  useEffect(() => {
    Promise.all([
      getData(),
      getLoginCodeList()
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [state.page, state.limit]);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePageData = (limit: number, page: number = 1) => {
    setState({
      ...state,
      limit,
      page
    })
  }

  const handlePage = (page: number) => {
    setState({
      ...state,
      page
    })
  }

  const addConnector = async (_id: string = "") => {
    let error = { ...formData };
    if (formData.name == "") {
      error = { ...error, name_error: "Please enter a name" };
    }
    if (formData.firm_name == "") {
      error = { ...error, firm_name_error: "Please enter a firm name" };
    }
    if (formData.email == "") {
      error = { ...error, email_error: "Please enter a email" };
    }
    if (formData.password == "") {
      error = { ...error, password_error: "Please enter a password" };
    }
    if (formData.mobile == "") {
      error = { ...error, mobile_error: "Please enter a mobile" };
    }
    if (formData.pan_no == "") {
      error = { ...error, pan_no_error: "Please enter a pan no" };
    }
    if (formData.state == "") {
      error = { ...error, state_error: "Please enter a state" };
    }
    if (formData.city == "") {
      error = { ...error, city_error: "Please enter a city" };
    }
    if (formData.postal_code == "") {
      error = { ...error, postal_code_error: "Please enter a postal code" };
    }
    setFormData(error)
    if (error.name_error == null && error.firm_name_error == null && error.email_error == null && error.password_error == null && error.mobile_error == null && error.pan_no_error == null && error.state_error == null && error.city_error == null && error.postal_code_error == null) {
      const { id, SRNO, name_error, firm_name_error, email_error, password_error, mobile_error, pan_no_error, state_error, city_error, postal_code_error, ...payload } = formData;
      if (open.type == "Add") {
        await ApiPost("connector", payload)
          .then((res: any) => {
            setFormData(connectorForm)
            getData();
            setOpen({ ...open, isOpen: false })
          })
          .catch((error) => {
            console.log(error);
          })
      } else {
        await ApiPut(`connector/${_id}`, payload)
          .then((res: any) => {
            setFormData(connectorForm);
            getData();
            setOpen({ ...open, isOpen: false })
          })
          .catch((error) => {
            console.log(error);
          })
      }
    }
  };

  // const columns = ["SR.NO.", "Name", "City", "State", "Contact No.", "Total Billing", "TotalLoan Amount"];
  let columns = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'Name', label: 'DSA Name' },
    { id: 'Contactno', label: 'Contact No.' },
    { id: 'City', label: 'City' },
    { id: 'State', label: 'State' },
  ];

  if (permission[PERMISSION_TYPE.CONNECTOR_EDIT]) {
    columns = [...columns, { id: 'Action', label: 'Action' }];
  }

  // const postget = async (e: any) => {
  //   const value = e.target.value;
  //   if (value.length >= 6) {
  //     await axios.get(`http://www.postalpincode.in/api/pincode/${value}`)
  //       .then((res: any) => {
  //         setFormData({
  //           ...formData,
  //           state: res?.data?.PostOffice?.[0]?.State ?? "",
  //           city: res?.data?.PostOffice?.[0]?.District ?? "",
  //           postal_code: value,
  //           city_error: null,
  //           state_error: null,
  //           postal_code_error: null
  //         });
  //       })
  //   }
  // }

  const checkStepValidation = () => {
    let error = { ...formData };


    //PAN validation
    let panVal = formData.pan_no;
    let firstFivePattern = /^[A-Za-z]{5}/;
    let firstFive = panVal.substring(0, 5);
    let sixtoNinePattern = /^[0-9]{4}/;
    let sixtoNine = panVal.substring(5, 9);
    let lastValPattern = /^[A-Za-z]{1}/;
    let lastVal = panVal.substring(9, 10);

    if (formData.pan_no == "") {
      error = { ...error, pan_no_error: "Please enter a pan no" };
    } else if (panVal.length != 10) {
      error = {
        ...error,
        pan_no_error: 'Length should be restricted to 10 digits and should not allow anything more or less'
      };
    } else if (!firstFivePattern.test(firstFive)) {
      error = {
        ...error, pan_no_error: 'First Five characters of PAN No should be alphabets'
      };
    } else if (!sixtoNinePattern.test(sixtoNine)) {
      error = {
        ...error, pan_no_error: 'Six to Nine characters of PAN No should be number'
      };
    } else if (!lastValPattern.test(lastVal)) {
      error = {
        ...error,
        pan_no_error: 'Last characters of PAN No should be alphabets'
      };
    } else (
      error = {
        ...error,
        pan_no_error: null
      })

    if (formData.name == "") {
      error = { ...error, name_error: "Please enter a name" };
    }
    if (formData.firm_name == "") {
      error = { ...error, firm_name_error: "Please enter a firm name" };
    }
    if (formData.email == "") {
      error = { ...error, email_error: "Please enter a email" };
    }
    if (formData.password == "") {
      error = { ...error, password_error: "Please enter a password" };
    }
    if (formData.mobile == "") {
      error = { ...error, mobile_error: "Please enter a mobile" };
    }
    if (formData.pan_no == "") {
      error = { ...error, pan_no_error: "Please enter a pan no" };
    }
    if (formData.state == "") {
      error = { ...error, state_error: "Please enter a state" };
    }
    if (formData.city == "") {
      error = { ...error, city_error: "Please enter a city" };
    }
    if (formData.postal_code == "") {
      error = { ...error, postal_code_error: "Please enter a postal code" };
    }
    let pincodePatten = /^(\d{6})$/;
    if (formData.postal_code != "" && !pincodePatten.test(formData.postal_code)) {
      error = { ...error, postal_code_error: "Please enter a valid postal codes" };
    }
    setFormData(error)
    if (error.name_error == null && error.firm_name_error == null && error.email_error == null && error.password_error == null && error.mobile_error == null && error.pan_no_error == null && error.state_error == null && error.city_error == null && error.postal_code_error == null) {
      setPopUpStap(true)
    }
  }

  const postget = async (e: any) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: e.target.value, postal_code_error: null, state_error: null, city_error: null })
    if (value.length >= 6) {
      await axios.get(`https://api.postalpincode.in/pincode/${value}`)
        .then((res: any) => {

          setFormData({
            ...formData,
            state: res?.data[0]?.PostOffice[0]?.State ?? "",
            city: res?.data[0]?.PostOffice[0]?.District ?? "",
            postal_code: value,
            city_error: null,
            state_error: null,
            postal_code_error: null
          });
          // setState(res?.data[0]?.PostOffice[0]?.State);
          // setCity(res?.data[0]?.PostOffice[0]?.District);
        })
    }
  }

  const payoutCal = (number: any) => {
    let calData: any = (number / 10).toFixed(3);
    let sub: any = number - calData;
    return sub;
  }

  return (<div className="overflow-x-hidden">
    {/* <Sidebar />
<Header /> */}
    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
              <h1 className="text-[22px] text-light-gray font-semibold" >Connector List</h1>

              {/* 
                        if(tab active) 
                            Add class "bg-white rounded-[18px] text-primary"
                        else 
                            Remove class "bg-white rounded-[18px] text-primary"
                    */}
              {!permission[PERMISSION_TYPE.ADMIN] &&
                <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                  <li>
                    <button className={`py-2 px-7 text-xs font-medium`} onClick={() => {
                      setOpen({ ...open, isOpen: true, type: "Add" });
                      setPopUpStap(false)
                      setFormData(connectorForm);
                    }}>Add Connector</button>
                  </li>
                </ul>
              }
            </div>

            {/* START::EXPORT */}
            <ul className="flex space-x-[18px]">
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/pdf.svg" alt="" />
                  <span>PDF</span>
                </button>
              </li>
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/excel.svg" alt="" />
                  <span>EXCEL</span>
                </button>
              </li>
            </ul>
            {/* END::EXPORT */}
          </div>
          {/* END::DASHBOARD HEADER */}

          <TableComponent
            rows={connectorDetail}
            columns={columns}
            handlePageData={handlePageData}
            handlePage={handlePage}
            state={state}
            renderColumn={(column: any) => {
              return (
                <TableCell align="center"
                  className="!bg-gray-200"
                  key={column.id}
                  style={{ background: "gray" }}
                >
                  {column.label}
                </TableCell>
              )
            }}
            renderRow={(row: any) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell align="center" component="th" scope="row">
                    {row.SRNO}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.mobile}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.city}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.state}
                  </TableCell>
                  {permission[PERMISSION_TYPE.CONNECTOR_EDIT] &&
                    <TableCell align="center" component="th" scope="row">
                      <img className='mx-auto' onClick={(e) => {
                        e.preventDefault();
                        setOpen({ ...open, isOpen: true, type: "Edit" });
                        setPopUpStap(false)
                        setFormData(row);
                      }} src="assets/edit-connector.svg" alt="" />
                    </TableCell>
                  }

                </TableRow>
              )
            }}
          />
        </div>
        {/* </main> */}

        {/* Start::Connector List Modal */}
        <div className={`tw-modal ${open.isOpen ? 'flex' : 'hidden'} !items-start`}>
          <div className="tw-modal-content !max-w-[1000px]">
            <div className="tw-modal-title">
              <div>Connector List</div>
              <button onClick={() => setOpen({ ...open, isOpen: false })}>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>
            {popupStep ?
              <div className='tw-modal-body'>
                <div className="overflow-x-auto">
                  <table className='w-full'>
                    <thead className='text-sm text-light-gray bg-gray-200'>
                      <tr>
                        <th className='p-3 min-w-[200px]'>Financer Name</th>
                        <th className='p-3 min-w-[200px]'>Product</th>
                        <th className='p-3 min-w-[200px]'>Normal Payout Grid</th>
                        <th className='p-3 min-w-[200px]'>Advance Payout Grid</th>
                      </tr>
                    </thead>
                    <tbody className='w-full bg-gray-100 text-sm text-light-gray'>
                      {[]?.map((details: any) => (
                        <tr className='border-b'>
                          <td className='p-3 text-center'>{details.Amount}</td>
                          <td className='p-3 text-center'>{details.Normal}</td>
                          <td className='p-3 text-center'>{details.Advance}</td>
                        </tr>
                      ))}
                      {formData.payout_grid.map((payout: any, index: number) => (
                        <tr className='border-b'>
                          <td className='p-3 text-center'>
                            <div id="financer_name" className={`input-wrap py-2 bg-white`}>
                              <img src="assets/bank.svg" alt="" />
                              <select name="financer_name" className="w-full text-sm text-[#808080] bg-transparent" value={payout?.bank_name} onChange={(e) => {
                                let temp: any = [...formData.payout_grid]
                                temp[index] = { ...temp[index], bank_name: e.target.value };
                                setFormData({ ...formData, payout_grid: temp });
                              }}>
                                <option value="" selected>Select Bank/NBFC Name</option>
                                {Object.keys(bankData)?.map((bank: any, id: any) => (
                                  <option value={bank}>{bank}</option>
                                ))}
                              </select>
                            </div>
                          </td>
                          <td className='p-3 text-center'>
                            <div id="product" className={`input-wrap py-2 bg-white`}>
                              <img src="assets/product.svg" alt="" />
                              <select className="bg-transparent w-full text-sm text-[#808080]" name="product" value={payout?.product} onChange={(e) => {
                                let temp: any = [...formData.payout_grid]
                                temp[index] = { ...temp[index], product: e.target.value };
                                setFormData({ ...formData, payout_grid: temp });
                              }}>
                                <option value="" selected>Select Product</option>
                                {bankData?.[payout?.bank_name]?.map((item: any) => (
                                  <option value={item.id}>{item.product}</option>
                                ))}
                              </select>
                            </div>
                          </td>
                          <td className='p-3 text-center'>
                            <input
                              className='w-full py-2 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                              type="text"
                              name="normal_payout_grid"
                              placeholder='2.45%'
                              value={payout?.normal_payout_grid}
                              onChange={(e: any) => {
                                let temp: any = [...formData.payout_grid]
                                temp[index] = { ...temp[index], normal_payout_grid: e.target.value };
                                let advance_payout: any = payoutCal(e.target.value)
                                temp[index] = { ...temp[index], advance_payout_grid: advance_payout };
                                console.log(temp, '*---')
                                setFormData({ ...formData, payout_grid: temp });
                              }}
                            />
                          </td>
                          <td className='p-3 text-center'>
                            <input
                              className='w-full py-2 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                              type="text"
                              name="advance_payout_grid"
                              placeholder='2.45%'
                              value={payout?.advance_payout_grid}
                              disabled
                            // onChange={(e: any) => {
                            //   let temp: any = [...formData.payout_grid]
                            //   temp[index] = { ...temp[index], advance_payout_grid: e.target.value };
                            //   setFormData({ ...formData, payout_grid: temp });
                            // }}
                            />
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </table>
                </div>
                <div className="flex items-center justify-between">
                  <button className="flex items-center justify-end space-x-1 text-sm border py-1 px-2 border-primary rounded-md" onClick={() => {
                    setFormData({ ...formData, payout_grid: [...formData.payout_grid, {}] });
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                    <span>Add New Row</span>
                  </button>
                  {/* <button className="flex items-center justify-end space-x-1 text-sm border py-1 px-2 border-primary rounded-md" onClick={() => {
                    setAddNewRow(true);
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                    <span>Save PayoutGrid</span>
                  </button> */}
                </div>
              </div>
              :
              <div className='tw-modal-body'>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label htmlFor="name" className={`input-label ${formData.name_error ? "text-red-500" : ""}`}>Name</label>
                    <div id="name" className={`input-wrap ${formData.name_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="name" value={formData.name} onChange={(e: any) => {
                        setFormData({ ...formData, name: e.target.value, name_error: null });
                      }} className="w-full text-sm" placeholder="Enter Name" />
                    </div>
                    <p className="text-red-500 text-sm">{formData.name_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="firm_name" className={`input-label ${formData.firm_name_error ? "text-red-500" : ""}`}>Firm Name</label>
                    <div id="firm_name" className={`input-wrap ${formData.firm_name_error ? "border-red-500" : ""}`}>
                      <img src="assets/firm.svg" alt="" />
                      <input type="text" name="firm_name" value={formData.firm_name} onChange={(e: any) => {
                        setFormData({ ...formData, firm_name: e.target.value, firm_name_error: null });
                      }} className="w-full text-sm" placeholder="Enter Firm Name" />
                    </div>
                    <p className="text-red-500 text-sm">{formData.firm_name_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="email" className={`input-label ${formData.email_error ? "text-red-500" : ""}`}>Email</label>
                    <div id="email" className={`input-wrap ${formData.email_error ? "border-red-500" : ""}`}>
                      <img src="assets/email.svg" alt="" />
                      <input type="text" name="email" value={formData.email} onChange={(e: any) => {
                        setFormData({ ...formData, email: e.target.value, email_error: null });
                      }} className="w-full text-sm" placeholder="Enter Email" />
                    </div>
                    <p className="text-red-500 text-sm">{formData.email_error}</p>
                  </div>
                  {open.type == "Add" &&
                    <div className="space-y-1">
                      <label htmlFor="password" className={`input-label ${formData.password_error ? "text-red-500" : ""}`}>Password</label>
                      <div id="password" className={`input-wrap ${formData.password_error ? "border-red-500" : ""}`}>
                        <img src="assets/email.svg" alt="" />
                        <input type="text" name="password" value={formData.password} onChange={(e: any) => {
                          setFormData({ ...formData, password: e.target.value, password_error: null });
                        }} className="w-full text-sm" placeholder="Enter Password" />
                      </div>
                      <p className="text-red-500 text-sm">{formData.password_error}</p>
                    </div>
                  }
                  <div className="space-y-1">
                    <label htmlFor="mobile" className={`input-label ${formData.mobile_error ? "text-red-500" : ""}`}>Mobile No.(Whatsapp)</label>
                    <div id="mobile" className={`input-wrap ${formData.mobile_error ? "border-red-500" : ""}`}>
                      <img src="assets/phone.svg" alt="" />
                      <input type="number" maxLength={10} minLength={10} name="mobile" value={formData.mobile} onChange={(e: any) => {
                        setFormData({ ...formData, mobile: e.target.value, mobile_error: null });
                      }} className="w-full text-sm" placeholder="Enter Mobile No.(whatsapp)" />
                    </div>
                    <p className="text-red-500 text-sm">{formData.mobile_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pan_no" className={`input-label ${formData.pan_no_error ? "text-red-500" : ""}`}>PAN No.</label>
                    <div id="pan_no" className={`input-wrap ${formData.pan_no_error ? "border-red-500" : ""}`}>
                      <img src="assets/card.svg" alt="" />
                      <input type="text" name="pan_no" maxLength={10} value={formData.pan_no} onChange={(e: any) => {
                        setFormData({ ...formData, pan_no: e.target.value, pan_no_error: null });
                      }} className="w-full text-sm" placeholder="Enter PAN No." />
                    </div>
                    <p className="text-red-500 text-sm">{formData.pan_no_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="postal_code" className={`input-label ${formData.postal_code_error ? "text-red-500" : ""}`}>Postal Code</label>
                    <div id="postal_code" className={`input-wrap ${formData.postal_code_error ? "border-red-500" : ""}`}>
                      <img src="assets/location.svg" alt="" />
                      <input type="text" name="postal_code" maxLength={6} value={formData.postal_code} onChange={(e: any) => {
                        setFormData({ ...formData, postal_code: e.target.value, postal_code_error: null }); postget(e);
                      }} className="w-full text-sm" placeholder="Enter Postal Code" />
                    </div>
                    <p className="text-red-500 text-sm">{formData.postal_code_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="city" className={`input-label ${formData.city_error ? "text-red-500" : ""}`}>City</label>
                    <div id="city" className={`input-wrap ${formData.city_error ? "border-red-500" : ""}`}>
                      <img src="assets/location.svg" alt="" />
                      <input type="text" name="city" value={formData.city} onChange={(e: any) => {
                        setFormData({ ...formData, city: e.target.value, city_error: null });
                      }} className="w-full text-sm" placeholder="Enter City" disabled />
                    </div>
                    <p className="text-red-500 text-sm">{formData.city_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="state" className={`input-label ${formData.state_error ? "text-red-500" : ""}`}>State</label>
                    <div id="state" className={`input-wrap ${formData.state_error ? "border-red-500" : ""}`}>
                      <img src="assets/location.svg" alt="" />
                      <input type="text" name="state" value={formData.state} onChange={(e: any) => {
                        setFormData({ ...formData, state: e.target.value, state_error: null });
                      }} className="w-full text-sm" placeholder="Enter State" disabled />
                    </div>
                    <p className="text-red-500 text-sm">{formData.state_error}</p>
                  </div>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center space-x-[10px]">
                    <input type="checkbox" id="remember" name="agreeteam" className="" />
                    <label htmlFor="remember" className="">I agree with Privacy Policy.</label>
                  </div>
                </div>
              </div>
            }
            <div className='tw-modal-footer'>
              {popupStep ?
                <>
                  <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => setPopUpStap(false)}>Previous</button>
                  <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => addConnector(formData?.id)}>Submit</button>
                </>
                :
                <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => checkStepValidation()}>Next</button>
              }

              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() =>
                setOpen({ ...open, isOpen: false })}>Cancel</button>
            </div>
          </div>
        </div>
        {/* End::Connector List Modal */}
      </>
    }
  </div>)
}

export default ConnectorList