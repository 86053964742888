import { TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPost } from '../../helper/API/ApiData';
import { PERMISSION_TYPE, userPermission } from '../../helper/permission';
import { RootState } from '../../redux/store';
// import Header from './components/header'
// import Sidebar from './components/sidebar'
function LoginCodeList() {

  const loginCodeListData = {
    bank_name: "",
    dsa_name: "",
    dsa_code: "",
    product: "",
    city: "",
    sm_name: "",
    email: "",
    phone: "",
    margin: "",
    // adjustment_ratio: "",
    // adjustment_ratio_name: "",
    bank_name_error: null,
    dsa_name_error: null,
    dsa_code_error: null,
    product_error: null,
    city_error: null,
    sm_name_error: null,
    email_error: null,
    phone_error: null,
    margin_error: null,
    // adjustment_ratio_error: null,
    // adjustment_ratio_name_error: null,
  }

  const columns = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'bank', label: 'Bank/NBFC Name' },
    { id: 'DSACode', label: 'DSA Code' },
    { id: 'DSAName', label: 'DSA NAME' },
    { id: 'product', label: 'Product' },
  ];


  const [formData, setFormData] = useState<any>(loginCodeListData);
  const [addOpen, setAddOpen] = useState(false);
  const [loginCodeListDetail, setLoginCodeListDetail] = useState([]);
  const [loader, setLoader] = useState(true);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [permission, setPermission] = useState<any>({});
  const user = useSelector((state: RootState) => state?.authUser?.userData?.user)
  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getLoginCodeList = async () => {
    await ApiGet(`loginCodeList/codeList?page=${state.page}&limit=${state.limit}`)
      .then((res: any) => {
        let temp = res.data.result.docs.map((item: any, index: number) => {
          return {
            SRNO: (((state.page - 1) * state.limit) + index + 1),
            bank: item.bank_name,
            DSACode: item.dsa_code,
            DSAName: item.dsa_name,
            product: item.product,
          }
        })
        setLoginCodeListDetail(temp)

        const tempPermission = {
          [PERMISSION_TYPE.ADD_LOGIN_CODE]: userPermission(user, PERMISSION_TYPE.ADD_LOGIN_CODE)
        }
        setPermission(tempPermission)

        setState({
          ...state,
          totalItems: res.data.result.total
        })
      }).catch((error) => {
        const tempPermission = {
          [PERMISSION_TYPE.ADD_LOGIN_CODE]: userPermission(user, PERMISSION_TYPE.ADD_LOGIN_CODE)
        }
        setPermission(tempPermission)
      });
  }

  useEffect(() => {
    Promise.all([
      getLoginCodeList()
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [state.page, state.limit])


  const handlePageData = (limit: number, page: number = 1) => {
    setState({
      ...state,
      limit,
      page
    })
  }

  const handlePage = (page: number) => {
    setState({
      ...state,
      page
    })
  }

  const addLoginCodeList = () => {
    let error = { ...formData };
    if (formData.bank_name == "") {
      error = { ...error, bank_name_error: "Please enter a bank name" };
    }
    if (formData.dsa_name == "") {
      error = { ...error, dsa_name_error: "Please enter a dsa name" };
    }
    if (formData.dsa_code == "") {
      error = { ...error, dsa_code_error: "Please enter a dsa code" };
    }
    if (formData.product == "") {
      error = { ...error, product_error: "Please enter a product" };
    }
    if (formData.city == "") {
      error = { ...error, city_error: "Please enter a city" };
    }
    if (formData.margin == "") {
      error = { ...error, margin_error: "Please enter a margin" };
    }
    // if (formData.adjustment_ratio == "") {
    //   error = { ...error, adjustment_ratio_error: "Please enter a adjustment ratio" };
    // }
    // if (formData.adjustment_ratio_name == "") {
    //   error = { ...error, adjustment_ratio_name_error: "Please enter a adjustment ratio name" };
    // }
    setFormData(error)
    if (error.bank_name_error == null && error.dsa_name_error == null && error.dsa_code_error == null && error.product_error == null && error.city_error == null && error.margin_error == null) {
      const { bank_name_error, dsa_name_error, dsa_code_error, product_error, city_error, email_error, sm_name_error, phone_error, margin_error, ...payload } = formData
      ApiPost("loginCodeList", payload)
        .then((res: any) => {
          setFormData(loginCodeListData);
          getLoginCodeList();
          setAddOpen(false);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }
  return (<div className="overflow-x-hidden">

    {/* <Sidebar />
    <Header /> */}
    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>
        {/* START::MAIN CONTENT */}
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
              <h1 className="text-[22px] text-light-gray font-semibold">Login Code List</h1>
              {permission[PERMISSION_TYPE.ADD_LOGIN_CODE] &&
                <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                  <li>
                    <button className={`py-2 px-7 text-xs font-medium`} onClick={(e) => {
                      e.preventDefault();
                      setAddOpen(true)
                    }}>Add Code List Data</button>
                  </li>
                </ul>
              }
            </div>

            {/* START::EXPORT */}
            <ul className="flex space-x-[18px]">
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/pdf.svg" alt="" />
                  <span>PDF</span>
                </button>
              </li>
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/excel.svg" alt="" />
                  <span>EXCEL</span>
                </button>
              </li>
            </ul>
            {/* END::EXPORT */}
          </div>
          {/* END::DASHBOARD HEADER */}
          <TableComponent
            rows={loginCodeListDetail}
            columns={columns}
            handlePageData={handlePageData}
            handlePage={handlePage}
            state={state}
            renderColumn={(column: any) => {
              return (
                <TableCell align="center"
                  className="!bg-gray-200"
                  key={column.id}
                  style={{ background: "gray" }}
                >
                  {column.label}
                </TableCell>
              )
            }}
            renderRow={(row: any) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell align="center" component="th" scope="row">
                    {row.SRNO}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.bank}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.DSACode}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.DSAName}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.product}
                  </TableCell>
                </TableRow>
              )
            }}
          />
        </div>
        {/* </main> */}

        {/* Start::Add Disbursement Data Modal */}
        <div className={`tw-modal ${addOpen ? 'flex' : 'hidden'} !items-start`}>
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>Add CodeList Data</div>
              <button onClick={() => setAddOpen(false)}>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>

            <div className='tw-modal-body'>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                <div className="space-y-1">
                  <label htmlFor="bank_name" className={`input-label ${formData.bank_name_error ? "text-red-500" : ""}`}>Bank Name</label>
                  <div id="bank_name" className={`input-wrap ${formData.bank_name_error ? "border-red-500" : ""}`}>
                    <img src="assets/user.svg" alt="" />
                    <input type="text" name="bank_name" value={formData.bank_name} onChange={(e: any) => {
                      setFormData({ ...formData, bank_name: e.target.value, bank_name_error: null });
                    }} className="w-full text-sm" placeholder="Enter Bank Name" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.bank_name_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="dsa_name" className={`input-label ${formData.dsa_name_error ? "text-red-500" : ""}`}>DSA Name</label>
                  <div id="dsa_name" className={`input-wrap ${formData.dsa_name_error ? "border-red-500" : ""}`}>
                    <img src="assets/firm.svg" alt="" />
                    <input type="text" name="dsa_name" value={formData.dsa_name} onChange={(e: any) => {
                      setFormData({ ...formData, dsa_name: e.target.value, dsa_name_error: null });
                    }} className="w-full text-sm" placeholder="Enter DSA Name" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.dsa_name_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="dsa_code" className={`input-label ${formData.dsa_code_error ? "text-red-500" : ""}`}>DSA Code</label>
                  <div id="dsa_code" className={`input-wrap ${formData.dsa_code_error ? "border-red-500" : ""}`}>
                    <img src="assets/email.svg" alt="" />
                    <input type="text" name="dsa_code" value={formData.dsa_code} onChange={(e: any) => {
                      setFormData({ ...formData, dsa_code: e.target.value, dsa_code_error: null });
                    }} className="w-full text-sm" placeholder="Enter DSA Code" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.dsa_code_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="product" className={`input-label ${formData.product_error ? "text-red-500" : ""}`}>Product</label>
                  <div id="product" className={`input-wrap ${formData.product_error ? "border-red-500" : ""}`}>
                    <img src="assets/product.svg" alt="" />
                    <input type="text" name="product" value={formData.product} onChange={(e: any) => {
                      setFormData({ ...formData, product: e.target.value, product_error: null });
                    }} className="w-full text-sm" placeholder="Enter Product" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.product_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="city" className={`input-label ${formData.city_error ? "text-red-500" : ""}`}>City</label>
                  <div id="city" className={`input-wrap ${formData.city_error ? "border-red-500" : ""}`}>
                    <img src="assets/bank.svg" alt="" />
                    <input type="text" name="city" value={formData.city} onChange={(e: any) => {
                      setFormData({ ...formData, city: e.target.value, city_error: null });
                    }} className="w-full text-sm" placeholder="Enter City" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.city_error}</p>
                </div>

                <div className="space-y-1">
                  <label htmlFor="sm_name" className="input-label">SM Name</label>
                  <div id="sm_name" className="input-wrap">
                    <img src="assets/date.svg" alt="" />
                    <input type="text" name="sm_name" value={formData.sm_name} onChange={(e: any) => { handleChange(e) }} className="w-full text-sm" placeholder="Enter Sm Name" />
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="email" className="input-label">Email</label>
                  <div id="email" className="input-wrap">
                    <img src="assets/email.svg" alt="" />
                    <input type="text" name="email" value={formData.email} onChange={(e: any) => { handleChange(e) }} className="w-full text-sm" placeholder="Enter Email" />
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="phone" className="input-label">Mobile No.(Optional)</label>
                  <div id="phone" className="input-wrap">
                    <img src="assets/phone.svg" alt="" />
                    <input type="text" name="phone" value={formData.phone} onChange={(e: any) => { handleChange(e) }} className="w-full text-sm" placeholder="Enter Mobile No." />
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="phone" className="input-label">Margin</label>
                  <div id="phone" className="input-wrap">
                    <img src="assets/product.svg" alt="" />
                    <input type="text" name="margin" value={formData.margin} onChange={(e: any) => {
                      setFormData({ ...formData, margin: e.target.value, margin_error: null });
                    }} className="w-full text-sm" placeholder="Enter Margin" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.margin_error}</p>
                </div>
                {/* <div className="space-y-1">
                  <label htmlFor="phone" className="input-label">Adjustment Ratio</label>
                  <div id="product" className={`input-wrap ${formData.product_error ? "border-red-500" : ""}`}>
                    <img src="assets/product.svg" alt="" />
                    <input type="text" name="adjustment_ratio" value={formData.adjustment_ratio} onChange={(e: any) => {
                      setFormData({ ...formData, adjustment_ratio: e.target.value, adjustment_ratio_error: null });
                    }} className="w-full text-sm" placeholder="Enter Margin" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.adjustment_ratio_error}</p>
                </div> */}
                {/* <div className="space-y-1">
                  <label htmlFor="phone" className="input-label">Adjustment Ratio Name</label>
                  <div id="adjustment_ratio_name" className={`input-wrap ${formData.product_error ? "border-red-500" : ""}`}>
                    <img src="assets/product.svg" alt="" />
                    <input type="text" name="adjustment_ratio_name" value={formData.adjustment_ratio_name} onChange={(e: any) => {
                      setFormData({ ...formData, adjustment_ratio_name: e.target.value, adjustment_ratio_name_error: null });
                    }} className="w-full text-sm" placeholder="Enter Margin" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.adjustment_ratio_name_error}</p>
                </div> */}
                {/* {formData.adjustment_ratio == "other" &&
                  <div className="space-y-1">
                    <div id="product" className={`input-wrap ${formData.product_error ? "border-red-500" : ""}`}>
                      <img src="assets/product.svg" alt="" />
                      <input type="text" name="margin" value={formData.margin} onChange={(e: any) => {
                        setFormData({ ...formData, margin: e.target.value, margin_error: null });
                      }} className="w-full text-sm" placeholder="Other" />
                    </div>
                  </div>
                } */}
              </div>
            </div>

            <div className='tw-modal-footer'>
              <button className='tw-modal-footer-button bg-secondary text-white' onClick={addLoginCodeList}>Save</button>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => setAddOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
        {/* End::Add Disbursement Data Modal */}
        {/* END::MAIN CONTENT */}
      </>
    }
  </div>)
}

export default LoginCodeList