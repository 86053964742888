import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData'
import { getImageUrl, isEmpty } from '../../helper/util'

const Kyc = () => {

  const main_data = {
    business_image: "",
    business_image_link: "",
    profile_image: "",
    profile_image_link: "",
    pan_card: "",
    pan_card_error: null,
    pan_card_link: "Choose Pan Card"
  }
  const bank_data = {
    account_number: "",
    account_number_error: null,
    ifsc: "",
    ifsc_error: null,
    cheque_image: "",
    cheque_image_error: null,
    cheque_image_link: "Choose Cheque",
  }
  const gst_data = {
    gst_image: "",
    gst_image_error: null,
    gst_image_link: "Choose Gst",
  }
  const status = {
    profile_status: "",
    cheque_status: "",
    gst_status: ""
  }

  const [formData, setFormData] = useState<any>({ ...main_data, ...bank_data, ...gst_data, ...status });
  const [UserDetails, setUserDetails] = useState<any>([]);
  
  const [gstData, setGstData] = useState<any>({
    gstNo: "",
    gstNo_error: null,
  });
  const [loader, setLoader] = useState(true);

  const getData = () => {
    ApiGet(`kyc/kyc-details`)
      .then((res: any) => {
        let finalData = {
          business_image_link: getImageUrl(res?.data?.user?.business_image),
          profile_image_link: getImageUrl(res?.data?.user?.profile_image),
          pan_card_link: isEmpty(res?.data?.user?.pan_card_image_name) ? "Choose Pan Card" : res?.data?.user?.pan_card_image_name,
          cheque_image_link: isEmpty(res?.data?.user?.cheque_image_name) ? "Choose Cheque" : res?.data?.user?.cheque_image_name,
          gst_image_link: isEmpty(res?.data?.user?.gst_image_name) ? "Choose Gst" : res?.data?.user?.gst_image_name,
          account_number: res?.data?.user?.account_number ?? "",
          ifsc: res?.data?.user?.ifsc ?? "",
          profile_status: res?.data?.user?.profile_status ?? "",
          cheque_status: res?.data?.user?.cheque_status ?? "",
          gst_status: res?.data?.user?.gst_status ?? ""
        }
        setFormData({ ...formData, ...finalData })
      })
      .catch((error) => {
      })
  }

  const getUserData = () => {
    ApiGet(`kyc/get-user`)
      .then((res: any) => {
        setGstData({ ...gstData, gstNo: res?.data?.user?.gst_no ?? "" })
      })
      .catch((error) => {
      })
  }

  useEffect(() => {
    Promise.all([
      getData(),
      getUserData()
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, []);

  const handleKyc = async (key: string) => {
    let error = { ...formData };
    if (key === "main") {
      if (formData.pan_card_link == "Choose Pan Card") {
        error = { ...error, pan_card_error: "Please Upload Document" };
      }
      const form_data = new FormData();
      form_data.append('business_image', formData.business_image)
      form_data.append('profile_image', formData.profile_image)
      form_data.append('pan_card_image', formData.pan_card)
      if (error.pan_card_error == null) {
        await ApiPost("kyc/upload-detail", form_data).then(async (response: any) => {
          getData();
          if (response.data && response.success) {
            toast.success("upload Successfully.", {
              position: "top-right",
              theme: "colored"
            })
          } else {
            console.log("No Data found")
          }
        }).catch((error: any) => console.log("Error", error))
      }
    }
    if (key === "bank") {
      if (formData.account_number == "") {
        error = { ...error, account_number_error: "Please Upload Document" };
      }
      if (formData.ifsc == "") {
        error = { ...error, ifsc_error: "Please Upload Document" };
      }
      if (formData.cheque_image_link == "Choose Cheque") {
        error = { ...error, cheque_image_error: "Please Upload Document" };
      }
      const form_data = new FormData();
      form_data.append('account_number', formData.account_number)
      form_data.append('ifsc', formData.ifsc)
      form_data.append('cheque_image', formData.cheque_image)
      if (error.account_number_error == null && error.ifsc_error == null && error.cheque_image_error == null) {
        await ApiPost("kyc/upload-bank-details", form_data).then(async (response: any) => {
          getData();
          if (response.data && response.success) {
            toast.success("upload Successfully.", {
              position: "top-right",
              theme: "colored"
            })
          } else {
            console.log("No Data found")
          }
        }).catch((error: any) => console.log("Error", error))
      }
    }
    setFormData(error)
  }

  const getBasicDetails = async () => {
    await ApiGet(`userReffered/setting`)
        .then((res: any) => {
          console.log(res?.data)
            setUserDetails(res?.data?.user);
        })
}

useEffect(() => {
  getBasicDetails()
},[])

  const handleGSTData = async () => {
    let error = { ...formData };
    let errorGST = { ...gstData };


   

    //PAN validation
    let panVal = UserDetails?.pan_no;

    //GST validation
    let mobilePatten = /^\d+$/;
    let emailPatten = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let gstVal = gstData.gstNo;
    let statecode = gstVal.substring(0, 2);
    let patternstatecode = /^[0-9]{2}$/
    let threetoseven = gstVal.substring(2, 7);
    let patternthreetoseven = /^[a-zA-Z]{5}$/
    let seventoten = gstVal.substring(7, 11);
    let patternseventoten = /^[0-9]{4}$/
    let Twelveth = gstVal.substring(11, 12);
    let patternTwelveth = /^[a-zA-Z]{1}$/
    let Thirteen = gstVal.substring(12, 13);
    let patternThirteen = /^[1-9a-zA-Z]{1}$/
    let fourteen = gstVal.substring(13, 14);
    let patternfourteen = /^[zZ]{1}$/
    let fifteen = gstVal.substring(14, 15);
    let patternfifteen = /^[0-9a-zA-Z]{1}$/
    if (gstVal != "") {
      if (gstVal.length != 15) {
          error = {
              ...error,
              gstNo_error: 'Length should be restricted to 15 digits and should not allow anything more or less'
          };
      } else if (!patternstatecode.test(statecode)) {
          error = {
              ...error, gstNo_error: 'First two characters of GST No should be numbers'
          };
      } else if (!patternthreetoseven.test(threetoseven)) {
          error = {
              ...error,
              gstNo_error: 'Third to seventh characters of GST No should be alphabets'
          };
      } else if (!patternseventoten.test(seventoten)) {
          error = {
              ...error,
              gstNo_error: 'Eighth to Eleventh characters of GST No should be numbers'
          };
      } else if (!patternTwelveth.test(Twelveth)) {
          error = {
              ...error,
              gstNo_error: 'Twelveth character of GST No should be alphabet'
          };
      } else if (!patternThirteen.test(Thirteen)) {
          error = {
              ...error,
              gstNo_error: 'Thirteen characters of GST No can be either alphabet or numeric'
          };
      } else if (!patternfourteen.test(fourteen)) {
          error = {
              ...error,
              gstNo_error: 'Fourteen characters of GST No should be Z'
          };
      } else if (!patternfifteen.test(fifteen)) {
          error = {
              ...error,
              gstNo_error: 'Fifteen characters of GST No can be either alphabet or numeric'
          };
      }
       else if (!gstVal.toUpperCase().includes(panVal.toUpperCase())) {
          error = { ...error, gstNo_error: "Invalid GST No. Please check!" }
      }
      else (
          error = {
              ...error,
              gstNo_error: null
          })
  }

    if (formData.gst_image_link == "Choose Gst") {
      error = { ...error, gst_image_error: "Please Upload Document" };
    }
    if (gstData.gstNo == "") {
      errorGST = { ...errorGST, gstNo_error: "Please Enter Gst No" };
    }
    setFormData(error)
    setGstData(errorGST)
    if (error.gst_image_error == null && errorGST.gstNo_error == null) {
      await ApiPut(`kyc/update-user`, {
        gst_no: gstData.gstNo
      }).then(async (response: any) => {
        getUserData();
      }).catch((error: any) => console.log("Error", error))

      const form_data = new FormData();
      form_data.append('gst_image', formData.gst_image)
      await ApiPost("kyc/upload-gst", form_data).then(async (response: any) => {
        getData();
        if (response.data && response.success) {
          toast.success("upload Successfully.", {
            position: "top-right",
            theme: "colored"
          })
        } else {
          console.log("No Data found")
        }
      }).catch((error: any) => console.log("Error", error))
    }

  }
  const getStatus = (status: string) => {
    if (status == "") {
      return null;
    }
    if (status === 'Under Review') {
      return <small className="text-[#FF9800] text-xs">Under review</small>
    }
    if (status === 'Verified') {
      return <small className="text-[#37B551] text-xs">Verified</small>
    }
    if (status === 'rejected') {
      return <small className="text-[#e74141] text-xs">Rejected</small>
    }
  }

  return (<div className="overflow-x-hidden">
    {/* 
    <Sidebar />
    <Header /> */}
    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>
        {/* START::MAIN CONTENT */}
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
              <h1 className="text-[22px] text-light-gray font-semibold">KYC</h1>
            </div>
          </div>
          {/* END::DASHBOARD HEADER */}

          <div className="md:flex items-center md:space-x-10 space-y-10 md:space-y-0">
            <div className="flex items-center space-x-[26px] pb-5">
              <div className="w-[150px] h-[150px]">
                <label htmlFor="business_image" className="overflow-hidden w-full h-full border-2 flex items-center justify-center border-dashed border-black rounded-full text-[#C2C2C2]">
                  {formData.business_image_link == "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                  </svg>
                  }
                  {formData.business_image_link != "" && <img src={formData.business_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                  }
                </label>
                <input type="file" id="business_image" className="hidden" onChange={(e: any) => {
                  setFormData({ ...formData, business_image: e.target.files[0], business_image_link: URL.createObjectURL(e.target.files[0]) })
                }} />
              </div>
              <h4 className="font-semibold text-light-gray text-lg">Add Business <br /> Premises Photos</h4>
            </div>

            <div className="flex items-center space-x-[26px] pb-5">
              <div className="w-[150px] h-[150px]">
                <label htmlFor="profile_image" className="overflow-hidden w-full h-full border-2 flex items-center justify-center border-dashed border-black rounded-full text-[#C2C2C2]">
                  {formData.profile_image_link == "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                  </svg>
                  }
                  {formData.profile_image_link != "" && <img src={formData.profile_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                  }
                </label>
                <input type="file" id="profile_image" onChange={(e: any) => {
                  setFormData({ ...formData, profile_image: e.target.files[0], profile_image_link: URL.createObjectURL(e.target.files[0]) })
                }} className="hidden" />
              </div>
              <h4 className="font-semibold text-light-gray text-lg">Add Photo</h4>
            </div>
          </div>

          {/* START::PAN CARD */}
          <div className="space-y-1">
            <p className="input-label">Pan Card</p>
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className="w-full space-y-1">
                <div id="email" className={`input-wrap w-full ${formData.pan_card_error ? "border-red-500" : ""}`}>
                  <label htmlFor="pan_card" className={`block w-full text-sm text-[#808080] ${formData.pan_card_error ? "text-red-500" : ""}`}>{formData.pan_card_link}</label>
                  <img src="assets/check-green.svg" alt="" />
                </div>
                <p className="text-red-500 text-sm">{formData.pan_card_error}</p>
                <input type="file" id="pan_card" onChange={(e: any) => {
                  setFormData({ ...formData, pan_card: e.target.files[0], pan_card_link: e.target.files?.[0]?.name, pan_card_error: null })
                }} className="text-sm hidden" />
                {getStatus(formData.profile_status)}
              </div>
              <button className={`px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border  ${(formData.profile_status === 'Verified' || formData.profile_status === 'Under Review') ? "bg-light-gray border-light-gray cursor-no-drop" : "border-secondary"}`} disabled={formData.profile_status === 'Verified' || formData.profile_status === 'Under Review'} onClick={() => handleKyc("main")}>Upload</button>
            </div>
          </div>
          {/* END::PAN CARD */}

          {/* START::AADHAR CARD */}
          {/* <div className="space-y-1">
        <p className="input-label">Aadhar Card</p>
        <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
          <div className="w-full space-y-1">
            <div id="email" className="input-wrap w-full">
              <label htmlFor="panCard" className="block w-full text-sm text-[#808080]">Aadhar card.pdf</label>
              <img src="assets/check-green.svg" alt="" />
            </div>
            <input type="file" id="panCard" className="text-sm hidden" />
            <small className="text-[#37B551] text-xs">Verified</small>
          </div>
          <button className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Upload</button>
        </div>
      </div> */}
          {/* END::AADHAR CARD */}

          {/* START::CHEQUE */}
          <div className="space-y-1">
            <p className="input-label">Cheque</p>
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className='w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4'>
                <div className={`w-full space-y-1`}>
                  <div id="email" className={`input-wrap w-full ${formData.account_number_error ? "border-red-500" : ""}`}>
                    <input type="text" className={`block w-full text-sm text-[#808080] ${formData.account_number_error ? "text-red-500" : ""}`} placeholder="Bank Account Number" value={formData.account_number} onChange={(e: any) => {
                      setFormData({ ...formData, account_number: e.target.value, account_number_error: null })
                    }} />
                    <img src="assets/eye-gray.svg" alt="" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.account_number_error}</p>
                </div>
                <div className={`w-full space-y-1`}>
                  <div id="email" className={`input-wrap w-full ${formData.ifsc_error ? "border-red-500" : ""}`}>
                    <input type="text" value={formData.ifsc} onChange={(e: any) => {
                      setFormData({ ...formData, ifsc: e.target.value, ifsc_error: null })
                    }} className={`block w-full text-sm text-[#808080] ${formData.ifsc_error ? "text-red-500" : ""}`} placeholder="IFSC Code" />
                    <img src="assets/eye-gray.svg" alt="" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.ifsc_error}</p>
                </div>
              </div>
              <button disabled className='hidden md:block opacity-0 px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Upload</button>
            </div>
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className="w-full space-y-1">
                <div id="email" className={`input-wrap w-full ${formData.cheque_image_error ? "border-red-500" : ""}`}>
                  <label htmlFor="cheque_image" className={`block w-full text-sm text-[#808080] ${formData.cheque_image_error ? "text-red-500" : ""}`}>{formData.cheque_image_link}</label>
                </div>
                <p className="text-red-500 text-sm">{formData.cheque_image_error}</p>
                <input type="file" id="cheque_image" onChange={(e: any) => {
                  setFormData({ ...formData, cheque_image: e.target.files[0], cheque_image_link: e.target.files?.[0]?.name, cheque_image_error: null })
                }} className="text-sm hidden" />
                {getStatus(formData.cheque_status)}
              </div>
              <button className={`px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border  ${(formData.cheque_status === 'Verified' || formData.cheque_status === 'Under Review') ? "bg-light-gray border-light-gray cursor-no-drop" : "border-secondary"}`} disabled={formData.cheque_status === 'Verified' || formData.cheque_status === 'Under Review'} onClick={() => handleKyc("bank")}>Upload</button>
            </div>
          </div>
          {/* END::CHEQUE */}

          {/* START::GST NO */}
          <div className="space-y-1">
            <p className="input-label">GST</p>
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className="w-full space-y-1">
                <div id="gst_no" className={`input-wrap w-full ${gstData.gstNo_error ? "border-red-500" : ""}`}>
                  <input type="text" value={gstData.gstNo} onChange={(e: any) => {
                    setGstData({ ...gstData, gstNo: e.target.value, gstNo_error: null })
                  }} className={`block w-full text-sm text-[#808080] ${gstData.gstNo_error ? "text-red-500" : ""}`} placeholder="Enter GST Number" />
                  <img src="assets/eye-gray.svg" alt="" />
                </div>
                <p className="text-red-500 text-sm">{gstData.gstNo_error}</p>
              </div>
              <button className={`px-16 py-[18px] invisible text-semibold text-sm bg-secondary text-white rounded-lg border`} onClick={() => { }}>Upload</button>
            </div>
          </div>
          {/* END::GST */}

          {/* START::GST */}
          <div className="space-y-1">
            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
              <div className="w-full space-y-1">
                <div id="email" className={`input-wrap w-full ${formData.gst_image_error ? "border-red-500" : ""}`}>
                  <label htmlFor="gst_image" className={`block w-full text-sm text-[#808080] ${formData.gst_image_error ? "text-red-500" : ""}`}>{formData.gst_image_link}</label>
                  <img src="assets/check-green.svg" alt="" />
                </div>
                <p className="text-red-500 text-sm">{formData.gst_image_error}</p>
                <input type="file" id="gst_image" onChange={(e: any) => {
                  setFormData({ ...formData, gst_image: e.target.files[0], gst_image_link: e.target.files?.[0]?.name, gst_image_error: null })
                }} className="text-sm hidden" />
                {getStatus(formData.gst_status)}
              </div>
              <button className={`px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border  ${(formData.gst_status === 'Verified' || formData.gst_status === 'Under Review') ? "bg-light-gray border-light-gray cursor-no-drop" : "border-secondary"}`} disabled={formData.gst_status === 'Verified' || formData.gst_status === 'Under Review'} onClick={() => handleGSTData()}>Upload</button>
            </div>
          </div>
          {/* END::GST */}

        </div>
        {/* </main> */}
        {/* END::MAIN CONTENT */}
      </>
    }
  </div >)
}

export default Kyc