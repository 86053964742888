import { TableCell, TableRow, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPut } from '../../helper/API/ApiData';
import { getImageUrl, isEmpty, numberFormat,getImageUrl2 } from '../../helper/util';
import DatePicker from "react-datepicker";
import moment from 'moment';
// import Header from './components/header';
// import Sidebar from './components/sidebar'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { PERMISSION_TYPE, userPermission } from './../../helper/permission';

function KycAdmin() {
  let document_data = {
    proof_document: "",
    proof_document_image: "",
    proof_document_link: "Choose Image",
    remark: "",
    adjustment_amount: "",
  }

  const reject_data = {
    comment: ""
  }
  const main_data = {
    id: "",
    business_image: "",
    business_image_link: "",
    profile_image: "",
    profile_image_link: "",
    pan_card: "",
    pan_card_name: "",
    pan_card_link: "Choose Pan Card"
  }
  const bank_data = {
    account_number: "",
    ifsc: "",
    cheque_image: "",
    cheque_image_name: "",
    cheque_image_link: "Choose Cheque",
  }
  const gst_data = {
    gst_image: "",
    gst_image_name: "",
    gst_image_link: "Choose Gst",
  }

  const dialog_data = {
    first: false,
    second: false,
    third: false,
    open: false
  }

  const [activeTab, setActiveTab] = useState("kyc-review");
  const [kycAdmin, setKycAdmin] = useState([]);
  const [expencePopup, setExpencePopup] = useState(false);
  const [expenceList, setExpenceList] = useState([]);
  const [advanceList, setAdvanceList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [documentPopup, setDocumentPopup] = useState<boolean>(false);
  const [rejectPopup, setRejectPopup] = useState<boolean>(false);
  const [documentPreview, setDocumentPreview] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [permission, setPermission] = useState<any>({});
  const [kycPopUp, setKycPopUp] = useState(dialog_data)
  const [expenceDocumentOpen, setExpenceDocumentOpen] = useState(false)
  const [expenceData, setExpenceData] = useState<any>({});
  const [formData, setFormData] = useState<any>({ ...main_data, ...bank_data, ...gst_data });
  const [documentData, setDocumentData] = useState<any>(document_data);
  const [documentRejectData, setDocumentRejectData] = useState<any>(reject_data);
  const [rejectedId, setRejectedId] = useState<string>("");
  const user = useSelector((state: RootState) => state?.authUser?.userData?.user)


  const [kycstate, setKycState] = useState<any>(STATE.DEFAULT_STATE);
  const [advancestate, setAdvanceState] = useState<any>(STATE.DEFAULT_STATE);
  const [expencestate, setExpenceState] = useState<any>(STATE.DEFAULT_STATE);

  const columns = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'Name', label: 'Bank/NBFC Name' },
    { id: 'City', label: 'DSA Code' },
    { id: 'State', label: 'DSA NAME' },
    { id: 'ContactNo', label: 'Contact No.' },
    { id: 'Action', label: 'Action' },
  ];

  let advance_columns = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'borrower_name', label: 'Borrower Name' },
    { id: 'financer_name', label: 'Financer Name' },
    { id: 'loan_amount', label: 'Loan Amount' },
    { id: 'gross_payout', label: 'Gross Payout' },
  ];

  if (permission[PERMISSION_TYPE.ADMIN]) {
    advance_columns = [...advance_columns, { id: 'cheker_type', label: 'Checker Type' }, { id: 'queueName', label: 'Queue' }]
  }

  advance_columns = [...advance_columns, { id: 'Action', label: 'Action' }]

  const expence_columns = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'expense_type', label: 'Expence Type' },
    { id: 'remark', label: 'Remark' },
    { id: 'status', label: 'Status' },
    { id: 'Action', label: 'Action' },
  ];

  const handlePageData = (limit: number, page: number = 1) => {
    if (activeTab == 'kyc-review') {
      setKycState({
        ...kycstate,
        limit,
        page
      })
    }
    if (activeTab == 'advance-payout-review') {
      setAdvanceState({
        ...advancestate,
        limit,
        page
      })
    }
    if (activeTab == 'other-review') {
      setExpenceState({
        ...expencestate,
        limit,
        page
      })
    }
  }

  const handlePage = (page: number) => {
    if (activeTab == 'kyc-review') {
      setKycState({
        ...kycstate,
        page
      })
    }
    if (activeTab == 'advance-payout-review') {
      setAdvanceState({
        ...advancestate,
        page
      })
    }
    if (activeTab == 'other-review') {
      setExpenceState({
        ...expencestate,
        page
      })
    }
  }

  const getkycadmindata = async () => {
    await ApiGet(`kyc/list-kyc?page=${kycstate.page}&limit=${kycstate.limit}`)
      .then((res: any) => {
        let temp = res.data.result.docs.map((item: any, index: number) => {
          return {
            id: item._id,
            SRNO: (((kycstate.page - 1) * kycstate.limit) + index + 1),
            Name: item.user.name,
            City: item.user.city,
            State: item.user.state,
            ContactNo: item.user.mobile,
            business_image: item?.business_image,
            profile_image: item?.profile_image,
            pan_card_image: item?.pan_card_image,
            pan_card_no: item?.user.pan_no,
            pan_card_image_name: isEmpty(item?.pan_card_image_name) ? "Choose Pan Card" : item?.pan_card_image_name,
            cheque_image: item?.cheque_image,
            cheque_image_name: isEmpty(item?.cheque_image_name) ? "Choose Cheque" : item?.cheque_image_name,
            gst_image_name: isEmpty(item?.gst_image_name) ? "Choose Gst" : item?.gst_image_name,
            gst_image: item?.gst_image,
            gst_no: item?.user.gst_no,
            account_number: item?.account_number ?? "",
            ifsc: item?.ifsc ?? "",
            profile_status: item?.profile_status ?? "",
            cheque_status: item?.cheque_status ?? "",
            gst_status: item?.gst_status ?? ""
          }
        })
        setKycAdmin(temp)
        setKycState({
          ...kycstate,
          totalItems: res.data.result.total
        })
      })
  }

  const getexpenceData = async () => {
    await ApiGet(`miscellaneous/under-review?page=${expencestate.page}&limit=${expencestate.limit}`)
      .then((res: any) => {
        let temp = res.data.result.docs.map((item: any, index: number) => {
          return {
            ...item,
            SRNO: (((expencestate.page - 1) * expencestate.limit) + index + 1),
            date: !isEmpty(item?.date) ? new Date(item?.date) : new Date(),
            upload_document_image: getImageUrl(item?.upload_document)
          }
        })
        setExpenceList(temp)
        setExpenceState({
          ...expencestate,
          totalItems: res.data.result.total
        })
      })
  }
  const getAdvanceData = async () => {
    await ApiGet(`disbursement/advance-payout?page=${advancestate.page}&limit=${advancestate.limit}`)
      .then((res: any) => {
        let temp = res?.data?.result?.map((item: any, index: number) => {
          return {
            SRNO: (((advancestate.page - 1) * advancestate.limit) + index + 1),
            queueName: item?.cheker_type == "maker" ? `${item?.relationship_manager?.department}(${item?.relationship_manager?.name})` : `${item?.ops_staff?.department}(${item?.ops_staff?.name})`,
            ...item,
          }
        })
        setAdvanceList(temp)
        setAdvanceState({
          ...advancestate,
          totalItems: res.data.total
        })
      })
  }

  const editExpence = async (type: string) => {
    const form_data = new FormData();
    if (expenceData.expense_type == "agreement") {
      form_data.append('expense_type', expenceData?.expense_type)
      form_data.append('first_party', expenceData?.first_party)
      form_data.append('second_party', expenceData?.second_party)
      form_data.append('type_of_agreement', expenceData?.type_of_agreement)
      form_data.append('stamp_duty_amount', expenceData?.stamp_duty_amount)
      form_data.append('remark', expenceData?.remark)
      form_data.append('date', expenceData?.date)
      form_data.append('upload_document', expenceData?.upload_document)
    }
    if (expenceData.expense_type == "petrol") {
      form_data.append('expense_type', expenceData?.expense_type)
      form_data.append('travel_from', expenceData?.travel_from)
      form_data.append('travel_to', expenceData?.travel_to)
      form_data.append('total_kms', expenceData?.total_kms)
      form_data.append('total_amount', expenceData?.total_amount)
      form_data.append('date', expenceData?.date)
      form_data.append('remark', expenceData?.remark)
      form_data.append('upload_document', expenceData?.upload_document)
    }
    if (expenceData.expense_type == "hospitality") {
      form_data.append('expense_type', expenceData?.expense_type)
      form_data.append('total_amount', expenceData?.total_amount)
      form_data.append('date', expenceData?.date)
      form_data.append('remark', expenceData?.remark)
      form_data.append('upload_document', expenceData?.upload_document)
    }
    if (expenceData.expense_type == "other") {
      form_data.append('expense_type', expenceData?.expense_type)
      form_data.append('type_of_expense', expenceData?.type_of_expense)
      form_data.append('reason', expenceData?.reason)
      form_data.append('expense_amount', expenceData?.expense_amount)
      form_data.append('remark', expenceData?.remark)
      form_data.append('date', expenceData?.date)
      form_data.append('upload_document', expenceData?.upload_document)
    }
    if (type == "approve") {
      form_data.append('status', "Verified")
    }
    if (type == "reject") {
      form_data.append('status', "Rejected")
    }
    await ApiPut(`miscellaneous/under-review/${expenceData._id}`, form_data)
      .then((res: any) => {
        setExpenceData({})
        setExpencePopup(false)
        getexpenceData()
      })
  }

  const handleStatusChange = async (id: string, type: string, status: string) => {
    await ApiPut(`kyc/verify/${id}`, {
      type,
      status
    }).then(async (response: any) => {
      setKycPopUp(dialog_data);
      setFormData({ ...main_data, ...bank_data, ...gst_data });
    }).catch((error: any) => console.log("Error", error))
  }

  useEffect(() => {
    Promise.all([
      getkycadmindata(),
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [kycstate.page, kycstate.limit])

  useEffect(() => {
    getAdvanceData()
  }, [advancestate.page, advancestate.limit])

  useEffect(() => {
    getexpenceData()
  }, [expencestate.page, expencestate.limit])

  useEffect(() => {
    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.ADVANCE_ACTION_UPLOAD]: userPermission(user, PERMISSION_TYPE.ADVANCE_ACTION_UPLOAD),
      [PERMISSION_TYPE.ADVANCE_ACTION_APPROVE]: userPermission(user, PERMISSION_TYPE.ADVANCE_ACTION_APPROVE),
      [PERMISSION_TYPE.ADVANCE_ACTION_REJECT]: userPermission(user, PERMISSION_TYPE.ADVANCE_ACTION_REJECT),
    }
    setPermission(tempPermission)
  }, [user])

  const handleKyc = async (id: string, key: string) => {
    if (key === "main") {
      const form_data = new FormData();
      form_data.append('business_image', formData.business_image)
      form_data.append('profile_image', formData.profile_image)
      form_data.append('pan_card_image', formData.pan_card)
      await ApiPut(`kyc/update-detail/${id}`, form_data).then(async (response: any) => {
        setKycPopUp(dialog_data);
        setFormData({ ...main_data, ...bank_data, ...gst_data });
        getkycadmindata();
      }).catch((error: any) => console.log("Error", error))
    }
    if (key === "cheque") {
      const form_data = new FormData();
      form_data.append('account_number', formData.account_number)
      form_data.append('ifsc', formData.ifsc)
      form_data.append('cheque_image', formData.cheque_image)
      await ApiPut(`kyc/update-bank-details/${id}`, form_data).then(async (response: any) => {
        setKycPopUp(dialog_data);
        setFormData({ ...main_data, ...bank_data, ...gst_data });
        getkycadmindata();
      }).catch((error: any) => console.log("Error", error))
    }
    if (key === "gst") {
      const form_data = new FormData();
      form_data.append('gst_image', formData.gst_image)
      await ApiPut(`kyc/update-gst/${id}`, form_data).then(async (response: any) => {
        setKycPopUp(dialog_data);
        setFormData({ ...main_data, ...bank_data, ...gst_data });
        getkycadmindata();
      }).catch((error: any) => console.log("Error", error))
    }
  }

  const actionAdminYes = async () => {
    const form_data = new FormData();
    form_data.append('proof_document', documentData.proof_document)
    form_data.append('remark', documentData.remark)
    form_data.append('adjustment_amount', documentData.adjustment_amount)
    await ApiPut(`disbursement/advance-payout-document/${selectedId}`, form_data).then((res: any) => {
      setSelectedId("")
      setDocumentPopup(false)
      getAdvanceData()
    }).catch((error: any) => {
      toast.error("Something went wrong!", {
        position: "top-right",
        theme: "colored"
      })
    })
  }

  const actionApprove = async (id: string) => {
    await ApiPut(`disbursement/advance-payout-status/${id}`, {}).then((res: any) => {
      getAdvanceData()
    }).catch((error: any) => {
      toast.error("Something went wrong!", {
        position: "top-right",
        theme: "colored"
      })
    })
  }

  const actionReject = async (id: string) => {
    setRejectPopup(true)

    await ApiPut(`disbursement/reject/${id}`, {
      cheker_type: "maker",
      comment: documentRejectData.comment
    }).then((res: any) => {
      setRejectPopup(false)
      getAdvanceData()
    }).catch((error: any) => {
      toast.error("Something went wrong!", {
        position: "top-right",
        theme: "colored"
      })
    })
  }

  return (<div className="overflow-x-hidden">
    {/* <Sidebar />
    <Header /> */}
    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
              <h1 className="text-[22px] text-light-gray font-semibold white-nowrap">Pending For Review</h1>

              {/* 
                            if(tab active) 
                                Add class "bg-white rounded-[18px] text-primary"
                            else 
                                Remove class "bg-white rounded-[18px] text-primary"
                        */}
              <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                <li>
                  <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "kyc-review" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("kyc-review")}>KYC Review</button>
                </li>
                <li>
                  <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "advance-payout-review" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("advance-payout-review")}>Advance Payout Review</button>
                </li>
                <li>
                  <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "other-review" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("other-review")}>Other Review</button>
                </li>
              </ul>
            </div>

            {/* START::EXPORT */}
            <ul className="flex space-x-[18px]">
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/pdf.svg" alt="" />
                  <span>PDF</span>
                </button>
              </li>
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/excel.svg" alt="" />
                  <span>EXCEL</span>
                </button>
              </li>
            </ul>
            {/* END::EXPORT */}
          </div>
          {/* END::DASHBOARD HEADER */}
          {activeTab == "kyc-review" &&
            <TableComponent
              rows={kycAdmin}
              handlePageData={handlePageData}
              handlePage={handlePage}
              state={kycstate}
              columns={columns}
              renderColumn={(column: any) => {
                return (
                  <TableCell align="center"
                    className="!bg-gray-200"
                    key={column.id}
                    style={{ background: "gray" }}
                  >
                    {column.label}
                  </TableCell>
                )
              }}
              renderRow={(row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center" component="th" scope="row">
                      {row.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.City}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.State}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.ContactNo}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <ul className="flex space-x-2 justify-center">
                        <li>
                          <Tooltip title="Personal Data">
                            <button onClick={() => {
                              let finalData = {
                                id: row.id,
                                business_image_link: getImageUrl2(row?.business_image),
                                profile_image_link: getImageUrl2(row?.profile_image),
                                pan_card_name: row?.pan_card_image_name,
                                pan_card_no: row?.pan_card_no,
                                pan_card_link: getImageUrl2(row?.pan_card_image)
                              }
                              setFormData({ ...formData, ...finalData })
                              setKycPopUp({ ...kycPopUp, first: true })
                            }}><img src="assets/user-card.svg" alt="" /></button>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Bank Data">
                            <button onClick={() => {
                              let finalData = {
                                id: row.id,
                                cheque_image_name: row?.cheque_image_name,
                                cheque_image_link: getImageUrl2(row?.cheque_image),
                                account_number: row?.account_number ?? "",
                                ifsc: row?.ifsc ?? "",
                              }
                              setFormData({ ...formData, ...finalData });
                              setKycPopUp({ ...kycPopUp, second: true });
                            }}><img src="assets/cheque.svg" alt="" /></button>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Gst Data">
                            <button onClick={() => {
                              let finalData = {
                                id: row.id,
                                gst_no: row.gst_no,
                                gst_image_name: row?.gst_image_name,
                                gst_image_link: getImageUrl(row?.gst_image),
                              }
                              setFormData({ ...formData, ...finalData });
                              setKycPopUp({ ...kycPopUp, third: true });
                            }}><img src="assets/discount.svg" alt="" /></button>
                          </Tooltip>
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                )
              }}
            />
          }
          {activeTab === "advance-payout-review" &&
            <TableComponent
              rows={advanceList}
              handlePageData={handlePageData}
              handlePage={handlePage}
              state={advancestate}
              columns={advance_columns}
              renderColumn={(column: any) => {
                return (
                  <TableCell align="center"
                    className="!bg-gray-200"
                    key={column.id}
                    style={{ background: "gray" }}
                  >
                    {column.label}
                  </TableCell>
                )
              }}
              renderRow={(row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center" component="th" scope="row">
                      {row.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.borrower_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.financer_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {numberFormat(row.loan_amount)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.gross_payout ?? 0}
                    </TableCell>
                    {permission[PERMISSION_TYPE.ADMIN] &&
                      <>
                        <TableCell align="center" component="th" scope="row">
                          {row.cheker_type}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.queueName}
                        </TableCell>
                      </>
                    }
                    <TableCell align="center" component="th" scope="row">
                      <ul className="flex space-x-2 justify-end">
                        {permission[PERMISSION_TYPE.ADVANCE_ACTION_UPLOAD] &&
                          <li>
                            <Tooltip title="Upload Data">
                              <button className="tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary bg-opacity-10 text-secondary" onClick={() => {
                                setSelectedId(row._id)
                                setDocumentPopup(true)
                              }}>Upload Data</button>
                            </Tooltip>
                          </li>
                        }
                        {permission[PERMISSION_TYPE.ADVANCE_ACTION_APPROVE] &&
                          <li>
                            <Tooltip title="Approve">
                              <button className="tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary text-white" onClick={() => {
                                actionApprove(row._id)
                              }}>Approve</button>
                            </Tooltip>
                          </li>
                        }
                        {permission[PERMISSION_TYPE.ADVANCE_ACTION_REJECT] &&
                          <li>
                            <Tooltip title="Rejected">
                              <button className='tw-modal-footer-button py-2 px-4 font-normal rounded-sm  w-[unset] bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                                setRejectedId(row._id)
                                setRejectPopup(true)
                              }}>Reject</button>
                            </Tooltip>
                          </li>
                        }
                      </ul>
                    </TableCell>
                  </TableRow>
                )
              }}
            />
          }
          {activeTab === "other-review" &&
            <TableComponent
              rows={expenceList}
              handlePageData={handlePageData}
              handlePage={handlePage}
              state={expencestate}
              columns={expence_columns}
              renderColumn={(column: any) => {
                return (
                  <TableCell align="center"
                    className="!bg-gray-200"
                    key={column.id}
                    style={{ background: "gray" }}
                  >
                    {column.label}
                  </TableCell>
                )
              }}
              renderRow={(row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center" component="th" scope="row">
                      {row.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.expense_type}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.remark}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.status}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <ul className="flex space-x-2 justify-center">
                        <li>
                          <Tooltip title="Expence Data">
                            <button onClick={() => {
                              setExpenceData(row)
                              setExpenceDocumentOpen(false)
                              setExpencePopup(true)
                            }}><img src="assets/user-card.svg" alt="" /></button>
                          </Tooltip>
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                )
              }}
            />
          }
        </div>


        <div className={`tw-modal ${expencePopup ? 'flex' : 'hidden'} !items-start`}>
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>Add {expenceData.expense_type} Data</div>
              <button onClick={() => setExpencePopup(false)}>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>
            {expenceData.expense_type == "agreement" &&
              <div className='tw-modal-body'>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label htmlFor="first_party" className={`input-label ${expenceData?.first_party_error ? "text-red-500" : ""}`}>First Party</label>
                    <div id="first_party" className={`input-wrap ${expenceData?.first_party_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="first_party" value={expenceData.first_party} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, first_party: e.target.value, first_party_error: null })
                      }} className="w-full text-sm" placeholder="Enter first party" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.first_party_error}</p>

                  </div>
                  <div className="space-y-1">
                    <label htmlFor="second_party" className={`input-label ${expenceData?.second_party_error ? "text-red-500" : ""}`}>Second Party</label>
                    <div id="second_party" className={`input-wrap ${expenceData?.second_party_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="second_party" value={expenceData.second_party} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, second_party: e.target.value, second_party_error: null })
                      }} className="w-full text-sm" placeholder="Enter second party" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.second_party_error}</p>

                  </div>
                  <div className="space-y-1">
                    <label htmlFor="type_of_agreement" className={`input-label ${expenceData?.type_of_agreement_error ? "text-red-500" : ""}`}>Type Of Agreement</label>
                    <div id="type_of_agreement" className={`input-wrap ${expenceData?.type_of_agreement_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="type_of_agreement" value={expenceData.type_of_agreement} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, type_of_agreement: e.target.value, type_of_agreement_error: null })
                      }} className="w-full text-sm" placeholder="Enter type of agreement" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.type_of_agreement_error}</p>

                  </div>
                  <div className="space-y-1">
                    <label htmlFor="stamp_duty_amount" className={`input-label ${expenceData?.stamp_duty_amount_error ? "text-red-500" : ""}`}>Type Of Agreement</label>
                    <div id="stamp_duty_amount" className={`input-wrap ${expenceData?.stamp_duty_amount_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="number" name="stamp_duty_amount" value={expenceData.stamp_duty_amount} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, stamp_duty_amount: e.target.value, stamp_duty_amount_error: null })
                      }} className="w-full text-sm" placeholder="Enter stamp duty amount" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.stamp_duty_amount_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="remark" className={`input-label ${expenceData?.remark_error ? "text-red-500" : ""}`}>Remark</label>
                    <div id="remark" className={`input-wrap ${expenceData?.remark_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="remark" value={expenceData?.remark} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, remark: e.target.value, remark_error: null })
                      }} className="w-full text-sm" placeholder="Enter Remark" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.remark_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pincode" className={`input-label`}>Date</label>
                    <div id="date_of_joining" className={`input-wrap`}>
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={expenceData?.date}
                        onChange={(date: any) => {
                          setExpenceData({ ...expenceData, date: date })
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="amount_income" className={`input-label ${expenceData?.upload_document_error ? "text-red-500" : ""}`}>Upload Document</label>
                    <div id="amount_income" className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${expenceData?.upload_document_error ? "border-red-500" : ""}`}>
                      <div className="w-full space-y-1">
                        <div className="input-wrap w-full">
                          <label htmlFor="upload_document" className="block w-full text-sm text-[#808080]">{expenceData?.upload_document_name ? expenceData?.upload_document_name : "Please Upload Document"}</label>
                        </div>
                        <input type="file" id="upload_document" onChange={(e: any) => {
                          setExpenceData({
                            ...expenceData, upload_document_image: URL.createObjectURL(e.target.files[0]),
                            upload_document: e.target.files?.[0], upload_document_name: e.target.files?.[0]?.name
                          })
                        }} className="text-sm hidden" />
                      </div>
                      <button className="px-8 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary" onClick={() => setExpenceDocumentOpen(!expenceDocumentOpen)}>Preview</button>
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.upload_document_error}</p>
                  </div>
                </div>
                {expenceDocumentOpen && <img src={expenceData.upload_document_image} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                }
              </div>
            }
            {expenceData.expense_type == "petrol" &&
              <div className='tw-modal-body'>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label htmlFor="amount_income" className={`input-label ${expenceData?.travel_from_error ? "text-red-500" : ""}`}>Travel From</label>
                    <div id="amount_income" className={`input-wrap ${expenceData?.travel_from_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="amount_income" value={expenceData?.travel_from} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, travel_from: e.target.value, travel_from_error: null })
                      }} className="w-full text-sm" placeholder="Enter Travel From" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.travel_from_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="amount_income" className={`input-label ${expenceData?.travel_to_error ? "text-red-500" : ""}`}>Travel To</label>
                    <div id="amount_income" className={`input-wrap ${expenceData?.travel_to_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="amount_income" value={expenceData?.travel_to} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, travel_to: e.target.value, travel_to_error: null })
                      }} className="w-full text-sm" placeholder="Enter Travel To" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.travel_to_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="amount_income" className={`input-label ${expenceData?.total_kms_error ? "text-red-500" : ""}`}>Total Kms</label>
                    <div id="amount_income" className={`input-wrap ${expenceData?.total_kms_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="amount_income" value={expenceData?.total_kms} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, total_kms: e.target.value, total_kms_error: null })
                      }} className="w-full text-sm" placeholder="Enter Total Kms" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.total_kms_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="total_amount" className={`input-label ${expenceData?.total_amount_error ? "text-red-500" : ""}`}>Total Amount</label>
                    <div id="total_amount" className={`input-wrap ${expenceData?.total_amount_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="total_amount" value={expenceData?.total_amount} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, total_amount: e.target.value, total_amount_error: null })
                      }} className="w-full text-sm" placeholder="Enter Total Amount" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.total_amount_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="remark" className={`input-label ${expenceData?.remark_error ? "text-red-500" : ""}`}>Remark</label>
                    <div id="remark" className={`input-wrap ${expenceData?.remark_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="remark" value={expenceData?.remark} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, remark: e.target.value, remark_error: null })
                      }} className="w-full text-sm" placeholder="Enter Remark" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.remark_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pincode" className={`input-label`}>Date</label>
                    <div id="date_of_joining" className={`input-wrap`}>
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={expenceData?.date}
                        onChange={(date: any) => {
                          setExpenceData({ ...expenceData, date: date })
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="amount_income" className={`input-label ${expenceData?.upload_document_error ? "text-red-500" : ""}`}>Upload Document</label>
                    <div id="amount_income" className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${expenceData?.upload_document_error ? "border-red-500" : ""}`}>
                      <div className="w-full space-y-1">
                        <div className="input-wrap w-full">
                          <label htmlFor="upload_document" className="block w-full text-sm text-[#808080]">{expenceData?.upload_document_name}</label>
                        </div>
                        <input type="file" id="upload_document" onChange={(e: any) => {
                          setExpenceData({
                            ...expenceData, upload_document_image: URL.createObjectURL(e.target.files[0]),
                            upload_document: e.target.files?.[0], upload_document_name: e.target.files?.[0]?.name
                          })
                        }} className="text-sm hidden" />
                      </div>
                      <button className="px-8 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary" onClick={() => setExpenceDocumentOpen(!expenceDocumentOpen)}>Preview</button>
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.upload_document_error}</p>
                  </div>
                </div>
                {expenceDocumentOpen && <img src={expenceData.upload_document_image} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                }
              </div>
            }
            {expenceData.expense_type == "hospitality" &&
              <div className='tw-modal-body'>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label htmlFor="total_amount" className={`input-label ${expenceData?.total_amount_error ? "text-red-500" : ""}`}>Total Amount</label>
                    <div id="total_amount" className={`input-wrap ${expenceData?.total_amount_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="total_amount" value={expenceData?.total_amount} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, total_amount: e.target.value, total_amount_error: null })
                      }} className="w-full text-sm" placeholder="Enter Travel From" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.total_amount_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="remark" className={`input-label ${expenceData?.remark_error ? "text-red-500" : ""}`}>Remark</label>
                    <div id="remark" className={`input-wrap ${expenceData?.remark_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="remark" value={expenceData?.remark} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, remark: e.target.value, remark_error: null })
                      }} className="w-full text-sm" placeholder="Enter Remark" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.remark_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pincode" className={`input-label`}>Date</label>
                    <div id="date_of_joining" className={`input-wrap`}>
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={expenceData?.date}
                        onChange={(date: any) => {
                          setExpenceData({ ...expenceData, date: date })
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="amount_income" className={`input-label ${expenceData?.upload_document_error ? "text-red-500" : ""}`}>Upload Document</label>
                    <div id="amount_income" className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${expenceData?.upload_document_error ? "border-red-500" : ""}`}>
                      <div className="w-full space-y-1">
                        <div className="input-wrap w-full">
                          <label htmlFor="upload_document" className="block w-full text-sm text-[#808080]">{expenceData?.upload_document_name}</label>
                        </div>
                        <input type="file" id="upload_document" onChange={(e: any) => {
                          setExpenceData({
                            ...expenceData, upload_document_image: URL.createObjectURL(e.target.files[0]),
                            upload_document: e.target.files?.[0], upload_document_name: e.target.files?.[0]?.name
                          })
                        }} className="text-sm hidden" />
                      </div>
                      <button className="px-8 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary" onClick={() => setExpenceDocumentOpen(!expenceDocumentOpen)}>Preview</button>
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.upload_document_error}</p>
                  </div>
                </div>
                {expenceDocumentOpen && <img src={expenceData.upload_document_image} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                }
              </div>
            }
            {expenceData.expense_type == "other" &&
              <div className='tw-modal-body'>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label htmlFor="type_of_expense" className={`input-label ${expenceData?.type_of_expense_error ? "text-red-500" : ""}`}>Type Of Expense</label>
                    <div id="type_of_expense" className={`input-wrap ${expenceData?.type_of_expense_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="type_of_expense" value={expenceData?.type_of_expense} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, type_of_expense: e.target.value, type_of_expense_error: null })
                      }} className="w-full text-sm" placeholder="Enter Travel From" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.type_of_expense_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="reason" className={`input-label ${expenceData?.reason_error ? "text-red-500" : ""}`}>Reason</label>
                    <div id="reason" className={`input-wrap ${expenceData?.reason_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="reason" value={expenceData?.reason} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, reason: e.target.value, reason_error: null })
                      }} className="w-full text-sm" placeholder="Enter Travel To" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.reason_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="expense_amount" className={`input-label ${expenceData?.expense_amount_error ? "text-red-500" : ""}`}>Expense Amount</label>
                    <div id="expense_amount" className={`input-wrap ${expenceData?.expense_amount_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="expense_amount" value={expenceData?.expense_amount} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, expense_amount: e.target.value, expense_amount_error: null })
                      }} className="w-full text-sm" placeholder="Enter Total Kms" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.expense_amount_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="remark" className={`input-label ${expenceData?.remark_error ? "text-red-500" : ""}`}>Remark</label>
                    <div id="remark" className={`input-wrap ${expenceData?.remark_error ? "border-red-500" : ""}`}>
                      <img src="assets/user.svg" alt="" />
                      <input type="text" name="remark" value={expenceData?.remark} onChange={(e: any) => {
                        setExpenceData({ ...expenceData, remark: e.target.value, remark_error: null })
                      }} className="w-full text-sm" placeholder="Enter Remark" />
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.remark_error}</p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pincode" className={`input-label`}>Date</label>
                    <div id="date_of_joining" className={`input-wrap`}>
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={expenceData?.date}
                        onChange={(date: any) => {
                          setExpenceData({ ...expenceData, date: date })
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="amount_income" className={`input-label ${expenceData?.upload_document_error ? "text-red-500" : ""}`}>Upload Document</label>
                    <div id="amount_income" className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${expenceData?.upload_document_error ? "border-red-500" : ""}`}>
                      <div className="w-full space-y-1">
                        <div className="input-wrap w-full">
                          <label htmlFor="upload_document" className="block w-full text-sm text-[#808080]">{expenceData?.upload_document_name}</label>
                        </div>
                        <input type="file" id="upload_document" onChange={(e: any) => {
                          setExpenceData({
                            ...expenceData, upload_document_image: URL.createObjectURL(e.target.files[0]),
                            upload_document: e.target.files?.[0], upload_document_name: e.target.files?.[0]?.name
                          })
                        }} className="text-sm hidden" />
                      </div>
                      <button className="px-8 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary" onClick={() => setExpenceDocumentOpen(!expenceDocumentOpen)}>Preview</button>
                    </div>
                    <p className="text-red-500 text-sm">{expenceData?.upload_document_error}</p>
                  </div>
                </div>
                {expenceDocumentOpen && <img src={expenceData.upload_document_image} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                }
              </div>
            }
            <div className='tw-modal-footer'>
              <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => editExpence("")}>Change Data</button>
              <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => editExpence("approve")}>Approve</button>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                editExpence("reject")
              }}>Reject</button>
            </div>
          </div>
        </div>

        {/* Start::KYC Admin Pan Card Modal */}
        <div className={`tw-modal ${kycPopUp.first ? 'flex' : 'hidden'} !items-start`}>
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>KYC Admin</div>
              <button onClick={() => setKycPopUp(dialog_data)}>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>
            <div className='tw-modal-body'>
              <div className="md:flex items-center md:space-x-10 space-y-10 md:space-y-0">
                <div className="flex items-center space-x-[26px] pb-5">
                  <div className="w-[150px] h-[150px]">
                    <label htmlFor="business_image" className="overflow-hidden w-full h-full border-2 flex items-center justify-center border-dashed border-black rounded-full text-[#C2C2C2]">
                      {formData.business_image_link == "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                      </svg>
                      }
                      {formData.business_image_link != "" && <img src={formData.business_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                      }
                    </label>
                    <input type="file" id="business_image" className="hidden" onChange={(e: any) => {
                      setFormData({ ...formData, business_image: e.target.files[0], business_image_link: URL.createObjectURL(e.target.files[0]) })
                    }} />
                  </div>
                  <h4 className="font-semibold text-light-gray text-lg">Add Business <br /> Premises Photos</h4>
                </div>

                <div className="flex items-center space-x-[26px] pb-5">
                  <div className="w-[150px] h-[150px]">
                    <label htmlFor="profile_image" className="overflow-hidden w-full h-full border-2 flex items-center justify-center border-dashed border-black rounded-full text-[#C2C2C2]">
                      {formData.profile_image_link == "" && <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                      </svg>
                      }
                      {formData.profile_image_link != "" && <img src={formData.profile_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                      }
                    </label>
                    <input type="file" id="profile_image" onChange={(e: any) => {
                      setFormData({ ...formData, profile_image: e.target.files[0], profile_image_link: URL.createObjectURL(e.target.files[0]) })
                    }} className="hidden" />
                  </div>
                  <h4 className="font-semibold text-light-gray text-lg">Add Photo</h4>
                </div>
              </div>

              {/* START::PAN CARD */}
              <div className="space-y-1">
                <p className="input-label">Pan Card {": " + formData?.pan_card_no}</p>
                <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                  <div className="w-full space-y-1">
                    <div id="email" className="input-wrap w-full">
                      <label htmlFor="pan_card" className="block w-full text-sm text-[#808080]">{formData.pan_card_name}</label>
                      <img src="assets/check-green.svg" alt="" />
                    </div>
                    <input type="file" id="pan_card" onChange={(e: any) => {
                      setFormData({ ...formData, pan_card: e.target.files[0], pan_card_name: e.target.files?.[0]?.name, pan_card_link: URL.createObjectURL(e.target.files[0]) })
                    }} className="text-sm hidden" />
                  </div>
                  <button className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary' onClick={() => setKycPopUp({ ...kycPopUp, open: !kycPopUp.open })}>Preview</button>
                </div>
                {/* {(kycPopUp.open && formData.pan_card_link != "") && <img src={formData.pan_card_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                } */}
                {
                  kycAdmin.map((kyc: any, index) => {
                    return (
                      kyc.id == formData.id ?
                        kycPopUp.open && kyc.pan_card_image != "" &&
                        (
                          kyc.pan_card_image?.split(".").pop() == "pdf" ?
                            <a target="_blank" href={`http://139.59.17.191:5009${kyc.pan_card_image}`}>
                              <button className='px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Open Document</button>
                            </a>
                            : <img src={`http://139.59.17.191:5009${kyc.pan_card_image}`} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                        )
                        : null
                    )
                  })
                }

              </div>
              {/* END::PAN CARD */}
            </div>

            <div className='tw-modal-footer'>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                handleKyc(formData.id, "main");
              }}>Change Document</button>
              <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => handleStatusChange(formData.id, "profile", "approved")}>Approve</button>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                handleStatusChange(formData.id, "profile", "rejected");
              }}>Decline</button>
            </div>
          </div>
        </div>
        {/* End::KYC Admin Pan Card Modal */}

        {/* Start::KYC Admin Cheque Modal */}
        <div className={`tw-modal ${kycPopUp.second ? 'flex' : 'hidden'} !items-start`}>
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>KYC Admin</div>
              <button onClick={() => setKycPopUp(dialog_data)}>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>

            <div className='tw-modal-body'>
              <div className="space-y-1">
                <p className="input-label">Cheque</p>
                <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                  <div className='w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4'>
                    <div className="w-full space-y-1">
                      <div id="email" className="input-wrap w-full">
                        <input type="text" className="block w-full text-sm text-[#808080]" placeholder="Bank Account Number" value={formData.account_number} onChange={(e: any) => {
                          setFormData({ ...formData, account_number: e.target.value })
                        }} />
                        <img src="assets/eye-gray.svg" alt="" />
                      </div>
                    </div>
                    <div className="w-full space-y-1">
                      <div id="email" className="input-wrap w-full">
                        <input type="text" value={formData.ifsc} onChange={(e: any) => {
                          setFormData({ ...formData, ifsc: e.target.value })
                        }} className="block w-full text-sm text-[#808080]" placeholder="IFSC Code" />
                        <img src="assets/eye-gray.svg" alt="" />
                      </div>
                    </div>
                  </div>
                  <button disabled className='hidden md:block opacity-0 px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Upload</button>
                </div>
                <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                  <div className="w-full space-y-1">
                    <div id="email" className="input-wrap w-full">
                      <label htmlFor="cheque_image" className="block w-full text-sm text-[#808080]">{formData.cheque_image_name}</label>
                    </div>
                    <input type="file" id="cheque_image" onChange={(e: any) => {
                      setFormData({ ...formData, cheque_image: e.target.files[0], cheque_image_name: e.target.files?.[0]?.name, cheque_image_link: URL.createObjectURL(e.target.files[0]) })
                    }} className="text-sm hidden" />
                  </div>
                  <button className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary' onClick={() => setKycPopUp({ ...kycPopUp, open: !kycPopUp.open })}>Preview</button>
                </div>
                {/* {(kycPopUp.open && formData.cheque_image_link != "") && <img src={formData.cheque_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                } */}
                {
                  kycPopUp.open && formData.cheque_image_link != "" &&
                  (
                    formData.cheque_image_link?.split(".").pop() == "pdf" ?
                      <a target="_blank" href={`http://139.59.17.191:5009${formData.cheque_image_link}`}>
                        <button className='px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Open Document</button>
                      </a>
                      : <img src={formData.cheque_image_link} alt="Image not Found" className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                  )
                }
              </div>
            </div>

            <div className='tw-modal-footer'>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                handleKyc(formData.id, "cheque");
              }}>Change Document</button>
              <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => handleStatusChange(formData.id, "cheque", "approved")}>Approve</button>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                handleStatusChange(formData.id, "cheque", "rejected");
              }}>Decline</button>
            </div>
          </div>
        </div>
        {/* End::KYC Admin Cheque Modal */}

        {/* Start::KYC GST Modal */}
        <div className={`tw-modal ${kycPopUp.third ? 'flex' : 'hidden'} !items-start`}>
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>KYC Admin</div>
              <button onClick={() => setKycPopUp(dialog_data)}>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>

            <div className='tw-modal-body'>
              <div className="space-y-1">
                <p className="input-label">GST {": " + formData.gst_no}</p>
                <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                  <div className="w-full space-y-1">
                    <div id="email" className="input-wrap w-full">
                      <label htmlFor="gst_image" className="block w-full text-sm text-[#808080]">{formData.gst_image_name}</label>
                      <img src="assets/check-green.svg" alt="" />
                    </div>
                    <input type="file" id="gst_image" onChange={(e: any) => {
                      setFormData({ ...formData, gst_image: e.target.files[0], gst_image_name: e.target.files?.[0]?.name, gst_image_link: URL.createObjectURL(e.target.files[0]) })
                    }} className="text-sm hidden" />
                  </div>
                  <button className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary' onClick={() => setKycPopUp({ ...kycPopUp, open: !kycPopUp.open })}>Preview</button>
                </div>
                {(kycPopUp.open && formData.gst_image_link != "") && <img src={formData.gst_image_link} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                }
                <>{console.log("formData", formData)}</>
                {
                  kycPopUp.open && formData.gst_image_link != "" &&
                  (
                    formData.gst_image_link?.split(".").pop() == "pdf" ?
                      <a target="_blank" href={`http://139.59.17.191:5009${formData.gst_image_link}`}>
                        <button className='px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Open Document</button>
                      </a>
                      : <img src={formData.gst_image_link} alt="Image not Found" className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                  )
                }
              </div>
            </div>

            <div className='tw-modal-footer'>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                handleKyc(formData.id, "gst");
              }}>Change Document</button>
              <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => handleStatusChange(formData.id, "gst", "approved")}>Approve</button>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                handleStatusChange(formData.id, "gst", "rejected");
              }}>Decline</button>
            </div>
          </div>
        </div>
        {/* End::KYC GST Modal */}


        <div className={`tw-modal ${documentPopup ? 'flex' : 'hidden'} !items-start`}>
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>Document</div>
              <button onClick={() => setDocumentPopup(false)}>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>

            <div className='tw-modal-body'>
              <div className="space-y-1">
                {/* <p className="input-label">Cheque</p> */}
                <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                  <div className='w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4'>
                    <div className="w-full space-y-1">
                      <div id="remark" className="input-wrap w-full">
                        <input type="text" className="block w-full text-sm text-[#808080]" placeholder="Remark" value={documentData.remark} onChange={(e: any) => {
                          setDocumentData({ ...documentData, remark: e.target.value })
                        }} />
                      </div>
                    </div>
                    <div className="w-full space-y-1">
                      <div id="adjustment_amount" className="input-wrap w-full">
                        <input type="text" value={documentData.adjustment_amount} onChange={(e: any) => {
                          setDocumentData({ ...documentData, adjustment_amount: e.target.value })
                        }} className="block w-full text-sm text-[#808080]" placeholder="Adjustment Amount" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                  <div className="w-full space-y-1">
                    <div className="input-wrap w-full">
                      <label htmlFor="proof_document" className="block w-full text-sm text-[#808080]">{documentData.proof_document_link}</label>
                    </div>
                    <input type="file" id="proof_document" onChange={(e: any) => {
                      setDocumentData({ ...documentData, proof_type:e.target.files[0].type?.split("/").pop(),proof_document: e.target.files[0], proof_document_link: e.target.files?.[0]?.name, proof_document_image: URL.createObjectURL(e.target.files[0]) })
                    }} className="text-sm hidden" 
                    accept="image/*, .pdf"/>
                  </div>
                  <button className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary' onClick={() => setDocumentPreview(!documentPreview)}>Preview</button>
                </div>
                {/* {(documentPreview && documentData.proof_document_image != "") && 
                <img src={documentData.proof_document_image} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                } */}
                {
(documentPreview && documentData.proof_document_image != "") && documentData.proof_type == 'pdf' ? 
<a target="_blank" href={documentData.proof_document_image}>
                              <button className='px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Open Document</button>
                            </a>
                            : (documentPreview && documentData.proof_document_image != "")  && <img src={documentData.proof_document_image} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                }
              </div>
            </div>
            {/* <a target="_blank" href={`http://139.59.17.191:5009${kyc.pan_card_image}`}>
                              <button className='px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Open Document</button>
                            </a> */}
            <div className='tw-modal-footer'>
              <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => {
                actionAdminYes();
              }}>Submit</button>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                setDocumentPopup(false)
              }}>Cancle</button>
            </div>
          </div>
        </div>

        <div className={`tw-modal ${rejectPopup ? 'flex' : 'hidden'} !items-start`}>
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>Document</div>
              <button onClick={() => setDocumentRejectData(false)}>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>

            <div className='tw-modal-body'>
              <div className="space-y-1">
                {/* <p className="input-label">Cheque</p> */}
                <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                  <div className='w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4'>
                    <div className="w-full space-y-1">
                      <div id="comment " className="input-wrap w-full">
                        <input type="text" className="block w-full text-sm text-[#808080]" placeholder="Remark" value={documentRejectData.comment} onChange={(e: any) => {
                          setDocumentRejectData({ ...documentRejectData, comment: e.target.value })
                        }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='tw-modal-footer'>
              <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => {
                actionReject(rejectedId)
              }}>Submit</button>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                setRejectPopup(false)
              }}>Cancle</button>
            </div>
          </div>
        </div>
        {/* </main> */}
      </>
    }
  </div>)
}

export default KycAdmin