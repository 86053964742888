import { useState } from 'react'
import { Outlet } from 'react-router'
import Pages from '../pages'
import Header from '../pages/components/header'
import Sidebar from '../pages/components/sidebar'

function Routes() {
    const [sidebarToggle, setSidebarToggle] = useState(true);
    return (
        <div>

            <Sidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
            <Header sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
            <main className={` ${sidebarToggle ? 'left-0 lg:pl-[220px]' : 'lg:pl-0 pl-[220px]'} transition-all pt-[70px]`} >
                <Outlet />
            </main>
        </div>
    )
}

export default Routes
