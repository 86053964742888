const STORAGEKEY = {
    token: 'token',
    authData: 'authData',
    userData: 'userData',
    userId: 'userId',
    layoutData: 'layoutData',
    roles: 'roles',
};

export default STORAGEKEY
