import { Box, Button, ClickAwayListener, Modal, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { PERMISSION_TYPE, userPermission } from '../../helper/permission';
import { isEmpty } from '../../helper/util';
import { RootState } from '../../redux/store';
import { getSlabValue } from './../../helper/util';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

function PayoutGrid() {

  const flatData = {
    normal_payout_grid: "",
    advance_payout_grid: "",
    disbursal_amount: "",
    loginCodeId: ""
  }

  const [flatSlotData, setFlatSlotData] = React.useState(flatData)
  const [loader, setLoader] = useState(true);
  const [normalSlot, setNormalSlot] = React.useState({
    normal_payout_slab: "",
    advance_payout_slab: ""
  })
  const [termsCondition, setTermsCondition] = React.useState([])
  const [addTermsConditionMulti, setAddTermsConditionMulti] = React.useState([""])
  const [termsConditionType, setTermsConditionType] = React.useState("")
  const [editOpen, setEditOpen] = React.useState(false);
  const [addNewRow, setAddNewRow] = React.useState(false);
  const [actionOpen, setActionOpen] = React.useState<boolean[]>([]);
  const [termsOpen, setTermsOpen] = React.useState<boolean[]>([]);
  const [termsHoverOpen, setTermsHoverOpen] = React.useState<boolean[]>([]);
  const [termsAdvanceOpen, setTermsAdvanceOpen] = React.useState<boolean[]>([]);
  const [termsAdvanceHoverOpen, setTermsAdvanceHoverOpen] = React.useState<boolean[]>([]);
  const [tcMopen, setTcMOpen] = React.useState(false);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [payoutGridDetail, setPayoutGridDetail] = useState([]);
  const [connectorPayoutGridDetail, setConnectorPayoutGridDetail] = useState([]);
  const [singlePayoutGridDetail, setSinglePayoutGridDetail] = useState([]);
  const [permission, setPermission] = useState<any>({});
  const [selected, setSelected] = useState<any>("");
  const user = useSelector((state: RootState) => state?.authUser?.userData?.user)

  let columns = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'bank', label: 'Bank/NBFC Name' },
    { id: 'product', label: 'Product' },
    { id: 'Normal', label: 'Normal Payout Slab' },
    { id: 'Advance', label: 'Advance Payout Slab' },
  ];

  if (permission[PERMISSION_TYPE.ADD_PAYOUT_GRID]) {
    columns = [...columns, { id: 'Action', label: 'Action' }]
  }


  const handlePageData = (limit: number, page: number = 1) => {
    setState({
      ...state,
      limit,
      page
    })
  }

  const handlePage = (page: number) => {
    setState({
      ...state,
      page
    })
  }

  const getTeamsAndCondition = (data: any) => {
    let temp: any = [];
    temp = [...temp, ...termsCondition];
    temp = [...temp, ...data];
    return temp.filter((value: any) => value != "")
  }

  const getPayoutGrid = async () => {
    await ApiGet(`loginCodeList/codeList?page=${state.page}&limit=${state.limit}`)
      .then((res: any) => {
        let temp = res.data.result.docs.map((item: any, index: number) => {
          return {
            id: item._id,
            SRNO: (((state.page - 1) * state.limit) + index + 1),
            bank: item.bank_name,
            product: item.product,
            Normal: getSlabValue(item, 'normal'),
            Advance: getSlabValue(item, 'advance'),
            termcondition: item.term_and_condition ?? [],
            selectedSlab: item.select ?? "",
            normal_payout_slab: item?.normal_payout_slab,
            advance_payout_slab: item?.advance_payout_slab,
            payout_grid: item?.payout_grid,
          }
        })
        setPayoutGridDetail(temp)

        const tempPermission = {
          [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(user, PERMISSION_TYPE.ADD_PAYOUT_GRID),
          [PERMISSION_TYPE.CONNECTOR]: userPermission(user, PERMISSION_TYPE.CONNECTOR),
        }
        setPermission(tempPermission)

        setActionOpen(temp.map(() => { return false }))
        setTermsOpen(temp.map(() => { return false }))
        setTermsAdvanceOpen(temp.map(() => { return false }))
        setTermsAdvanceHoverOpen(temp.map(() => { return false }))
        setState({
          ...state,
          totalItems: res.data.result.total
        })
      }).catch((error) => {
        const tempPermission = {
          [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(user, PERMISSION_TYPE.ADD_PAYOUT_GRID),
          [PERMISSION_TYPE.CONNECTOR]: userPermission(user, PERMISSION_TYPE.CONNECTOR),
        }
        setPermission(tempPermission)
      });
  }

  const getConnectorPayoutGrid = async () => {
    await ApiGet(`loginCodeList/payoutgrid`)
      .then((res: any) => {
        let temp = res?.data?.result?.payout_grid.map((item: any, index: number) => {
          return {
            id: item._id,
            SRNO: (((state.page - 1) * state.limit) + index + 1),
            bank: item?.bank_name,
            product: item?.product?.product,
            Normal: item?.normal_payout_grid,
            Advance: item?.advance_payout_grid,
            termcondition: item.term_and_condition ?? [],
            selectedSlab: item.select ?? "",
            normal_payout_slab: item?.normal_payout_slab,
            advance_payout_slab: item?.advance_payout_slab,
            payout_grid: item?.payout_grid,
          }
        })
        setConnectorPayoutGridDetail(temp)

        const tempPermission = {
          [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(user, PERMISSION_TYPE.ADD_PAYOUT_GRID),
          [PERMISSION_TYPE.CONNECTOR]: userPermission(user, PERMISSION_TYPE.CONNECTOR),
        }
        setPermission(tempPermission)

        setActionOpen(temp.map(() => { return false }))
        setTermsOpen(temp.map(() => { return false }))
        setTermsAdvanceOpen(temp.map(() => { return false }))
        setTermsAdvanceHoverOpen(temp.map(() => { return false }))
        setState({
          ...state,
          totalItems: 0
        })
      }).catch((error) => {
        const tempPermission = {
          [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(user, PERMISSION_TYPE.ADD_PAYOUT_GRID),
          [PERMISSION_TYPE.CONNECTOR]: userPermission(user, PERMISSION_TYPE.CONNECTOR),
        }
        setPermission(tempPermission)
      });
  }

  const getMainT_C = async () => {
    await ApiGet(`payoutGrid/main-term-condtion`)
      .then((res: any) => {
        setTermsCondition(res?.data?.termAndCondtion?.term_and_condition.length == 0 ? [""] : res?.data?.termAndCondtion?.term_and_condition)
      })
  }

  useEffect(() => {
    Promise.all([
      getPayoutGrid(),
      getConnectorPayoutGrid(),
      getMainT_C()
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [state.page, state.limit])

  const getPayoutGridByLogicCode = async (code: string) => {
    await ApiGet(`payoutGrid?codeListId=${code}`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            Amount: item.disbursal_amount,
            Normal: item.normal_payout_grid,
            Advance: item.advance_payout_grid,
          }
        })
        setSinglePayoutGridDetail(temp)
      })
  }

  useEffect(() => {
    if (flatSlotData.loginCodeId != "") getPayoutGridByLogicCode(flatSlotData.loginCodeId)
  }, [flatSlotData.loginCodeId])

  // popup handle
  const handleChange = (e: any) => {
    setFlatSlotData({ ...flatSlotData, [e.target.name]: e.target.value });
  };

  const addnormal = (id: any) => {
    if (selected == "variable_slab") {
      ApiPost("payoutGrid", flatSlotData)
        .then((res: any) => {
          getPayoutGridByLogicCode(flatSlotData.loginCodeId)
          const { loginCodeId, ...rest } = flatData
          setFlatSlotData({ ...flatSlotData, ...rest })
          getPayoutGrid();
        }).catch((error) => {
          console.log(error);
        })

      setAddNewRow(false);
    } else {
      ApiPut(`logincodelist/${flatSlotData.loginCodeId}`, normalSlot)
        .then((res: any) => {
          setEditOpen(false);
          getPayoutGrid();
        })
    }
  }


  const Addterms = (type: string) => {
    if (termsConditionType === 'main') {
      ApiPost("payoutGrid/main-term-condtion", {
        term_and_condition: addTermsConditionMulti
      })
        .then((res: any) => {
          setTcMOpen(false);
          getPayoutGrid();
          getMainT_C();
        })
    } else {
      const body = {
        codeListId: flatSlotData.loginCodeId,
        term_and_condition: addTermsConditionMulti
      }
      ApiPost("logincodelist/term-condition", body)
        .then((res: any) => {
          setTcMOpen(false);
          getPayoutGrid();
        })
    }
  }

  return (<div className="overflow-x-hidden">

    {/* <Sidebar />
    <Header /> */}
    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>
        {/* START::MAIN CONTENT */}
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5  min-h-[calc(100vh-70px)]">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
              <h1 className="text-[22px] text-light-gray font-semibold">Payout Grid</h1>
              {permission[PERMISSION_TYPE.ADD_PAYOUT_GRID] &&
                <button className='bg-secondary text-white py-2 px-3 rounded-lg text-sm font-medium' onClick={() => {
                  setAddTermsConditionMulti(termsCondition)
                  setTermsConditionType("main")
                  setTcMOpen(true)
                }}>Add T & C</button>
              }
            </div>

            {/* START::EXPORT */}
            <ul className="flex space-x-[18px]">
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/pdf.svg" alt="" />
                  <span>PDF</span>
                </button>
              </li>
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/excel.svg" alt="" />
                  <span>EXCEL</span>
                </button>
              </li>
            </ul>
            {/* END::EXPORT */}
          </div>


          {/* MODEL-----Start---------------- */}

          {/* Start::Terms and Condition Modal */}
          <div className={`tw-modal ${tcMopen ? 'flex' : 'hidden'}`}>
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add Terms and Condition</div>
                <button onClick={() => setTcMOpen(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className='tw-modal-body py-6'>
                <ol className='list-decimal ml-4 text-xs text-[#808080] leading-[26px] space-y-4'>
                  {addTermsConditionMulti.map((tc, index) => (
                    <li className='flex items-center gap-4'>
                      <span>{index + 1}.</span>
                      <input
                        className='w-full py-4 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                        type="text"
                        name="termscondition"
                        placeholder='Add Terms and Condition'
                        value={tc}
                        onChange={(e: any) => {
                          let temp = [...addTermsConditionMulti];
                          temp[index] = e.target.value;
                          setAddTermsConditionMulti(temp)
                        }}
                      />
                      <button className="flex items-center space-x-1 text-sm text-red-500 ml-auto my-1" onClick={() => {
                        let temp = [...addTermsConditionMulti];
                        let filterData = temp.filter((x, i) => i != index)
                        setAddTermsConditionMulti(filterData)
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button>
                    </li>
                  ))}
                </ol>
              </div>

              <div className="py-3 px-6">
                <button className="ml-auto flex items-center space-x-1 text-sm" onClick={() => {
                  setAddTermsConditionMulti([...addTermsConditionMulti, ""])
                }}>
                  <img src="assets/plus-green.svg" alt="" />
                  <span>Add New Row</span>
                </button>
              </div>

              <div className='tw-modal-footer'>
                <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => Addterms('multiple')}>Save</button>
                <button className='tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary' onClick={() => setTcMOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
          {/* End::Terms and Condition Modal */}

          {/* Start::Edit Payout Grid Modal */}
          <div className={`tw-modal ${editOpen ? 'flex' : 'hidden'}`}>
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Edit Payout Grid</div>
                <button onClick={() => setEditOpen(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className='tw-modal-body py-5'>
                <div className='col-span-2 space-y-6'>
                  <div className='w-full relative'>
                    <img className='absolute left-6 top-1/2 transform -translate-y-1/2' src="assets/line-bar.svg" alt="" />
                    <select name="" id="" value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full py-4 pr-[22px] pl-[44px] border text-sm rounded-lg border-[#DEDEDE]'>
                      <option selected>Select Slab</option>
                      <option value="flat_slab">Flat Slab</option>
                      <option value="variable_slab">Variable Slab</option>
                    </select>
                  </div>
                  {selected == "flat_slab" &&
                    <div className='space-y-2'>
                      <div className='w-full flex gap-5'>
                        <div className='w-full space-y-2'>
                          <label htmlFor="normal_payout_slab" className="input-label">Normal Payout</label>
                          <input
                            className='w-full py-4 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                            type="text"
                            name="normal_payout_slab"
                            placeholder='2.55'
                            value={normalSlot.normal_payout_slab}
                            onChange={(e: any) => setNormalSlot({ ...normalSlot, normal_payout_slab: e.target.value })}
                          />
                        </div>
                        <div className='w-full space-y-2'>
                          <label htmlFor="advance_payout_slab" className="input-label">Advance Payout</label>
                          <input
                            className='w-full py-4 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                            type="text"
                            name="advance_payout_slab"
                            placeholder='2.45'
                            value={normalSlot.advance_payout_slab}
                            onChange={(e: any) => setNormalSlot({ ...normalSlot, advance_payout_slab: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                  }

                </div>
                {selected == "variable_slab" && <div className="overflow-x-auto">
                  <table className='w-full'>
                    <thead className='text-sm text-light-gray bg-gray-200'>
                      <tr>
                        <th className='py-3 px-5 min-w-[220px]'>Disbursal Amount</th>
                        <th className='py-3 px-5 min-w-[220px]'>Normal Payout Grid</th>
                        <th className='py-3 px-5 min-w-[220px]'>Advance Payout Grid</th>
                      </tr>
                    </thead>
                    <tbody className='bg-gray-100 text-sm text-light-gray'>
                      {singlePayoutGridDetail?.map((details: any) => (
                        <tr className='border-b'>
                          <td className='py-3 px-5 min-w-[220px] text-center'>{details.Amount}</td>
                          <td className='py-3 px-5 min-w-[220px] text-center'>{details.Normal}</td>
                          <td className='py-3 px-5 min-w-[220px] text-center'>{details.Advance}</td>
                        </tr>
                      ))}
                      {addNewRow &&
                        <tr className='border-b'>
                          <td className='py-3 px-5 min-w-[220px] text-center'>
                            <input
                              className='w-full py-2 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                              type="text"
                              name="disbursal_amount"
                              placeholder='0-100'
                              value={flatSlotData.disbursal_amount}
                              onChange={(e: any) => { handleChange(e) }}
                            />
                          </td>
                          <td className='py-3 px-5 min-w-[220px] text-center'>
                            <input
                              className='w-full py-2 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                              type="text"
                              name="normal_payout_grid"
                              placeholder='2.55%'
                              value={flatSlotData.normal_payout_grid}
                              onChange={(e: any) => { handleChange(e) }}
                            />
                          </td>
                          <td className='py-3 px-5 min-w-[220px] text-center'>
                            <input
                              className='w-full py-2 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                              type="text"
                              name="advance_payout_grid"
                              placeholder='2.45%'
                              value={flatSlotData.advance_payout_grid}
                              onChange={(e: any) => { handleChange(e) }}
                            />
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                }

                {(selected == "variable_slab" && !addNewRow) &&
                  //  permission[PERMISSION_TYPE.ADD_PAYOUT_GRID] &&
                  <button className="flex items-center space-x-1 text-sm" onClick={() => {
                    setAddNewRow(true);
                  }}>
                    <img src="assets/plus-green.svg" alt="" />
                    <span>Add New Row</span>
                  </button>
                }
              </div>

              <div className='tw-modal-footer'>
                <button className='tw-modal-footer-button bg-secondary text-white' onClick={addnormal}>Save</button>
                <button className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary" onClick={() => setEditOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
          {/* End::Edit Payout Grid Modal */}

          {/* MODEL-----End---------------- */}


          {/* END::DASHBOARD HEADER */}
          <TableComponent
            rows={permission[PERMISSION_TYPE.CONNECTOR] ? connectorPayoutGridDetail : payoutGridDetail}
            columns={columns}
            handlePageData={handlePageData}
            handlePage={handlePage}
            state={state}
            renderColumn={(column: any) => {
              return (
                <TableCell
                  align="center"
                  className="!bg-gray-200"
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              )
            }}
            renderRow={(row: any, index: number) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell align="center" component="th" scope="row">
                    {row.SRNO}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.bank}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.product}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <span className="relative">
                      <span className='flex items-center justify-center space-x-2'><span>{parseFloat(row.Normal).toFixed(2)}</span> <img src="assets/green_alert.svg" alt=""
                        onClick={() => {
                          let tempClose: boolean[] = [...termsOpen];
                          tempClose = tempClose.map(() => { return false })
                          setTermsOpen(tempClose)
                          let temp: boolean[] = [...termsHoverOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = !temp[index]
                          setTermsHoverOpen(temp)
                        }}
                        onMouseEnter={() => {
                          let temp: boolean[] = [...termsOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = true
                          if (termsHoverOpen.every((open) => open == false)) {
                            setTermsOpen(temp)
                          }
                        }} onMouseLeave={() => {
                          let temp: boolean[] = [...termsOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = false
                          if (termsHoverOpen.every((open) => open == false)) {
                            setTermsOpen(temp)
                          }
                        }} /></span>
                      {(termsOpen[index] || termsHoverOpen[index]) && (
                        <ClickAwayListener onClickAway={() => {
                          let temp: boolean[] = [...termsHoverOpen];
                          temp = temp.map(() => { return false })
                          // setTermsAdvanceHoverOpen(temp)
                        }}><div className="absolute top-[calc(100%+10px)] w-[300px] right-full shadow-lg z-[777] text-[#808080]">
                            <div className='flex justify-between bg-primary py-4 px-5'>
                              <p className="text-white text-sm">Terms</p>
                            </div>
                            <div className="py-4 px-5 bg-white">
                              <ol className="list-decimal ml-5 text-left space-y-4 text-xs">
                                {getTeamsAndCondition(row.termcondition).length == 0 ? <li>No Term & Condition</li> : getTeamsAndCondition(row.termcondition).map((item: any) => (
                                  <li>{item}</li>
                                ))}
                              </ol>
                              {(!isEmpty(row?.payout_grid) && row?.selectedSlab == "variable_slab") && (
                                <>
                                  <h6 className="font-semibold py-4 text-light-gray text-left">Variable Slab</h6>
                                  <table className='w-full text-xs'>
                                    <thead className='text-light-gray bg-gray-200'>
                                      <tr>
                                        <th className='py-3 px-5 max-w-[100px]'>Amount</th>
                                        <th className='py-3 px-5 max-w-[100px]'>Normal</th>
                                        <th className='py-3 px-5 max-w-[100px]'>Advance</th>
                                      </tr>
                                    </thead>
                                    <tbody className='bg-gray-100 text-light-gray'>
                                      {row?.payout_grid.map((data: any) => (
                                        <tr className='border-b'>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.disbursal_amount}
                                          </td>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.normal_payout_grid}
                                          </td>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.advance_payout_grid}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </div>
                          </div>
                        </ClickAwayListener>)
                      }
                    </span>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <span className="relative">
                      <span className='flex items-center justify-center space-x-2'><span>{parseFloat(row.Advance).toFixed(2)}</span> <img src="assets/plus-green.svg" className="w-3" alt=""
                        onClick={() => {
                          let tempClose: boolean[] = [...termsAdvanceOpen];
                          tempClose = tempClose.map(() => { return false })
                          setTermsAdvanceOpen(tempClose)
                          let temp: boolean[] = [...termsAdvanceHoverOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = !temp[index]
                          setTermsAdvanceHoverOpen(temp)
                        }}
                        onMouseEnter={() => {
                          let temp: boolean[] = [...termsAdvanceOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = true
                          if (termsAdvanceHoverOpen.every((open) => open == false)) {
                            setTermsAdvanceOpen(temp)
                          }
                        }} onMouseLeave={() => {
                          let temp: boolean[] = [...termsAdvanceOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = false
                          if (termsAdvanceHoverOpen.every((open) => open == false)) {
                            setTermsAdvanceOpen(temp)
                          }
                        }} /></span>

                      {(termsAdvanceOpen[index] || termsAdvanceHoverOpen[index]) && (
                        <ClickAwayListener onClickAway={() => {
                          let temp: boolean[] = [...termsAdvanceHoverOpen];
                          temp = temp.map(() => { return false })
                          // setTermsAdvanceHoverOpen(temp)
                        }}>
                          <div className="absolute top-[calc(100%+10px)] w-[300px] right-full shadow-lg z-[777] text-[#808080]">
                            <div className='flex justify-between bg-primary py-4 px-5'>
                              <p className="text-white text-sm">Terms</p>
                            </div>
                            <div className="py-4 px-5 bg-white">
                              <ol className="list-decimal ml-5 text-left space-y-4 text-xs">
                                {getTeamsAndCondition(row.termcondition).map((item: any) => (
                                  <li>{item}</li>
                                ))}
                              </ol>
                              {(!isEmpty(row?.payout_grid) && row?.selectedSlab == "variable_slab") && (
                                <>
                                  <h6 className="font-semibold py-4 text-light-gray text-left">Variable Slab</h6>
                                  <table className='w-full text-xs'>
                                    <thead className='text-light-gray bg-gray-200'>
                                      <tr>
                                        <th className='py-3 px-5 max-w-[100px]'>Amount</th>
                                        <th className='py-3 px-5 max-w-[100px]'>Normal</th>
                                        <th className='py-3 px-5 max-w-[100px]'>Advance</th>
                                      </tr>
                                    </thead>
                                    <tbody className='bg-gray-100 text-light-gray'>
                                      {row?.payout_grid.map((data: any) => (
                                        <tr className='border-b'>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.disbursal_amount}
                                          </td>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.normal_payout_grid}
                                          </td>
                                          <td className='py-3 px-5 max-w-[100px] text-center'>
                                            {data?.advance_payout_grid}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </div>
                          </div>
                        </ClickAwayListener>)
                      }
                    </span>
                  </TableCell>
                  {permission[PERMISSION_TYPE.ADD_PAYOUT_GRID] &&
                    <TableCell align="center" component="th" scope="row">
                      <button className='relative'>
                        <img className='mx-auto' onClick={() => {
                          let temp: boolean[] = [...actionOpen];
                          if (!temp[index]) {
                            temp = temp.map(() => { return false })
                          }
                          temp[index] = !temp[index]
                          setActionOpen(temp)
                        }} src="assets/menu-cirlce.svg" alt="" />
                        {actionOpen[index] &&

                          <ClickAwayListener onClickAway={() => {
                            let temp: boolean[] = [...actionOpen];
                            temp = temp.map(() => { return false })
                            setActionOpen(temp)
                          }}>
                            <ul className='absolute bg-white w-[120px] top-full text-light-gray right-0 z-[11] border shadow-2xl'>
                              <li>
                                <a href="" onClick={(e) => {
                                  e.preventDefault();
                                  setSelected(row?.selectedSlab)
                                  setEditOpen(true);
                                  setNormalSlot({
                                    normal_payout_slab: row.normal_payout_slab,
                                    advance_payout_slab: row.advance_payout_slab
                                  })
                                  setFlatSlotData({ ...flatSlotData, loginCodeId: row.id });
                                }} className='px-4 border-b py-[10px] text-sm flex items-center space-x-2'>
                                  <img src="assets/edit-pencil.svg" alt="" />
                                  <span>Edit</span>
                                </a>
                              </li>
                              <li>
                                <a href="" onClick={(e) => {
                                  e.preventDefault();
                                  setTermsConditionType('multi')
                                  setTcMOpen(true);
                                  setAddTermsConditionMulti(row?.termcondition.length == 0 ? [""] : row?.termcondition)
                                  setFlatSlotData({ ...flatSlotData, loginCodeId: row.id });
                                }} className='px-4 border-b py-[10px] text-sm flex items-center space-x-2'>
                                  <img src="assets/plus-gray.svg" alt="" />
                                  <span>Add T&C</span>
                                </a>
                              </li>
                            </ul>
                          </ClickAwayListener>
                        }
                      </button>
                    </TableCell>
                  }
                </TableRow>
              )
            }}
          />
        </div>
        {/* </main> */}
        {/* END::MAIN CONTENT */}
      </>
    }
  </div>)
}

export default PayoutGrid