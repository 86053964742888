import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableContainer } from "@mui/material";
import Pagination from './pagination';
import { useLocation } from "react-router";


const TableComponent = (props: any) => {
  let { rows, columns, renderColumn, renderRow } = props;
  const { onChange, handlePageData, handlePage, state } = props;
  const [gotoPage, setGoToPage] = useState("");
  const location = useLocation();
  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{
          "&::-webkit-scrollbar": {
            width: "15px",
            height: "15px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: 1
          }
        }} className={location.pathname == "/payoutgrid" ? '!overflow-visible' : ''}>
          <Table stickyHeader aria-label="sticky table" className="!bg-gray-200">
            <TableHead >
              <TableRow>
                {columns?.map(renderColumn)}
              </TableRow>
            </TableHead>
            <TableBody className="!bg-gray-100">
              {rows?.map(renderRow)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {Number(state.totalItems) !== 0 &&
        <div className="px-6 py-3 bg-gray-200 md:flex md:space-x-2 space-y-2 items-center justify-center md:justify-between">
          <Pagination
            className="pagination-bar"
            currentPage={Number(state.page)}
            totalCount={Number(state.totalItems)}
            pageSize={Number(state.limit)}
            onPageChange={(page: any) => handlePage(page)}
          />
          <ul className="flex items-center space-x-2 text-xs]">
            <li className="flex space-x-2 items-center">
              <label htmlFor="show" className="text-light-gray">Show</label>
              <select name="limitData" value={state.limit} onChange={(e) => handlePageData(e.target.value)} className="py-1 px-2 bg-white rounded-lg border border-[#D8D8D8]">
                <option value={10}>10 row</option>
                <option value={20} selected>20 row</option>
                <option value={30} selected>30 row</option>
                <option value={40} selected>40 row</option>
                <option value={50} selected>50 row</option>
              </select>
            </li>
            <li className="flex space-x-2 items-center">
              <label htmlFor="show" className="text-light-gray">Go to Page</label>
              <input value={gotoPage} onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setGoToPage(e.target.value);
                }
              }} className="py-1 px-2 bg-white rounded-lg border border-[#D8D8D8] w-12" />
              <a onClick={(e) => {
                e.preventDefault();
                handlePage(gotoPage)
                setGoToPage("")
              }} className="text-secondary cursor-pointer">Go &gt;</a>
            </li>
          </ul>
        </div>
      }
    </>
  );
};

export default TableComponent
