import { Checkbox, TableCell, TableRow, TextField } from '@mui/material';
import React, { useState } from 'react'
import TableComponent from '../../components/Tables';
import { useEffect } from 'react';
import DatePicker from "react-datepicker";
import { ApiGet, ApiPost } from '../../helper/API/ApiData';
import { STATE } from '../../constants';
import axios from 'axios';
import _ from 'lodash';
import { isEmpty, numberFormat } from '../../helper/util';
import { getSlabValue } from './../../helper/util';
import STORAGEKEY from '../../config/APP/app.config';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Logout_Auth } from '../../redux/reducers/authReducer';
import { PERMISSION_TYPE, userPermission } from '../../helper/permission';
import { RootState } from '../../redux/store';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from 'react-toastify';
import CsvDownload from 'react-json-to-csv'
import moment from 'moment';

const DisbursementData = () => {

  const disbursementData = {
    borrower_name: "",
    lan_no: "",
    // pan_no: "",
    contact_person_name: "",
    loan_amount: "",
    pincode: "",
    financer_name: "",
    product: "",
    month_of_disbursement: new Date(),
    mobile: "",
    borrower_name_error: null,
    lan_no_error: null,
    loan_amount_error: null,
    pincode_error: null,
    financer_name_error: null,
    product_error: null,
    // pan_no_error: null,
    contact_person_name_error: null,
    month_of_disbursement_error: null,
    mobile_error: null,
    connector: "",
    connector_error: null,
    state_error:null,
    city_error:null,
state:"",
city:""
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addOpen, setAddOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isconnector, setConnector] = useState(false);
  const [connectorList, setConnectorList] = useState([]);
  const [activeTab, setActiveTab] = useState("initiated");

  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const [formData, setFormData] = useState<any>(disbursementData);
  const [disbursementDetail, setDisbursementDetail] = useState([]);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [l1l2Detail, setL1L2Detail] = useState([]);
  const [stateLevel, setStateLevel] = useState(STATE.DEFAULT_STATE);
  const [misMatchDetail, setMisMatchDetail] = useState([]);
  const [stateMisMatch, setStateMisMatch] = useState(STATE.DEFAULT_STATE);
  const [bankData, setBankData] = useState<any>({});
  const [monthData, setMonthData] = useState<string[]>(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
  const [permission, setPermission] = useState<any>({});
  const userData = useSelector((state: RootState) => state?.authUser?.userData?.user)
  const [selectedFile, setSelectedFile] = useState<File | string>();
  const [getAllData, setGetAllData] = useState<any>([]);
  const [getAccNo, setgetAccNo] = useState<any>();

  useEffect(() => {
    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER)
    }
    setPermission(tempPermission)

  }, [userData])

  const getData = () => {
    ApiGet(`disbursement?page=${state.page}&&limit=${state.limit}&&status=${activeTab}`)
      .then((res: any) => {
        let finalData = res.data.result.docs.map((item: any, index: number) => {
          return {
            SRNO: (((state.page - 1) * state.limit) + index + 1),
            id: item._id,
            BorrowerName: item.borrower_name ?? "-",
            Contact_Person_Name: item.contact_person_name == "" ? "-" : item.contact_person_name,
            Loanamount: item.loan_amount ?? "-",
            Financer: item.financer_name ?? "-",
            Product: item?.product?.product ?? "-",
            dismonth: item.month_of_disbursement ?? "-",
            Connectorname: item?.connector?.name ?? "-",
            Grosspayout: item?.gross_payout ?? "0",
            Paymentstatus: item.payment_status ?? "-",
          }
        })
        setDisbursementDetail(finalData)
        setState({
          ...state,
          totalItems: res.data.result.total
        })
      })
      .catch((error) => {
        setDisbursementDetail([])
      })
  }

  const getL1L2Data = () => {
    ApiGet(`disbursement/level-payout?page=${stateLevel.page}&&limit=${stateLevel.limit}`)
      .then((res: any) => {
        let finalData = res.data.result.map((item: any, index: number) => {
          return {
            SRNO: (((stateLevel.page - 1) * stateLevel.limit) + index + 1),
            ...item
          }
        })
        setL1L2Detail(finalData)
        setStateLevel({
          ...stateLevel,
          totalItems: res.data.total
        })
      })
      .catch((error) => {
        setL1L2Detail([])
      })
  }

  const getMisMatchData = () => {
    ApiGet(`disbursement/mismatch-data?page=${stateMisMatch.page}&&limit=${stateMisMatch.limit}`)
      .then((res: any) => {
        let finalData = res.data.result.docs.map((item: any, index: number) => {
          return {
            SRNO: (((stateMisMatch.page - 1) * stateMisMatch.limit) + index + 1),
            ...item
          }
        })
        setMisMatchDetail(finalData)
        setStateMisMatch({
          ...stateMisMatch,
          totalItems: res.data.result.total
        })
      })
      .catch((error) => {
        setMisMatchDetail([])
      })
  }

  const getAllDisbursementData = () => {
    ApiGet(`disbursement/csv-data`)
      .then((res: any) => {
        setGetAllData(res.data.result)
      })
      .catch((error) => {
        setGetAllData([])
      })
  }

  const getConnector = () => {
    ApiGet('connector/getConnector')
      .then((res: any) => {
        let temp = res.data.result.map((x: any) => {
          return {
            id: x._id,
            label: x.email
          }
        })
        setConnectorList(temp);
      }).catch((error) => {
        setConnectorList([])
      })
  }

  const getLoginCodeList = async () => {
    await ApiGet(`logincodelist`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            bank: item.bank_name,
            product: item.product,
            id: item._id
          }
        })
        const bankData = _.groupBy(temp, function (b) { return b.bank })
        setBankData(bankData)
      }).catch((error) => {
        setBankData({})
      })
  }

  const handlePage = (page: number) => {
    if (activeTab == "initiated") {
      setState({
        ...state,
        page
      })
    }
    if (activeTab == "l1l2") {
      setStateLevel({
        ...stateLevel,
        page
      })
    }
    if (activeTab == "mismatch") {
      setStateMisMatch({
        ...stateMisMatch,
        page
      })
    }
  }


  const handlePageData = (limit: number, page: number = 1) => {
    if (activeTab == "initiated") {
      setState({
        ...state,
        limit,
        page
      })
    }
    if (activeTab == "l1l2") {
      setStateLevel({
        ...stateLevel,
        limit,
        page
      })
    }
    if (activeTab == "mismatch") {
      setStateMisMatch({
        ...stateMisMatch,
        limit,
        page
      })
    }
  }

  useEffect(() => {
    Promise.all([
      getData(),
      getConnector(),
      getLoginCodeList(),
      getAllDisbursementData()
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [state.page, state.limit, activeTab]);

  useEffect(() => {
    Promise.all([
      getMisMatchData(),
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [stateMisMatch.page, stateMisMatch.limit]);

  useEffect(() => {
    Promise.all([
      getL1L2Data(),
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [stateLevel.page, stateLevel.limit]);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const addDisbursement = () => {
    let error = { ...formData };
    if (formData.borrower_name == "") {
      error = { ...error, borrower_name_error: "Please enter a borrower name" };
    }
    // if (formData.lan_no == "") {
    //   error = { ...error, lan_no_error: "Please enter a lan no" };
    // }
    // if (formData.pan_no == "") {
    //   error = { ...error, pan_no_error: "Please enter a pancard no" };
    // }
    if (formData.loan_amount == "") {
      error = { ...error, loan_amount_error: "Please enter a loan amount" };
    }
    if (formData.pincode == "") {
      error = { ...error, pincode_error: "Please enter a pincode" };
    }
    if (formData.pincode == "") {
      error = { ...error, pincode_error: "Please enter a pincode" };
    }
    let pincodePatten = /^(\d{6})$/;
    if (formData.pincode != "" && !pincodePatten.test(formData.pincode)) {
      error = { ...error, pincode_error: "Please enter a valid pincode" };
    }
    if (formData.financer_name == "") {
      error = { ...error, financer_name_error: "Please enter a financer name" };
    }
    if (formData.product == "") {
      error = { ...error, product_error: "Please enter a product" };
    }
    if (formData.month_of_disbursement == "") {
      error = { ...error, month_of_disbursement_error: "Please enter a Month" };
    }
    // if (formData.contact_person_name == "") {
    //   error = { ...error, contact_person_name_error: "Please enter a person name" };
    // }
    if ((formData.connector == "" && isconnector)) {
      error = { ...error, connector_error: "Please enter a connector" };
    }
    let mobilePatten = /^(\d{10})$/;
    if (formData.mobile != "" && !mobilePatten.test(formData.mobile)) {
      error = { ...error, mobile_error: "Please enter a valid mobile" };
    }
    setFormData(error)
    if (error.borrower_name_error == null && error.loan_amount_error == null && error.pincode_error == null && error.financer_name_error == null && error.product_error == null && error.month_of_disbursement_error == null && error.connector_error == null) {
      const { borrower_name_error, lan_no_error, loan_amount_error, pincode_error, financer_name_error, product_error, month_of_disbursement_error, connector_error, contact_person_name_error, mobile_error, ...payload } = formData
      let finalData = payload;
      if (formData.connector == "") {
        let { connector, ...payload } = finalData
        finalData = payload
      }

      ApiPost("disbursement", finalData)
        .then((res: any) => {
          setFormData(disbursementData)
          getData();
          setAddOpen(false)
        })
        .catch((error) => {
          console.log(error);
          if (error?.error == "please complete your profile") {
            toast.error("Please validate your KYC before adding disbursement data.", {
              position: "top-right",
              theme: "colored"
            })
          }
        })
    }
  }

  const disbursementcsv = async () => {
    let formData = new FormData()

    if (selectedFile) {
      formData.append("filedata", selectedFile);
    }

    await ApiPost(`disbursement/add-utr-csv`, formData)
      .then((res: any) => {
        if (res?.data?.message == "upload successfully") {
          toast.success("upload Successfully.", {
            position: "top-right",
            theme: "colored"
          })
          getData()
          getConnector()
          getLoginCodeList()
        }

        setSelectedFile("");
      }).catch((error: any) => {
        toast.error(error?.error, {
          position: "top-right",
          theme: "colored"
        })
      })
  }

  useEffect(() => {
    if (selectedFile) {
      disbursementcsv()
    }
  }, [selectedFile])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = getAllData.map((row: any) => row._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;


  // const columns = ["SR.NO.", "Name", "City", "State", "Contact No.", "Total Billing", "TotalLoan Amount"];
  const columns = [
    { id: '', label: '' },
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'BorrowerName', label: 'Borrower Name' },
    { id: 'Contact_Person_Name', label: 'Contact Person' },
    { id: 'Loanamount', label: 'Loan Amount' },
    { id: 'Financer', label: 'Financer' },
    { id: 'Product', label: 'Product' },
    { id: 'dismonth', label: 'Dis. Month' },
    { id: 'Connectorname', label: 'Connector Name' },
    { id: 'Grosspayout', label: 'Gross Payout' },
    { id: 'Paymentstatus', label: 'Payment Status' },
  ];

  const columnsLevel = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'borrower_name', label: 'Borrower Name' },
    { id: 'user', label: 'User' },
    { id: 'month_of_disbursement', label: 'Month Of Disbursement' },
    { id: 'l1_dsa', label: 'L1 DSA' },
    { id: 'l2_dsa', label: 'L2 DSA' },
    { id: 'product', label: 'Product' },
    { id: 'loan_amount', label: 'Loan Amount' },
    { id: 'l1_up_expense', label: 'L1 Up Expense' },
    { id: 'l2_up_expense', label: 'L2 Up Expense' },
  ];

  const columnsMisMatch = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'bank_name', label: 'Bank Name' },
    { id: 'customer_name', label: 'Customer Name' },
    { id: 'lan_Number', label: 'Lan Number' },
    { id: 'loan_amount', label: 'Loan Amount' },
    { id: 'month_and_year', label: 'Month And Year' },
    { id: 'payout_in_percetage', label: 'Payout In Percetage' },
    { id: 'product', label: 'product' },
  ];

  // const exportPDF = () => {
  //   if (selected.length > 0) {
  //     const unit = "pt";
  //     const size = "A4"; // Use A1, A2, A3 or A4
  //     const orientation = "portrait"; // portrait or landscape

  //     const marginLeft = 40;
  //     const doc: any = new jsPDF(orientation, unit, size);

  //     doc.setFontSize(15);

  //     const title = "Report";
  //     const headers = [columns.filter((x: any) => (x.id != 'SRNO' && x.id != '')).map(column => column.label)];

  //     const data = selected.map((data: any) => {
  //       let finalData: any = disbursementDetail.find((item: any) => item?.id == data) ?? {}
  //       const { id, SRNO, ...payload } = finalData
  //       return Object.values(payload)
  //     });

  //     let content = {
  //       startY: 50,
  //       head: headers,
  //       body: data
  //     };

  //     doc.text(title, marginLeft, 40);
  //     doc.autoTable(content);
  //     doc.save("report.pdf")
  //   } else {
  //     toast.error("Select at least 1 row.", {
  //       position: "top-right",
  //       theme: "colored"
  //     })
  //   }
  // }


  const getPaymentMode = (row: any) => {
    if (row?.user?.kyc?.ifsc.startsWith("ICICI")) {
      return "FT"
    }
    if (row?.loan_amount >= 200000) {
      return "RTGS"
    }
    return "NEFT"
  }

  const getaccountnumber = async () => {
    await ApiGet(`basicDetails/list`)
      .then((res: any) => {
        setgetAccNo(res.data.result.account_number)
      }).catch((error) => {

      })
  }

  useEffect(() => {
    getaccountnumber()
  }, [])
  const getExportData = (data: any) => {
    let finalData: any = [];
    let temp = data?.map((row: any) => {
      if (selected.includes(row._id)) {
        finalData.push({
          PYMT_PROD_TYPE_CODE: "PAB_VENDOR",
          PYMT_MODE: getPaymentMode(row),
          DEBIT_ACC_NO: getAccNo,
          BNF_NAME: row?.user?.name,
          BENE_ACC_NO: row?.user?.kyc?.account_number,
          BENE_IFSC: row?.user?.kyc?.ifsc,
          AMOUNT: row?.net_amount_payable,
          MOBILE_NUM: row?.user?.mobile,
          EMAIL_ID: row?.user?.email,
          PYMT_DATE: moment().format("DD/MM/YYYY").toString()
        })
      }
      return row
    })
    return finalData
  }


  const postget = async (e: any) => {
    const value = e.target.value;
      setFormData({ ...formData, pincode: e.target.value, pincode_error: null ,state_error: null, city_error: null});
    if (value.length >= 6) {
        await axios.get(`https://api.postalpincode.in/pincode/${value}`)
            .then((res: any) => {
              setFormData({
                ...formData,
                state: res?.data[0]?.PostOffice[0]?.State ?? "",
                city: res?.data[0]?.PostOffice[0]?.District ?? "",
                pincode: value,
                city_error: null,
                state_error: null,
                pincode_error: null
              });
                // setState(res?.data[0]?.PostOffice[0]?.State);
                // setCity(res?.data[0]?.PostOffice[0]?.District);
            })
    }
}

  return (<div className="overflow-x-hidden">
    {/* <Sidebar />
<Header /> */}
    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
              <h1 className="text-[22px] text-light-gray font-semibold">Disbursement Data</h1>

              {/* 
                    if(tab active) 
                        Add class "bg-white rounded-[18px] text-primary"
                    else 
                        Remove class "bg-white rounded-[18px] text-primary"
                */}


              {/* for-user */}
              {permission[PERMISSION_TYPE.USER] && <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                <li>
                  <button className={`py-2 px-7 text-xs font-medium`} onClick={(e) => {
                    e.preventDefault();
                    setAddOpen(true)
                  }}>Add Disbursement Data</button>
                </li>
              </ul>}


              {/* //for admin */}
              {permission[PERMISSION_TYPE.ADMIN] && <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                <li>
                  <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "initiated" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("initiated")}>Payment Initiated</button>
                </li>
                <li>
                  <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "mismatch" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("mismatch")}>MisMatch Data</button>
                </li>
                <li>
                  <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "l1l2" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("l1l2")}>L1-L2 Payout</button>
                </li>
              </ul>}
            </div>

            {/* START::EXPORT */}
            <ul className="flex space-x-[18px]">
              {(permission[PERMISSION_TYPE.ADMIN] && activeTab == "initiated") &&
                <li>
                  <input
                    id="fileChoosen"
                    type="file"
                    className="hidden"
                    onChange={(e: any) => {
                      if (!e.target.files || e.target.files.length === 0) {
                        window.location.reload();
                        return;
                      }
                      setSelectedFile(e.target.files[0]);
                    }}
                    onClick={(event: any) => {
                      event.target.value = null
                    }}
                  />
                  <label htmlFor="fileChoosen" className="flex items-center max-w-[200px] space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibolds">
                    Upload UTR File
                  </label>
                </li>
              }
              {activeTab == "initiated" &&
                <li>
                  <CsvDownload
                    data={getExportData(getAllData)}
                    filename="report.csv"
                    className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold"
                  >
                    Export
                  </CsvDownload>
                  {/* <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold" onClick={exportPDF}>
                  <img src="assets/calendar.svg" alt="" />
                  <span>Export</span>
                </button> */}
                </li>
              }
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/pdf.svg" alt="" />
                  <span>PDF</span>
                </button>
              </li>
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/excel.svg" alt="" />
                  <span>EXCEL</span>
                </button>
              </li>
            </ul>
            {/* END::EXPORT */}
          </div>

          {/* END::DASHBOARD HEADER */}
          <TableComponent
            rows={activeTab == "initiated" ? disbursementDetail : (activeTab == "l1l2" ? l1l2Detail : misMatchDetail)}
            columns={activeTab == "initiated" ? columns : (activeTab == "l1l2" ? columnsLevel : columnsMisMatch)}
            handlePageData={handlePageData}
            handlePage={handlePage}
            state={activeTab == "initiated" ? state : (activeTab == "l1l2" ? stateLevel : stateMisMatch)}
            renderColumn={(column: any, index: number) => {
              if (index == 0 && activeTab == "initiated") {
                return (
                  <TableCell padding="checkbox" align="center"
                    className="!bg-gray-200"
                    key={column.id}
                    style={{ background: "gray" }}  >
                    <Checkbox
                      color="primary"
                      className="!bg-gray-200"
                      indeterminate={selected.length > 0 && selected.length < disbursementDetail.length}
                      checked={disbursementDetail.length > 0 && selected.length === disbursementDetail.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )
              }
              return (
                <TableCell align="center"
                  className="!bg-gray-200"
                  key={column.id}
                  style={{ background: "gray" }}
                >
                  {column.label}
                </TableCell>
              )
            }}
            renderRow={(row: any) => {
              if (activeTab == "initiated") {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.BorrowerName}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Contact_Person_Name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {numberFormat(row.Loanamount)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Financer}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.Product}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {moment(row.dismonth).format("MMMM, YYYY")}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Connectorname}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {parseFloat(row.Grosspayout).toFixed(2)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Paymentstatus == "pending" && <span className="text-sm text-[#DD2025] font-medium">Payment Pending</span>}
                      {row.Paymentstatus == "initiated" && <span className="text-sm text-[#FF9800] font-medium">Payment initiated</span>}
                      {row.Paymentstatus == "completed" && <span className="text-sm text-[#37B551] font-medium">Payment completed</span>}
                    </TableCell>
                  </TableRow>
                )
              }
              if (activeTab == "l1l2") {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center" component="th" scope="row">
                      {row?.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.borrower_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.user.name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.month_of_disbursement ? moment(row.month_of_disbursement).format("MMMM-YYYY") : "-"}

                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.level_1?.user?.name ? row?.level_1?.user?.name : "-"}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.level_2?.user?.name ? row?.level_2?.user?.name : "-"}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.product?.product}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {numberFormat(row.loan_amount)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.l1_up_expense ? row?.l1_up_expense : "-"}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row?.l2_up_expense ? row?.l2_up_expense : "-"}
                    </TableCell>
                  </TableRow>
                )
              }
              if (activeTab == "mismatch") {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center" component="th" scope="row">
                      {row.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.bank_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.customer_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.lan_Number}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {numberFormat(row.loan_amount)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.month_and_year}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.payout_in_percetage}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.product}
                    </TableCell>
                  </TableRow>
                )
              }
            }}
          />
        </div>
        {/* </main> */}

        {/* Start::Add Disbursement Data Modal */}
        <div className={`tw-modal ${addOpen ? 'flex' : 'hidden'} !items-start`}>
          <div className="tw-modal-content">
            <div className="tw-modal-title">
              <div>Add Disbursement Data</div>
              <button onClick={() => setAddOpen(false)}>
                <img src="assets/close-outline.svg" alt="" />
              </button>
            </div>

            <div className='tw-modal-body'>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                <div className="space-y-1">
                  <label htmlFor="borrower_name" className={`input-label ${formData.borrower_name_error ? "text-red-500" : ""}`}>Name of Borrower</label>
                  <div id="borrower_name" className={`input-wrap ${formData.borrower_name_error ? "border-red-500" : ""}`}>
                    <img src="assets/user.svg" alt="" />
                    <input type="text" name="borrower_name" value={formData.borrower_name} onChange={(e: any) => {
                      setFormData({ ...formData, borrower_name: e.target.value, borrower_name_error: null });
                    }} className="w-full text-sm" placeholder="Enter Name of Borrower" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.borrower_name_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="lan_no" className={`input-label ${formData.lan_no_error ? "text-red-500" : ""}`}>LAN No.(Optional)</label>
                  <div id="lan_no" className={`input-wrap ${formData.lan_no_error ? "border-red-500" : ""}`}>
                    <img src="assets/firm.svg" alt="" />
                    <input type="text" name="lan_no" value={formData.lan_no} onChange={(e: any) => {
                      setFormData({ ...formData, lan_no: e.target.value, lan_no_error: null });
                    }} className="w-full text-sm" placeholder="Enter LAN No." />
                  </div>
                  <p className="text-red-500 text-sm">{formData.lan_no_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="contact_person_name" className={`input-label ${formData.contact_person_name_error ? "text-red-500" : ""}`}>Contact Person Name(Optional)</label>
                  <div id="contact_person_name" className={`input-wrap ${formData.contact_person_name_error ? "border-red-500" : ""}`}>
                    <img src="assets/firm.svg" alt="" />
                    <input type="text" name="contact_person_name" value={formData.contact_person_name} onChange={(e: any) => {
                      setFormData({ ...formData, contact_person_name: e.target.value, contact_person_name_error: null });
                    }} className="w-full text-sm" placeholder="Enter Contact Person Name" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.contact_person_name_error}</p>
                </div>
                {/* <div className="space-y-1">
                  <label htmlFor="lan_no" className={`input-label ${formData.pan_no_error ? "text-red-500" : ""}`}>Pan No.</label>
                  <div id="pan_no" className={`input-wrap ${formData.pan_no_error ? "border-red-500" : ""}`}>
                    <img src="assets/firm.svg" alt="" />
                    <input type="text" name="lan_no" value={formData.pan_no} onChange={(e: any) => {
                      setFormData({ ...formData, pan_no: e.target.value, pan_no_error: null });
                    }} className="w-full text-sm" placeholder="Enter LAN No." />
                  </div>
                  <p className="text-red-500 text-sm">{formData.pan_no_error}</p>
                </div> */}
                <div className="space-y-1">
                  <label htmlFor="loan_amount" className={`input-label ${formData.loan_amount_error ? "text-red-500" : ""}`}>Loan Amount</label>
                  <div id="loan_amount" className={`input-wrap ${formData.loan_amount_error ? "border-red-500" : ""}`}>
                    <img src="assets/email.svg" alt="" />
                    <input type="text" name="loan_amount" value={formData.loan_amount} onChange={(e: any) => {
                      setFormData({ ...formData, loan_amount: e.target.value, loan_amount_error: null });
                    }} className="w-full text-sm" placeholder="Enter Loan Amount" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.loan_amount_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="pincode" className={`input-label ${formData.pincode_error ? "text-red-500" : ""}`}>Borrower’s City Pincode</label>
                  <div id="pincode" className={`input-wrap ${formData.pincode_error ? "border-red-500" : ""}`}>
                    <img src="assets/location.svg" alt="" />
                    <input type="text" name="pincode"  maxLength={6} value={formData.pincode} onChange={(e: any) => { postget(e) }}  className="w-full text-sm" placeholder="Enter Borrower’s City Pincode" />
                  </div>
                  <p className="text-red-500 text-sm">{formData.pincode_error}</p>
                </div>
                
                <div className="space-y-1">
                  <label htmlFor="financer_name" className={`input-label ${formData.financer_name_error ? "text-red-500" : ""}`}>Financer Name</label>
                  <div id="financer_name" className={`input-wrap ${formData.financer_name_error ? "border-red-500" : ""}`}>
                    <img src="assets/bank.svg" alt="" />
                    <select name="financer_name" value={formData.financer_name} onChange={(e) => setFormData({ ...formData, financer_name: e.target.value, financer_name_error: null, product: "" })} className="w-full text-sm text-[#808080]">
                      <option value="" selected>Select Bank/NBFC Name</option>
                      {Object.keys(bankData)?.map((bank: any, id: any) => (
                        <option value={bank}>{bank}</option>
                      ))}

                    </select>
                  </div>
                  <p className="text-red-500 text-sm">{formData.financer_name_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="pincode" className={`input-label ${formData.city_error ? "text-red-500" : ""}`}>City</label>
                  <div id="pincode" className={`input-wrap ${formData.city_error ? "border-red-500" : ""}`}>
                    <img src="assets/location.svg" alt="" />
                    <input type="text" name="pincode" value={formData.city} onChange={(e: any) => {
                      setFormData({ ...formData, city: e.target.value, city_error: null });
                    }} className="w-full text-sm" placeholder="Enter City" disabled/>
                  </div>
                  <p className="text-red-500 text-sm">{formData.city_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="pincode" className={`input-label ${formData.state_error ? "text-red-500" : ""}`}>State</label>
                  <div id="pincode" className={`input-wrap ${formData.state_error ? "border-red-500" : ""}`}>
                    <img src="assets/location.svg" alt="" />
                    <input type="text" name="pincode" value={formData.state} onChange={(e: any) => {
                      setFormData({ ...formData, state: e.target.value, state_error: null });
                    }} className="w-full text-sm" placeholder="Enter State" disabled/>
                  </div>
                  <p className="text-red-500 text-sm">{formData.state_error}</p>
                </div>  
                <div className="space-y-1">
                  <label htmlFor="product" className={`input-label ${formData.product_error ? "text-red-500" : ""}`}>Product</label>
                  <div id="product" className={`input-wrap ${formData.product_error ? "border-red-500" : ""}`}>
                    <img src="assets/product.svg" alt="" />
                    <select name="product" value={formData.product} onChange={(e) => setFormData({ ...formData, product: e.target.value, product_error: null })} className="w-full text-sm text-[#808080]">
                      <option value="" selected>Select Product</option>
                      {bankData?.[formData.financer_name]?.map((item: any) => (
                        <option value={item.id}>{item.product}</option>
                      ))}
                    </select>
                  </div>
                  <p className="text-red-500 text-sm">{formData.product_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="month_of_disbursement" className={`input-label ${formData.month_of_disbursement_error ? "text-red-500" : ""}`}>Month of Disbursement</label>
                  <div id="month_of_disbursement" className={`input-wrap ${formData.month_of_disbursement_error ? "border-red-500" : ""}`}>
                    <img src="assets/date.svg" alt="" />
                    <DatePicker
                      selected={formData.month_of_disbursement}
                      onChange={(date: any) => setFormData({ ...formData, month_of_disbursement: date, month_of_disbursement_error: null })}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                  </div>
                  <p className="text-red-500 text-sm">{formData.month_of_disbursement_error}</p>
                </div>
                <div className="space-y-1">
                  <br className='hidden sm:block' />
                  <label className="input-label">Whether case sourced by Connector?</label>
                  <div className="flex space-x-4">
                    <div className='flex items-center space-x-1'>
                      <input type="radio" id="yesConnector" value="yes" onChange={() => setConnector(true)} name="yesConnector" />
                      <label htmlFor="yesConnector">Yes</label>
                    </div>
                    <div className='flex items-center space-x-1'>
                      <input type="radio" id="noConnector" value="no" name="yesConnector" onChange={() => {
                        setConnector(false);
                        setFormData({ ...formData, connector: "", connector_error: null })
                      }} />
                      <label htmlFor="noConnector">No</label>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="mobile" className={`input-label ${formData.mobile_error ? "text-red-500" : ""}`}>Mobile No.(Optional)</label>
                  <div id="mobile" className={`input-wrap ${formData.mobile_error ? "border-red-500" : ""}`}>
                    <img src="assets/phone.svg" alt="" />
                    <input type="text" name="mobile" value={formData.mobile} onChange={(e: any) => {
                      setFormData({ ...formData, mobile: e.target.value, mobile_error: null });
                    }} className="w-full text-sm" placeholder="Enter Mobile No." />
                  </div>
                  <p className="text-red-500 text-sm">{formData.mobile_error}</p>
                </div>
                <div className="space-y-1">
                  <label htmlFor="connector" className={`input-label ${formData.connector_error ? "text-red-500" : ""}`}>Connector</label>
                  <div id="connector" className={`input-wrap ${formData.connector_error ? "border-red-500" : ""}`}>
                    <img src="assets/connector.svg" alt="" />
                    <select name="connector" value={formData.connector} onChange={(e) => setFormData({ ...formData, connector: e.target.value, connector_error: null })} disabled={!isconnector} className="w-full text-sm text-[#808080]">
                      <option value="">Select</option>
                      {connectorList.map((y: any) => (
                        <option value={y.id}>{y.label}</option>
                      ))}
                    </select>
                  </div>
                  <p className="text-red-500 text-sm">{formData.connector_error}</p>
                </div>
              </div>
            </div>

            <div className='tw-modal-footer'>
              <button className='tw-modal-footer-button bg-secondary text-white' onClick={addDisbursement}>Submit</button>
              <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                setAddOpen(false)
                setFormData(disbursementData)
              }}>Cancel</button>
            </div>
          </div>
        </div>
        {/* End::Add Disbursement Data Modal */}
      </>
    }
  </div>)
}

export default DisbursementData