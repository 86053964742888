export const PERMISSION_TYPE = {
    ADD_LOGIN_CODE: "ADD_LOGIN_CODE",
    ADD_PAYOUT_GRID: "ADD_PAYOUT_GRID",
    CONNECTOR_EDIT: "CONNECTOR_EDIT",
    ADMIN: "ADMIN",
    USER: "USER",
    STAFF_OPS: "STAFF_OPS",
    STAFF: "STAFF",
    CONNECTOR: "CONNECTOR",
    SIDEBAR_DASHBOARD: "SIDEBAR_DASHBOARD",
    SIDEBAR_USER_DATABASE: "SIDEBAR_USER_DATABASE",
    SIDEBAR_STAFF_LIST: "SIDEBAR_STAFF_LIST",
    SIDEBAR_USER_REFFERED: "SIDEBAR_USER_REFFERED",
    SIDEBAR_KYC_STATUS: "SIDEBAR_KYC_STATUS",
    SIDEBAR_KYC_ADMIN: "SIDEBAR_KYC_ADMIN",
    SIDEBAR_LOGIN_CODE_LIST: "SIDEBAR_LOGIN_CODE_LIST",
    SIDEBAR_PAYOUT_GRID: "SIDEBAR_PAYOUT_GRID",
    SIDEBAR_SM_LIST: "SIDEBAR_SM_LIST",
    SIDEBAR_CONNECTOR_LIST: "SIDEBAR_CONNECTOR_LIST",
    SIDEBAR_DISBURSEMENT_DATA: "SIDEBAR_DISBURSEMENT_DATA",
    SIDEBAR_LOGIN_CREDENTIALS: "SIDEBAR_LOGIN_CREDENTIALS",
    SIDEBAR_INCOME_EXPENCE: "SIDEBAR_INCOME_EXPENCE",
    SIDEBAR_INCENTIVE: "SIDEBAR_INCENTIVE",
    ADVANCE_ACTION_UPLOAD: "ADVANCE_ACTION_UPLOAD",
    ADVANCE_ACTION_APPROVE: "ADVANCE_ACTION_APPROVE",
    ADVANCE_ACTION_REJECT: "ADVANCE_ACTION_REJECT",
    SIDEBAR_BASIC_DETAILS: "SIDEBAR_BASIC_DETAILS",
}

export const userPermission = (currentUser: any, type: string) => {
    const isAdmin = currentUser?.roles === 'admin';
    const isUser = currentUser?.roles === 'user';
    const isConnector = currentUser?.roles === 'connector';
    const isSalesStaff = currentUser?.roles === 'staff' && currentUser?.department === 'sales';
    const isOpsStaff = currentUser?.roles === 'staff' && currentUser?.department === 'ops';
    const isLocationStaff = currentUser?.roles === 'staff' && currentUser?.department === 'location';
    switch (type) {
        case PERMISSION_TYPE.ADMIN:
            return isAdmin
        case PERMISSION_TYPE.USER:
            return isUser
        case PERMISSION_TYPE.STAFF_OPS:
            return isOpsStaff
        case PERMISSION_TYPE.STAFF:
            return isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.CONNECTOR:
            return isConnector


        case PERMISSION_TYPE.ADD_LOGIN_CODE:
            return isAdmin
        case PERMISSION_TYPE.ADD_PAYOUT_GRID:
            return isAdmin
        case PERMISSION_TYPE.CONNECTOR_EDIT:
            return isAdmin || isUser || isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.ADVANCE_ACTION_UPLOAD:
            return isAdmin || isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.ADVANCE_ACTION_APPROVE:
            return isAdmin || isOpsStaff
        case PERMISSION_TYPE.ADVANCE_ACTION_REJECT:
            return isAdmin || isOpsStaff


        case PERMISSION_TYPE.SIDEBAR_DASHBOARD:
            return isAdmin || isSalesStaff || isLocationStaff || isUser || isOpsStaff || isConnector
        case PERMISSION_TYPE.SIDEBAR_USER_DATABASE:
            return isAdmin || isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_STAFF_LIST:
            return isAdmin
        case PERMISSION_TYPE.SIDEBAR_USER_REFFERED:
            return isAdmin || isUser || isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_KYC_STATUS:
            return isUser || isConnector
        case PERMISSION_TYPE.SIDEBAR_KYC_ADMIN:
            return isAdmin || isOpsStaff || isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_LOGIN_CODE_LIST:
            return isAdmin || isUser || isSalesStaff || isLocationStaff || isConnector || isOpsStaff
        case PERMISSION_TYPE.SIDEBAR_PAYOUT_GRID:
            return isAdmin || isUser || isSalesStaff || isLocationStaff || isOpsStaff || isConnector
        case PERMISSION_TYPE.SIDEBAR_SM_LIST:
            return isAdmin || isUser || isSalesStaff || isLocationStaff || isConnector || isOpsStaff
        case PERMISSION_TYPE.SIDEBAR_CONNECTOR_LIST:
            return isAdmin || isUser || isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_DISBURSEMENT_DATA:
            return isAdmin || isUser || isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_LOGIN_CREDENTIALS:
            return isAdmin || isSalesStaff || isLocationStaff || isOpsStaff
        case PERMISSION_TYPE.SIDEBAR_INCOME_EXPENCE:
            return isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_INCENTIVE:
            return isSalesStaff || isLocationStaff
        case PERMISSION_TYPE.SIDEBAR_BASIC_DETAILS:
            return isAdmin
    }
}