import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ClickAwayListener } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { Logout_Auth } from '../../redux/reducers/authReducer';
import STORAGEKEY from '../../config/APP/app.config';
import { PERMISSION_TYPE, userPermission } from './../../helper/permission';

function Header(props: any) {
    const { sidebarToggle, setSidebarToggle } = props;
    const userData = useSelector((state: RootState) => state?.authUser?.userData?.user)
    const [actionOpen, setActionOpen] = useState(false);
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const [permission, setPermission] = useState<any>({});

    useEffect(() => {
        const tempPermission = {
            [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
            [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
        }
        setPermission(tempPermission)
    }, [userData])

    const handleLogout = () => {
        localStorage.removeItem(STORAGEKEY.token)
        localStorage.removeItem(STORAGEKEY.userData)
        // dispatch(Logout_Auth({}))
        navigate("/login", { replace: true });
    }

    return <>
        {/* START::HEADER */}
        <header className={`transition-all fixed top-0 left-0 w-full bg-[#343E64] z-[999] ${sidebarToggle ? 'pl-0 lg:pl-[220px]' : 'lg:pl-0 pl-[220px]'}`}>
            <div className="py-4 px-9">

                {/* START::NAV SECTION */}
                <ul className="flex items-center justify-between space-x-6">

                    <li>
                        <button className="text-white h-8" onClick={() => setSidebarToggle(!sidebarToggle)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </li>

                    {/* START::SEARCH BOX */}
                    <li className="w-full bg-[#5C637E] py-2 px-5 space-x-[10px] text-sm hidden md:flex items-center rounded-md">
                        <img src="assets/search-white.svg" alt="" />
                        <input type="text" className="text-sm placeholder:text-white text-white w-full bg-transparent" placeholder="Search here..." />
                    </li>
                    {/* END::SEARCH BOX */}

                    {/* START::USER INFO */}
                    <li className="flex items-start space-x-3 text-sm">
                        <div className="w-8 h-8 bg-white p-[1px] rounded-full border border-secondary overflow-hidden">
                            <img src="assets/user.png" className='w-full' alt="" />
                        </div>
                        <ul className="text-white space-y-[2px] whitespace-nowrap">
                            <li className="text-sm font-semibold">{userData?.name}</li>
                            <li className="flex items-center space-x-[6px]">
                                <span className="opacity-70 text-xs uppercase text-[#B3B3B3]">{userData?.referral_codes}</span>
                                <CopyToClipboard text={userData?.referral_codes} onCopy={() => toast.success("Copy Successfully.", {
                                    position: "top-right",
                                    theme: "colored"
                                })}>
                                    <span className="w-3 cursor-pointer"><img src="assets/copy-gray.svg" alt="" /></span>
                                </CopyToClipboard>
                            </li>
                        </ul>
                    </li>
                    {/* END::USER INFO */}

                    {/* START::ALERT */}
                    <li>
                        <button onClick={(e) => {
                            e.preventDefault();
                            setActionOpen(!actionOpen)
                        }} className="w-8 h-8 bg-white rounded-full">
                            <img src="assets/ring-sky.svg" className="mx-auto" alt="" />
                        </button>

                        {actionOpen &&
                            <ClickAwayListener onClickAway={() => {
                                setActionOpen(false)
                            }}>
                                <ul className='absolute bg-white w-[200px] top-[50%] text-light-gray right-[50px] z-[11] border shadow-2xl'>
                                    {permission[PERMISSION_TYPE.ADMIN] &&
                                        <li className="cursor-pointer">
                                            <a onClick={() => {
                                                setActionOpen(false)
                                                navigate("/basicdetails", { replace: true })
                                            }} className='px-4 border-b py-[10px] text-sm flex items-center space-x-2'>
                                                <img src="assets/edit-pencil.svg" alt="" />
                                                <span>My Profile</span>
                                            </a>
                                        </li>
                                    }
                                    {permission[PERMISSION_TYPE.USER] &&
                                        <li className="cursor-pointer">
                                            <a onClick={() => {
                                                setActionOpen(false)
                                                navigate("/userdetails", { replace: true })
                                            }} className='px-4 border-b py-[10px] text-sm flex items-center space-x-2'>
                                                <img src="assets/edit-pencil.svg" alt="" />
                                                <span>My Profile</span>
                                            </a>
                                        </li>
                                    }
                                    <li className="cursor-pointer">
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            setActionOpen(false)
                                            handleLogout();
                                        }} className='px-4 border-b py-[10px] text-sm flex items-center space-x-2'>
                                            <img src="assets/plus-gray.svg" alt="" />
                                            <span>Logout</span>
                                        </a>
                                    </li>
                                </ul>
                            </ClickAwayListener>
                        }
                    </li>
                    {/* END::ALERT */}

                </ul>
                {/* END::NAV SECTION */}

            </div>
        </header>
        {/* END::HEADER */}
    </>;
}

export default Header;
