import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ApiGet } from '../../helper/API/ApiData';
import DatePicker from "react-datepicker";
import { numberFormat } from '../../helper/util';
import { useParams } from 'react-router';
import TableComponent from './../../components/Tables/index';
import { TableRow, TableCell } from '@mui/material';

function SingleIncentiveCalculation() {
    let { id } = useParams();
    const monthData = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [loader, setLoader] = useState(true);
    const [incentiveData, setIncentiveData] = useState<any>({});
    const [selectedYear, setSelectedYear] = useState<any>(new Date());
    const [incentiveList, setIncentiveList] = useState<any>({
        user: {},
        incentive: [],
        expence: []
    });

    const columns = [
        { id: 'Month', label: 'Month' },
        { id: 'Incentive(Current Month)', label: 'Incentive(Current Month)' },
        { id: 'Disbursement(Current Month)', label: 'Disbursement(Current Month)' },
        { id: 'Disbursement(YTD)', label: 'Disbursement(YTD)' },
    ];

    const getSlabValue = (value: number, data: any) => {
        if (value < 0) {
            return 0
        }
        if (value > 0 && value < 100000) {
            return _.find(data?.incentive_list ?? [], (x) => x.incentive_slab == "0-1")?.incentive_percentage ?? 0
        }
        if (value > 100000 && value < 200000) {
            return _.find(data?.incentive_list ?? [], (x) => x.incentive_slab == "1-2")?.incentive_percentage ?? 0
        }
        if (value > 200000 && value < 300000) {
            return _.find(data?.incentive_list ?? [], (x) => x.incentive_slab == "2-3")?.incentive_percentage ?? 0
        }
        if (value > 300000 && value < 400000) {
            return _.find(data?.incentive_list ?? [], (x) => x.incentive_slab == "3-4")?.incentive_percentage ?? 0
        }
        if (value > 400000 && value < 500000) {
            return _.find(data?.incentive_list ?? [], (x) => x.incentive_slab == "4-5")?.incentive_percentage ?? 0
        }
        if (value > 500000) {
            return _.find(data?.incentive_list ?? [], (x) => x.incentive_slab == "5+")?.incentive_percentage ?? 0
        }
    }

    const getYearData = (year: number, data: any = {}) => {
        const monthlySalary = (Number(data?.user?.ctc) / 12) ?? 0
        const monthlyRatioSalary = (monthlySalary * Number(data?.user?.salary_justification_ratio)) ?? 0;
        let finalData: any = {};
        for (let i = 0; i < 12; i++) {
            const monthlyExpence = _.find(data?.expence ?? [], (x) => x?._id?.year == year && x?._id?.month == i + 1)?.total_cost_month ?? 0
            const monthlyIncentive = _.find(data?.incentive ?? [], (x) => x?._id?.year == year && x?._id?.month == i + 1)?.total_cost_month ?? 0;
            let sortData = [monthlyIncentive, monthlyRatioSalary, monthlyExpence].sort(function (a, b) { return b - 1 });
            const sortIncentive = sortData.reduce((a, b) => b - a, 0)
            const sortValue = getSlabValue(sortIncentive, data?.user) ?? 0
            const monthlyCalculateIncentive = (sortIncentive * sortValue) / 100
            finalData = { ...finalData, [monthData[i]]: { ...finalData?.[monthData[i]], salary: monthlyCalculateIncentive, monthDisburment: 0, allDisburment: 0 } }
        }
        setIncentiveData(finalData)
    }

    const getIncentiveExpense = async (user: any, incentive: any) => {
        await ApiGet(`staff/incentive-expense/${id}`)
            .then((res: any) => {
                const data = {
                    user: user ?? {},
                    incentive: incentive ?? [],
                    expence: res?.data?.result ?? []
                }
                setIncentiveList(data)
                getYearData(2022, data)
            })
    }

    const getIncentive = async (data: any) => {
        await ApiGet(`staff/incentive/${id}`)
            .then((res: any) => {
                getIncentiveExpense(data, res.data.result)
            })
    }

    const getStaff = async () => {
        await ApiGet(`staff/${id}`)
            .then((res: any) => {
                getIncentive(res.data.staff ?? {})
            })
    }

    useEffect(() => {
        if (id) {
            Promise.all([
                getStaff()
            ]).then(() => {
                setTimeout(() => { setLoader(false) }, 250)
            }).catch((error) => {
                setLoader(false)
            })
        }
    }, [id])

    useEffect(() => {
        var dt = new Date(selectedYear);
        getYearData(dt.getFullYear(), incentiveList)
    }, [selectedYear])

    return (<div className="overflow-x-hidden">
        {loader ?
            <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
                <img src="/assets/logo.svg" />
            </div>
            :
            <div className="p-4 sm:p-9 space-y-5">
                {/* <div className="space-y-1 ">
                    <label htmlFor="selectedYear" className={`input-label `}>Select Year</label>
                    <div id="selectedYear" className={`input-wrap `}>
                        <img src="assets/date.svg" alt="" />
                        <DatePicker
                            selected={selectedYear}
                            onChange={(date: any) => setSelectedYear(date)}
                            showYearPicker
                            dateFormat="yyyy"
                        />
                    </div>
                </div> */}

                {/* START::DASHBOARD HEADER */}
                <div className="flex flex-wrap gap-5 items-center justify-between">
                    <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                        <h1 className="text-[22px] text-light-gray font-semibold" >Select Year</h1>
                    </div>
                </div>

                <div className="border-b border-gray-300 pb-5 z-[999]">
                    <div id="selectedYear" className="input-wrap max-w-[300px] w-full py-2">
                        <img className="w-5 h-5" src="/assets/date.svg" alt="" />
                        <DatePicker
                            selected={selectedYear}
                            onChange={(date: any) => setSelectedYear(date)}
                            showYearPicker
                            dateFormat="yyyy"
                            className="p-1 text-sm"
                        />
                    </div>
                </div>
                <TableComponent
                    rows={monthData}
                    columns={columns}
                    handlePageData={() => { }}
                    handlePage={() => { }}
                    state={{ page: 1, limit: 0, totalItems: 0 }}
                    renderColumn={(column: any) => {
                        return (
                            <TableCell align="center"
                                className="!bg-gray-200"
                                key={column.id}
                                style={{ background: "gray", zIndex: "0" }}
                            >
                                {column.label}
                            </TableCell>
                        )
                    }}
                    renderRow={(row: any) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                <TableCell align="center" component="th" scope="row">
                                    {row}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {numberFormat(incentiveData?.[row]?.salary)}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {incentiveData?.[row]?.monthDisburment}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {incentiveData?.[row]?.allDisburment}
                                </TableCell>
                            </TableRow>
                        )
                    }}
                />
            </div>
        }
    </div>)
}

export default SingleIncentiveCalculation;
