import { TableCell, TableRow, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { getImageUrl, isEmpty } from '../../helper/util';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { DatePicker } from '@mui/lab'
import DatePicker from "react-datepicker";
import { Stack, TextField } from '@mui/material'
import React from 'react'
// import Header from './components/header';
// import Sidebar from './components/sidebar'
import { useNavigate } from 'react-router';

function Staff() {
    const slabList = [
        { label: "0L to 1L", value: "0-1" },
        { label: "1L to 2L", value: "1-2" },
        { label: "2L to 3L", value: "2-3" },
        { label: "3L to 4L", value: "3-4" },
        { label: "4L to 5L", value: "4-5" },
        { label: "5L+", value: "5+" },
    ]
    const staffData = {
        department: "",
        name: "",
        email: "",
        address: "",
        mobile: "",
        pan_no: "",
        ctc: "",
        salary_justification_ratio: "",
        date_of_joining: new Date(),
        incentive_list: [],
        department_error: null,
        name_error: null,
        email_error: null,
        address_error: null,
        mobile_error: null,
        pan_no_error: null,
        ctc_error: null,
        date_of_joining_error: null,
        salary_justification_ratio_error: null,
    }
    const [activeTab, setActiveTab] = useState("sales");
    const [staffDetails, setStaffDetails] = useState([]);
    const [loader, setLoader] = useState(true);
    const [addOpen, setAddOpen] = useState(false)
    const [isEdit, setIsEdit] = useState("")
    const [formData, setFormData] = useState<any>(staffData);
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    let navigate = useNavigate();

    const columns = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'Name', label: 'Name' },
        { id: 'Department', label: 'Department' },
        { id: 'CTC', label: 'CTC' },
        { id: 'Action', label: 'Action' },
    ];

    const handlePageData = (limit: number, page: number = 1) => {
        setState({
            ...state,
            limit,
            page
        })
    }

    const handlePage = (page: number) => {
        setState({
            ...state,
            page
        })
    }

    const addStaffData = () => {
        let error = { ...formData };

        if (formData.department == "") {
            error = { ...error, department_error: "Please enter a department" };
        }
        if (formData.email == "") {
            error = { ...error, email_error: "Please enter a email" };
        }
        if (formData.name == "") {
            error = { ...error, name_error: "Please enter a name" };
        }
        if (formData.address == "") {
            error = { ...error, address_error: "Please enter a address" };
        }
        if (formData.mobile == "") {
            error = { ...error, mobile_error: "Please enter a mobile No." };
        }
        if (formData.pan_no == "") {
            error = { ...error, pan_no_error: "Please enter a pancard No." };
        }
        if (formData.ctc == "") {
            error = { ...error, ctc_error: "Please enter a ctc" };
        }
        if (formData.date_of_joining == null) {
            error = { ...error, date_of_joining_error: "Please enter a date of joining" };
        }
        setFormData(error)
        if (error.department_error == null && error.email_error == null && error.name_error == null && error.address_error == null && error.mobile_error == null && error.pan_no_error == null && error.ctc_error == null && error.date_of_joining_error == null) {
            const { department_error, email_error, address_error, mobile_error, pan_no_error, ctc_error, date_of_joining_error, name_error, ...payload } = formData
            let finalData = payload;


            isEdit ?
                ApiPut(`staff/${isEdit}`, finalData)
                    .then((res: any) => {
                        setFormData(staffData)
                        getStaffdata()
                        setAddOpen(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                :
                ApiPost("staff/register", finalData)
                    .then((res: any) => {
                        setFormData(staffData)
                        getStaffdata()
                        setAddOpen(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
        }
    }

    const getStaffdata = async () => {
        await ApiGet(`staff?page=${state.page}&limit=${state.limit}&department=${activeTab}`)
            .then((res: any) => {
                let temp = res.data.result.docs.map((item: any, index: number) => {
                    return {
                        id: item._id,
                        SRNO: (((state.page - 1) * state.limit) + index + 1),
                        Name: item.name,
                        Department: item.department,
                        CTC: item.ctc,
                    }
                })
                setStaffDetails(temp)
                setState({
                    ...state,
                    totalItems: res.data.result.total
                })
            }).catch((error) => {
                setStaffDetails([])
            })
    }
    const deleteStaff = async (_id: string) => {
        await ApiPut(`staff/remove/${_id}`, {})
            .then((res: any) => {
                getStaffdata()
            })
    }
    const editStaff = async (_id: string) => {
        setAddOpen(true)
        setIsEdit(_id)
        await ApiGet(`staff/${_id}`)
            .then((res: any) => {
                let data = {
                    department: res?.data?.staff.department,
                    name: res?.data?.staff.name,
                    email: res?.data?.staff.email,
                    address: res?.data?.staff.address,
                    mobile: res?.data?.staff.mobile,
                    pan_no: res?.data?.staff.pan_no,
                    ctc: res?.data?.staff.ctc,
                    date_of_joining: new Date(res?.data?.staff?.date_of_joining),
                    incentive_list: res?.data?.staff?.incentive_list,
                    salary_justification_ratio: res?.data?.staff?.salary_justification_ratio
                }
                setFormData(data)
            })
    }
    const handleAddDataPopup = async () => {
        setAddOpen(true)
        setIsEdit("")
        setFormData(staffData)
    }

    useEffect(() => {
        Promise.all([
            getStaffdata()
        ]).then(() => {
            setTimeout(() => { setLoader(false) }, 250)
        }).catch((error) => {
            setLoader(false)
        })
    }, [state.page, state.limit, activeTab])

    const rowClick = (data: any) => {
        navigate(`/staff/${data.id}`, { replace: true });
    }

    return (<div className="overflow-x-hidden">
        {/* <Sidebar />
    <Header /> */}
        {loader ?
            <>
                {/* START::OVERLAY */}
                <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
                    <img src="assets/logo.svg" />
                </div>
                {/* END::OVERLAY */}
            </>
            :
            <>
                {/* <main className="lg:pl-[280px] pt-[92px]"> */}
                <div className="p-4 sm:p-9 space-y-5">

                    {/* START::DASHBOARD HEADER */}
                    <div className="flex flex-wrap gap-5 items-center justify-between">
                        <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                            <h1 className="text-[22px] text-light-gray font-semibold white-nowrap">HR List</h1>
                        </div>

                        {/* START::EXPORT */}
                        <ul className="flex space-x-[18px]">
                            <li>
                                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold bg-primary text-white" onClick={() => handleAddDataPopup()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                    </svg>
                                    <span>Add Staff</span>
                                </button>
                            </li>
                        </ul>
                        {/* END::EXPORT */}
                    </div>
                    {/* END::DASHBOARD HEADER */}

                    {/* START::DASHBOARD HEADER */}
                    <div className="flex flex-wrap gap-5 items-center justify-between">
                        <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">

                            <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                                <li>
                                    <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "sales" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("sales")}>Sales Team</button>
                                </li>
                                <li>
                                    <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "ops" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("ops")}>Ops Team</button>
                                </li>
                                <li>
                                    <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "location" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("location")}>Location Head</button>
                                </li>
                            </ul>
                        </div>

                        {/* START::EXPORT */}
                        <ul className="flex space-x-[18px]">
                            <li>
                                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                                    <img src="assets/pdf.svg" alt="" />
                                    <span>PDF</span>
                                </button>
                            </li>
                            <li>
                                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                                    <img src="assets/excel.svg" alt="" />
                                    <span>EXCEL</span>
                                </button>
                            </li>
                        </ul>
                        {/* END::EXPORT */}
                    </div>
                    {/* END::DASHBOARD HEADER */}
                    <TableComponent
                        rows={staffDetails}
                        handlePageData={handlePageData}
                        handlePage={handlePage}
                        state={state}
                        columns={columns}
                        renderColumn={(column: any) => {
                            return (
                                <TableCell align="center"
                                    className="!bg-gray-200"
                                    key={column.id}
                                    style={{ background: "gray" }}
                                >
                                    {column.label}
                                </TableCell>
                            )
                        }}
                        renderRow={(row: any) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    rowClick(row)
                                }}>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.SRNO}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.Name}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.Department}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.CTC}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <ul className="flex space-x-2 justify-center">
                                            <li>
                                                <Tooltip title="Edit Data">
                                                    <img className='mx-auto' onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        editStaff(row.id)
                                                    }} src="assets/edit-connector.svg" alt="" />
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Tooltip title="Delete Data">
                                                    <img className='mx-auto' onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        deleteStaff(row.id)
                                                    }} src="assets/trash-gray.svg" alt="" />
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </TableCell>
                                </TableRow>
                            )
                        }}
                    />
                </div>

                {/* Start::Add staff Data Modal */}
                <div className={`tw-modal ${addOpen ? 'flex' : 'hidden'} !items-start`}>
                    <div className="tw-modal-content">
                        <div className="tw-modal-title">
                            <div>{isEdit ? "Edit Staff Data" : "Add Staff Data"}</div>
                            <button onClick={() => setAddOpen(false)}>
                                <img src="assets/close-outline.svg" alt="" />
                            </button>
                        </div>

                        <div className='tw-modal-body'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                <div className="space-y-1">
                                    <label htmlFor="product" className={`input-label ${formData.department_error ? "text-red-500" : ""}`}>Departement</label>
                                    <div id="product" className={`input-wrap ${formData.department_error ? "border-red-500" : ""}`}>
                                        <img src="assets/product.svg" alt="" />
                                        <select name="product" value={formData.department} onChange={(e) => setFormData({ ...formData, department: e.target.value, department_error: null })} className="w-full text-sm text-[#808080]">
                                            <option value="" selected>Select Product</option>
                                            <option value="sales" selected>Sales Team</option>
                                            <option value="ops" selected>Ops Team</option>
                                            <option value="location" selected>Location Head</option>
                                        </select>
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.department_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="borrower_name" className={`input-label ${formData.name_error ? "text-red-500" : ""}`}>Name</label>
                                    <div id="name" className={`input-wrap ${formData.name_error ? "border-red-500" : ""}`}>
                                        <img src="assets/user.svg" alt="" />
                                        <input type="text" name="name" value={formData.name} onChange={(e: any) => {
                                            setFormData({ ...formData, name: e.target.value, name_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Name" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.name_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="lan_no" className={`input-label ${formData.address_error ? "text-red-500" : ""}`}>Address</label>
                                    <div id="address" className={`input-wrap ${formData.address_error ? "border-red-500" : ""}`}>
                                        <img src="assets/firm.svg" alt="" />
                                        <input type="text" name="address" value={formData.address} onChange={(e: any) => {
                                            setFormData({ ...formData, address: e.target.value, address_error: null });
                                        }} className="w-full text-sm" placeholder="Enter Address" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.address_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="lan_no" className={`input-label ${formData.ctc_error ? "text-red-500" : ""}`}>CTC</label>
                                    <div id="ctc" className={`input-wrap ${formData.ctc_error ? "border-red-500" : ""}`}>
                                        <img src="assets/firm.svg" alt="" />
                                        <input type="number" name="ctc" value={formData.ctc} onChange={(e: any) => {
                                            setFormData({ ...formData, ctc: e.target.value, ctc_error: null });
                                        }} className="w-full text-sm" placeholder="Enter CTC" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.ctc_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="email" className={`input-label ${formData.email_error ? "text-red-500" : ""}`}>Email</label>
                                    <div id="email" className={`input-wrap ${formData.email_error ? "border-red-500" : ""}`}>
                                        <img src="assets/email.svg" alt="" />
                                        <input type="text" name="email" value={formData.email} onChange={(e: any) => {
                                            setFormData({ ...formData, email: e.target.value, email_error: null });
                                        }} className="w-full text-sm" placeholder="Enter email" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.email_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="mobile" className={`input-label ${formData.mobile_error ? "text-red-500" : ""}`}>Mobile No.</label>
                                    <div id="pincode" className={`input-wrap ${formData.mobile_error ? "border-red-500" : ""}`}>
                                        <img src="assets/location.svg" alt="" />
                                        <input type="text" name="pincode" value={formData.mobile} onChange={(e: any) => {
                                            setFormData({ ...formData, mobile: e.target.value, mobile_error: null });
                                        }} className="w-full text-sm" placeholder="Enter mobile No" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.mobile_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="pincode" className={`input-label ${formData.date_of_joining_error ? "text-red-500" : ""}`}>Date of Joining</label>
                                    <div id="date_of_joining" className={`input-wrap ${formData.date_of_joining_error ? "border-red-500" : ""}`}>
                                        <img src="assets/date.svg" alt="" />
                                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={4}>
                                                <DatePicker
                                                    label='Date Picker'
                                                    renderInput={(params: any) => <TextField {...params} />}
                                                    value={formData.date_of_joining}
                                                    onChange={(e:any) => 
                                                        setFormData({ ...formData, date_of_joining: e.target.value, date_of_joining_error: null })}
                                                />
                                            </Stack>
                                        </LocalizationProvider> */}
                                        <DatePicker
                                            selected={formData.date_of_joining}
                                            onChange={(date: any) => setFormData({ ...formData, date_of_joining: date, date_of_joining_error: null })}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            showFullMonthYearPicker
                                        />
                                        {/* <select name="date_of_joining" value={formData.date_of_joining} onChange={(e) => setFormData({ ...formData, date_of_joining: e.target.value, date_of_joining_error: null })} className="w-full text-sm text-[#808080]">
                                            <option value="" selected>Select Month</option>
                                            {monthData?.map((item: any) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </select> */}
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.date_of_joining_error}</p>
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="pincode" className={`input-label ${formData.pan_no_error ? "text-red-500" : ""}`}>Pan No.</label>
                                    <div id="pan_no" className={`input-wrap ${formData.pan_no_error ? "border-red-500" : ""}`}>
                                        <img src="assets/location.svg" alt="" />
                                        <input type="text" name="pan_no" value={formData.pan_no} onChange={(e: any) => {
                                            setFormData({ ...formData, pan_no: e.target.value, pan_no_error: null });
                                        }} className="w-full text-sm" placeholder="Enter pancard no" />
                                    </div>
                                    <p className="text-red-500 text-sm">{formData.pan_no_error}</p>
                                </div>
                            </div>
                            {(formData.department == "sales" || formData.department == "location") &&
                                <>
                                    <div className="space-y-1">
                                        <label htmlFor="salary_justification_ratio" className={`input-label ${formData.salary_justification_ratio_error ? "text-red-500" : ""}`}>Justification Ratio</label>
                                        <div id="salary_justification_ratio" className={`input-wrap ${formData.salary_justification_ratio_error ? "border-red-500" : ""}`}>
                                            <img src="assets/location.svg" alt="" />
                                            <input type="text" name="salary_justification_ratio" value={formData.salary_justification_ratio} onChange={(e: any) => {
                                                setFormData({ ...formData, salary_justification_ratio: e.target.value, salary_justification_ratio_error: null });
                                            }} className="w-full text-sm" placeholder="Enter Justification Ratio" />
                                        </div>
                                        <p className="text-red-500 text-sm">{formData.salary_justification_ratio_error}</p>
                                    </div>
                                    <div className='overflow-x-auto'>
                                        <table className='w-full text-xs'>
                                            <thead className='text-light-gray bg-gray-200'>
                                                <tr>
                                                    <th className='py-3 px-5 max-w-[100px]'>Incentive Slab</th>
                                                    <th className='py-3 px-5 max-w-[100px]'>Incentive %</th>
                                                </tr>
                                            </thead>
                                            <tbody className='bg-gray-100 text-light-gray'>
                                                {formData?.incentive_list.map((staff: any, index: any) => (
                                                    <tr className='border-b'>
                                                        <td className='py-3 px-5 max-w-[100px] text-center'>
                                                            <select name="incentive_slab" value={staff?.incentive_slab} onChange={(e: any) => {
                                                                let temp: any = [...formData.incentive_list]
                                                                temp[index] = { ...temp[index], incentive_slab: e.target.value };
                                                                setFormData({ ...formData, incentive_list: temp });
                                                            }} className="w-full py-2 px-[22px] border text-sm rounded-lg border-[#DEDEDE]">
                                                                <option value="" selected>Select Incentive Slab</option>
                                                                {slabList.map((list: any, index: number) => (
                                                                    <option key={index} value={list.value}>{list.label}</option>
                                                                ))}

                                                            </select>
                                                        </td>
                                                        <td className='py-3 px-5 max-w-[100px] text-center'>
                                                            <input
                                                                className='w-full py-2 px-[22px] border text-sm rounded-lg border-[#DEDEDE]'
                                                                type="text"
                                                                name="incentive_percentage"
                                                                placeholder='0-100'
                                                                value={staff?.incentive_percentage}
                                                                onChange={(e: any) => {
                                                                    let temp: any = [...formData.incentive_list]
                                                                    temp[index] = { ...temp[index], incentive_percentage: e.target.value };
                                                                    setFormData({ ...formData, incentive_list: temp });
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <button className="ml-auto flex items-center space-x-1 text-sm py-1 px-2 border border-primary rounded-md" onClick={() => {
                                        setFormData({ ...formData, incentive_list: [...formData.incentive_list, {}] });
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                        </svg>
                                        <span>Add New Row</span>
                                    </button>
                                </>
                            }

                        </div>

                        <div className='tw-modal-footer'>
                            <button className='tw-modal-footer-button bg-secondary text-white' onClick={addStaffData}>Save</button>
                            <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => setAddOpen(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
                {/* End::Add staff Data Modal */}
            </>
        }
    </div >)
}

export default Staff