import { TableCell, TableRow, Tooltip, Checkbox } from "@mui/material";
import { useState, useEffect } from "react";
import TableComponent from "../../components/Tables";
import { STATE } from "../../constants";
import { ApiGet, ApiPut } from "../../helper/API/ApiData";
import { useNavigate } from "react-router";

function UserDatabase() {
  const [userData, setUserData] = useState<any>([]);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [loader, setLoader] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [addOpenType, setAddOpenType] = useState("");
  const [staffData, setStaffData] = useState([]);

  const [selected, setSelected] = useState<readonly string[]>([]);
  const [userId, setUserid] = useState("");
  const [searchParms, setSearchParms] = useState({
    name: "",
    city: "",
    state: "",
  });
  const [formData, setFormData] = useState({
    relationship_manager: "",
  });
  let navigate = useNavigate();

  const getData = (searchData = false) => {
    let url = `userReffered/user?page=${state.page}&limit=${state.limit}`;
    if (searchData == true) {
      url = `userReffered/user?page=${state.page}&limit=${state.limit}&name=${searchParms.name}&city=${searchParms.city}&state=${searchParms.state}`;
    }
    ApiGet(url)
      .then((res: any) => {
        let finalData = res.data.result.docs?.map(
          (item: any, index: number) => {
            return {
              Userid: item._id,
              SRNO: (state.page - 1) * state.limit + index + 1,
              Name: item.name,
              Email: item.email,
              City: item.city,
              State: item.state,
              Contact: item.mobile,
              relationship_manager: item?.relationship_manager?.name ?? "-",
            };
          }
        );
        setUserData(finalData);
        setState({
          ...state,
          totalItems: res.data.result.total,
        });
      })
      .catch((error) => {
        setUserData([]);
      });
  };

  const search = () => {
    console.log(searchParms);
  };

  const getStaffData = () => {
    ApiGet(`userReffered/staff-list`)
      .then((res: any) => {
        let finalData = res.data.result.map((item: any, index: number) => {
          return {
            email: item?.email,
            id: item?._id,
          };
        });
        setStaffData(finalData);
      })
      .catch((error) => {
        setStaffData([]);
      });
  };

  const rowClick = (data: any) => {
    navigate(`/userdatabase/${data.Userid}`, { replace: true });
  };

  useEffect(() => {
    Promise.all([getData(), getStaffData()])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
    setSelected([]);
  }, [state.page, state.limit]);

  const handlePageData = (limit: number, page: number = 1) => {
    setState({
      ...state,
      limit,
      page,
    });
  };

  const handlePage = (page: number) => {
    setState({
      ...state,
      page,
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = userData?.map((row: any) => row.Userid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilert = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParms({ ...searchParms, [event.target.name]: event.target.value });
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const addRelation = async () => {
    if (addOpenType == "single") {
      await ApiPut(`userReffered/${userId}`, formData)
        .then((res: any) => {
          setAddOpen(false);
          getData();
          setFormData({
            relationship_manager: "",
          });
        })
        .catch((error) => {
          setStaffData([]);
        });
    } else {
      await ApiPut(`userReffered/add-relationship`, {
        ...formData,
        ids: selected,
      })
        .then((res: any) => {
          setAddOpen(false);
          setSelected([]);
          setFormData({
            relationship_manager: "",
          });
          getData();
        })
        .catch((error) => {
          setStaffData([]);
        });
    }
  };

  // const columns = ["SR.NO.", "Name", "City", "State", "Contact No.", "Total Billing", "TotalLoan Amount"];
  const columns = [
    { id: "", label: "" },
    { id: "SRNO", label: "SR.NO." },
    { id: "Name", label: "DSA Name" },
    // { id: 'Email', label: 'Email' },
    { id: "relationship_manager", label: "Relationship Manager" },
    { id: "City", label: "City" },
    { id: "State", label: "State" },
    { id: "Contact", label: "Contact" },
    { id: "Action", label: "Action" },
  ];

  return (
    <div className="overflow-x-hidden">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {/* <main className="lg:pl-[280px] pt-[92px]"> */}
          <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                <h1 className="text-[22px] text-light-gray font-semibold">
                  User Database
                </h1>
              </div>

              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                <input
                  type="text"
                  className="block w-full rounded-md text-sm text-[#808080] form-control
                  px-3
                  py-1.5
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300 
                  m-0 focus:bg-white focus:border-blue-500 focus:ring-2"
                  name="name"
                  placeholder="DSA Name"
                  onChange={handleFilert}
                />
                <input
                  type="text"
                  className="block w-full rounded-md text-sm text-[#808080] form-control
                  px-3
                  py-1.5
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300 
                  m-0 focus:bg-white focus:border-blue-500 focus:ring-2"
                  name="city"
                  placeholder="City"
                  onChange={handleFilert}
                />
                <input
                  type="text"
                  className="block w-full rounded-md text-sm text-[#808080] form-control
                  px-3
                  py-1.5
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300 
                  m-0 focus:bg-white focus:border-blue-500 focus:ring-2"
                  name="state"
                  placeholder="State"
                  onChange={handleFilert}
                />
                <div className="flex space-x-2 justify-center">
                  <div>
                    <button
                      type="button"
                      className="inline-block rounded-full hover:bg-gray-300 hover:ring-2 bg-blue-600 text-white leading-normal uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-500 active:shadow-lg transition duration-150 ease-in-out w-9 h-9"
                      onClick={() => getData(true)}
                    >
                      <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="-4 -2 30 30" width="25px" height="35px"><path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"/></svg>
                    </button>
                  </div>
                </div>
              </div>

              {/* START::EXPORT */}
              <ul className="flex space-x-[18px]">
                {selected?.length > 0 && (
                  <li>
                    <button
                      onClick={() => {
                        setAddOpenType("multi");
                        setAddOpen(true);
                      }}
                      className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold"
                    >
                      <img src="assets/pdf.svg" alt="" />
                      <span>Change Relationship</span>
                    </button>
                  </li>
                )}
                <li>
                  <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                    <img src="assets/pdf.svg" alt="" />
                    <span>PDF</span>
                  </button>
                </li>
                <li>
                  <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                    <img src="assets/excel.svg" alt="" />
                    <span>EXCEL</span>
                  </button>
                </li>
              </ul>
              {/* END::EXPORT */}
            </div>
            {/* END::DASHBOARD HEADER */}
            <TableComponent
              rows={userData ?? []}
              handlePageData={handlePageData}
              handlePage={handlePage}
              state={state}
              columns={columns}
              renderColumn={(column: any, index: number) => {
                if (index == 0) {
                  return (
                    <TableCell
                      padding="checkbox"
                      align="center"
                      className="!bg-gray-200"
                      key={column.id}
                      style={{ background: "gray" }}
                    >
                      <Checkbox
                        color="primary"
                        className="!bg-gray-200"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < userData.length
                        }
                        checked={
                          userData.length > 0 &&
                          selected.length === userData.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    align="center"
                    className="!bg-gray-200"
                    key={index}
                    style={{ background: "gray" }}
                  >
                    {column.label}
                  </TableCell>
                );
              }}
              renderRow={(row: any, index: number) => {
                const isItemSelected = isSelected(row.Userid);
                return (
                  <TableRow
                    hover
                    className="cursor-pointer"
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      rowClick(row);
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          handleClick(event, row.Userid);
                        }}
                        role="checkbox"
                        checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.SRNO}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.relationship_manager}
                    </TableCell>
                    {/* <TableCell align="center" component="th" scope="row">
                    {row.Email}
                  </TableCell> */}
                    <TableCell align="center" component="th" scope="row">
                      {row.City}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.State}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.Contact}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cursor-pointer"
                      component="th"
                      scope="row"
                    >
                      <ul className="flex space-x-2 justify-center">
                        <li>
                          <Tooltip title="Edit Data">
                            <img
                              className="mx-auto"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setUserid(row.Userid);
                                setAddOpenType("single");
                                setAddOpen(true);
                              }}
                              src="assets/edit-connector.svg"
                              alt=""
                            />
                          </Tooltip>
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                );
              }}
            />
          </div>

          <div
            className={`tw-modal ${addOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add RelationShip</div>
                <button onClick={() => setAddOpen(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  <label
                    htmlFor="relationship_manager"
                    className={`input-label `}
                  >
                    Relationship Manager
                  </label>
                  <div id="relationship_manager" className={`input-wrap `}>
                    <img src="assets/bank.svg" alt="" />
                    <select
                      name="relationship_manager"
                      value={formData.relationship_manager}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          relationship_manager: e.target.value,
                        })
                      }
                      className="w-full text-sm text-[#808080]"
                    >
                      <option value="" selected>
                        Select Staff
                      </option>
                      {staffData.map((staff: any, index: number) => (
                        <option key={index} value={staff.id}>
                          {staff.email}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={addRelation}
                >
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setUserid("");
                    setAddOpenType("");
                    setAddOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* </main> */}
    </div>
  );
}

export default UserDatabase;
