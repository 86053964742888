import { TableCell, TableRow } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet } from '../../helper/API/ApiData'
// import Header from './components/header';
// import Sidebar from './components/sidebar'

function SingleUserList() {
    const [activeTab, setActiveTab] = useState("l1_up");
    const [userData, setUserData] = useState([]);
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    let { id } = useParams();


    const getData = async (id: string, activeTab: string) => {
        await ApiGet(`userReffered/user-level?userId=${id}&&level_type=${activeTab}`)
            .then((res: any) => {
                let finalData = [];
                (activeTab == "l1_up" || activeTab == "l2_up") ?
                    finalData = res.data.result.docs.map((item: any, index: number) => {
                        return {

                            Userid: item.user._id,
                            SRNO: (((state.page - 1) * state.limit) + index + 1),
                            Name: item.user.name,
                            Email: item.user.email,
                            City: item.user.city,
                            State: item.user.state,
                            Contact: item.user.mobile,
                        }
                    })
                    :
                    finalData = res.data.result.docs.map((item: any, index: number) => {
                        return {
                            Userid: item.invite_user._id,
                            SRNO: (((state.page - 1) * state.limit) + index + 1),
                            Name: item.invite_user.name,
                            Email: item.invite_user.email,
                            City: item.invite_user.city,
                            State: item.invite_user.state,
                            Contact: item.invite_user.mobile,
                        }
                    })
                setUserData(finalData)

                setState({
                    ...state,
                    totalItems: res.data.result.total
                })

            }).catch((err) => {
                setUserData([])

                setState({
                    ...state,
                    totalItems: 0
                })
            })
    }

    useEffect(() => {
        if (id) {
            getData(id, activeTab);
        }
    }, [activeTab, state.page, state.limit, id]);

    const handlePageData = (limit: number, page: number = 1) => {
        setState({
            ...state,
            limit,
            page
        })
    }

    const handlePage = (page: number) => {
        setState({
            ...state,
            page
        })
    }

    // const columns = ["SR.NO.", "Name", "City", "State", "Contact No.", "Total Billing", "TotalLoan Amount"];
    const columns = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'Name', label: 'Name' },
        { id: 'Email', label: 'Email' },
        { id: 'City', label: 'City' },
        { id: 'State', label: 'State' },
        { id: 'Contact', label: 'Contact' },
    ];

    return (<div className="overflow-x-hidden">

        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
                <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                    <h1 className="text-[22px] text-light-gray font-semibold">User Database</h1>

                    <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                        <li>
                            <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "l1_up" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("l1_up")}>L1 UP</button>
                        </li>
                        <li>
                            <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "l2_up" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("l2_up")}>L2 UP</button>
                        </li>
                        <li>
                            <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "l1_down" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("l1_down")}>L1 DOWN</button>
                        </li>
                        <li>
                            <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "l2_down" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("l2_down")}>L2 DOWN</button>
                        </li>
                    </ul>
                </div>

                {/* START::EXPORT */}
                <ul className="flex space-x-[18px]">
                    <li>
                        <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                            <img src="assets/pdf.svg" alt="" />
                            <span>PDF</span>
                        </button>
                    </li>
                    <li>
                        <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                            <img src="assets/excel.svg" alt="" />
                            <span>EXCEL</span>
                        </button>
                    </li>
                </ul>
                {/* END::EXPORT */}
            </div>
            {/* END::DASHBOARD HEADER */}
            <TableComponent
                rows={userData ?? []}
                handlePageData={handlePageData}
                handlePage={handlePage}
                state={state}
                columns={columns}
                renderColumn={(column: any) => {
                    return (
                        <TableCell align="center"
                            className="!bg-gray-200"
                            key={column.id}
                            style={{ background: "gray" }}
                        >
                            {column.label}
                        </TableCell>

                    )
                }}
                renderRow={(row: any) => {
                    return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            <TableCell align="center" component="th" scope="row">
                                {row.SRNO}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                                {row.Name}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                                {row.Email}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                                {row.City}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                                {row.State}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                                {row.Contact}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                                {row.TotalBilling}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                                {row.TotalLoanAmount}
                            </TableCell>
                        </TableRow>
                    )
                }}
            />
        </div>
        {/* </main> */}
    </div>)
}

export default SingleUserList