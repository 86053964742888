import { TableCell, TableRow } from '@mui/material';
import exp from 'constants';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPost } from '../../helper/API/ApiData'
import { getImageUrl } from '../../helper/util';
import DatePicker from "react-datepicker";
import moment from 'moment';

function Miscellaneous() {
    let income_data = {
        income_attributed: "",
        income_type: "",
        amount_income: 0,
        income_attributed_error: null,
        income_type_error: null,
        amount_income_error: null,
    }
    let expence_data = {
        expense_type: "all",
        agreement: {
            first_party: "",
            second_party: "",
            type_of_agreement: "",
            stamp_duty_amount: "",
            upload_document: "",
            remark: "",
            date: new Date(),
            upload_document_name: "Please Upload Document",
            first_party_error: null,
            second_party_error: null,
            type_of_agreement_error: null,
            stamp_duty_amount_error: null,
            remark_error: null,
            upload_document_error: null,
        },
        petrol: {
            travel_from: "",
            travel_to: "",
            total_kms: "",
            upload_document: "",
            remark: "",
            date: new Date(),
            upload_document_name: "Please Upload Document",
            travel_from_error: null,
            travel_to_error: null,
            total_kms_error: null,
            remark_error: null,
            upload_document_error: null,
        },
        hospitality: {
            total_amount: "",
            upload_document: "",
            remark: "",
            date: new Date(),
            upload_document_name: "Please Upload Document",
            total_amount_error: null,
            remark_error: null,
            upload_document_error: null,
        },
        other: {
            type_of_expense: "",
            reason: "",
            expense_amount: "",
            upload_document: "",
            remark: "",
            date: new Date(),
            upload_document_name: "Please Upload Document",
            type_of_expense_error: null,
            reason_error: null,
            expense_amount_error: null,
            remark_error: null,
            upload_document_error: null,
        }
    }
    let expence_data_list: any = {
        "all": "All",
        "agreement": "Agreement",
        "petrol": "Petrol",
        "hospitality": "Hospitality",
        "other": "Other",
    }
    const [activeTab, setActiveTab] = useState("income");
    const [userData, setUserData] = useState([]);
    const [expenceList, setExpenceList] = useState<any>([]);
    const [expenceTableList, setExpenceTableList] = useState([]);
    const [incomePopup, setIncomePopup] = useState(false);
    const [expencePopup, setExpencePopup] = useState(false);
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    const [loader, setLoader] = useState(true);
    const [incomeData, setIncomeData] = useState<any>(income_data);
    const [expenceData, setExpenceData] = useState<any>(expence_data);

    const columns_income = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'Income_Attributed', label: 'Income Attributed' },
        { id: 'Income_Type', label: 'Income Type' },
        { id: 'Amount', label: 'Amount' },
    ];

    const columns_expence: any = {
        "all": [
            { id: 'type', label: 'Type' },
            { id: 'amount', label: 'Amount' },
        ],
        "agreement": [
            { id: 'SRNO', label: 'SR.NO.' },
            { id: 'first_party', label: 'First Party' },
            { id: 'second_party', label: 'Second Party' },
            { id: 'type_of_agreement', label: 'Type Of Agreement' },
            { id: 'total_amount', label: 'Stamp Duty Amount' },
            { id: 'remark', label: 'Remark' },
            { id: 'date', label: 'Date' },
            { id: 'upload_document', label: 'Document' },
        ],
        "petrol": [
            { id: 'SRNO', label: 'SR.NO.' },
            { id: 'travel_from', label: 'Travel From' },
            { id: 'travel_to', label: 'Travel To' },
            { id: 'total_kms', label: 'Total Kms' },
            { id: 'total_amount', label: 'Total Amount' },
            { id: 'remark', label: 'Remark' },
            { id: 'date', label: 'Date' },
            { id: 'upload_document', label: 'Document' },
        ],
        "hospitality": [
            { id: 'SRNO', label: 'SR.NO.' },
            { id: 'total_amount', label: 'Total Amount' },
            { id: 'remark', label: 'Remark' },
            { id: 'date', label: 'Date' },
            { id: 'upload_document', label: 'Document' },
        ],
        "other": [
            { id: 'SRNO', label: 'SR.NO.' },
            { id: 'type_of_expense', label: 'Type Of Expense' },
            { id: 'reason', label: 'Reason' },
            { id: 'total_amount', label: 'Expence Amount' },
            { id: 'remark', label: 'Remark' },
            { id: 'date', label: 'Date' },
            { id: 'upload_document', label: 'Document' },
        ],
    };

    const getIncomeData = (activeTab: string) => {
        ApiGet(`miscellaneous?type=${activeTab}&expense_type=${expenceData.expense_type}&page=${state.page}&limit=${state.limit}`)
            .then((res: any) => {
                let finalData = res.data.result.docs.map((item: any, index: number) => {
                    return {
                        SRNO: (((state.page - 1) * state.limit) + index + 1),
                        Income_Attributed: item.income_attributed,
                        Income_Type: item.income_type,
                        Amount: item.amount_income,
                    }
                })
                setUserData(finalData)
                setState({
                    ...state,
                    totalItems: res.data.result.total
                })
            })
            .catch((error) => {
                setUserData([])
            })
    }

    const getExpenceData = (activeTab: string, type: string) => {
        ApiGet(`miscellaneous?type=${activeTab}&expense_type=${type}&page=${state.page}&limit=${state.limit}`)
            .then((res: any) => {
                let finalData = res.data.result.docs.map((item: any, index: number) => {
                    return {
                        SRNO: (((state.page - 1) * state.limit) + index + 1),
                        ...item
                    }
                })
                setExpenceList(finalData)
                setExpenceTableList(columns_expence[type].map((item: any) => { return item?.id }))
                setState({
                    ...state,
                    totalItems: res.data.result.total
                })
            })
            .catch((error) => {
                setExpenceList([])
            })
    }

    const getAllExpenceData = () => {
        ApiGet(`miscellaneous/get-expense`)
            .then((res: any) => {
                let groupData = _.groupBy(res.data.result, function (e) { return e.expense_type })
                let finalData: any = Object.keys(expence_data_list).filter((exp) => exp != "all").map((item: any) => {
                    return {
                        type: item,
                        amount: groupData?.[item]?.reduce((total, data) => {
                            return total + parseInt(data?.total_amount);
                        }, 0) ?? 0
                    }
                })
                setExpenceList(finalData)
                setExpenceTableList(columns_expence[expenceData.expense_type].map((item: any) => { return item?.id }))
                setState({
                    ...state,
                    totalItems: 0
                })
            })
            .catch((error) => {
                setExpenceList([])
            })
    }

    useEffect(() => {
        if (activeTab == "expence" && expenceData.expense_type == "all") {
            Promise.all([
                getAllExpenceData()
            ]).then(() => {
                setTimeout(() => { setLoader(false) }, 250)
            }).catch((error) => {
                setLoader(false)
            })
        } else {
            Promise.all([
                getIncomeData(activeTab),
                getExpenceData(activeTab, expenceData.expense_type)
            ]).then(() => {
                setTimeout(() => { setLoader(false) }, 250)
            }).catch((error) => {
                setLoader(false)
            })
        }
    }, [activeTab, state.page, state.limit, expenceData.expense_type]);

    useEffect(() => {
        setState({
            ...state,
            page: 1
        })
    }, [activeTab]);

    const handlePageData = (limit: number, page: number = 1) => {
        setState({
            ...state,
            limit,
            page
        })
    }

    const handlePage = (page: number) => {
        setState({
            ...state,
            page
        })
    }

    const addIncome = async () => {
        let error = { ...incomeData };
        if (incomeData.income_attributed == "") {
            error = { ...error, income_attributed_error: "Please enter a income attributed" };
        }
        if (incomeData.income_type == "") {
            error = { ...error, income_type_error: "Please enter a income type" };
        }
        if (incomeData.amount_income < 1) {
            error = { ...error, amount_income_error: "Please enter a amount" };
        }
        setIncomeData(error)
        if (error.income_attributed_error == null && error.income_type_error == null && error.amount_income_error == null) {
            const { income_attributed_error, income_type_error, amount_income_error, ...payload } = incomeData
            await ApiPost("miscellaneous/add-income", payload)
                .then((res: any) => {
                    setIncomeData(income_data);
                    getIncomeData(activeTab)
                    setIncomePopup(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const addExpence = async (type: string) => {
        let error = { ...expenceData };
        let is_error = false;
        const form_data = new FormData();
        if (type == "agreement") {
            if (expenceData?.[type]?.first_party == "") {
                error = { ...error, [type]: { ...error?.[type], first_party_error: "Please enter a first party" } };
            }
            if (expenceData?.[type]?.second_party == "") {
                error = { ...error, [type]: { ...error?.[type], second_party_error: "Please enter a second party" } };
            }
            if (expenceData?.[type]?.type_of_agreement == "") {
                error = { ...error, [type]: { ...error?.[type], type_of_agreement_error: "Please enter a type of agreement" } };
            }
            if (expenceData?.[type]?.total_amount == "") {
                error = { ...error, [type]: { ...error?.[type], stamp_duty_amount_error: "Please enter a stamp sduty amount" } };
            }
            if (expenceData?.[type]?.remark == "") {
                error = { ...error, [type]: { ...error?.[type], remark_error: "Please enter a remark" } };
            }
            if (expenceData?.[type]?.upload_document_name == "Please Upload Document") {
                error = { ...error, [type]: { ...error?.[type], upload_document_error: "Please Upload Document" } };
            }
            form_data.append('expense_type', expenceData?.expense_type)
            form_data.append('first_party', expenceData?.[type]?.first_party)
            form_data.append('second_party', expenceData?.[type]?.second_party)
            form_data.append('type_of_agreement', expenceData?.[type]?.type_of_agreement)
            form_data.append('total_amount', expenceData?.[type]?.total_amount)
            form_data.append('remark', expenceData?.[type]?.remark)
            form_data.append('date', expenceData?.[type]?.date)
            form_data.append('upload_document', expenceData?.[type]?.upload_document)
            if (error?.[type]?.first_party_error == null && error?.[type]?.second_party_error == null && error?.[type]?.type_of_agreement_error == null && error?.[type]?.stamp_duty_amount_error == null && error?.[type]?.remark_error == null && error?.[type]?.upload_document_error == null) {
                is_error = true;
            }
        }
        if (type == "petrol") {
            if (expenceData?.[type]?.travel_from == "") {
                error = { ...error, [type]: { ...error?.[type], travel_from_error: "Please enter a travel from" } };
            }
            if (expenceData?.[type]?.travel_to == "") {
                error = { ...error, [type]: { ...error?.[type], travel_to_error: "Please enter a travel to" } };
            }
            if (expenceData?.[type]?.total_kms == "") {
                error = { ...error, [type]: { ...error?.[type], total_kms_error: "Please enter a totalkms" } };
            }
            if (expenceData?.[type]?.remark == "") {
                error = { ...error, [type]: { ...error?.[type], remark_error: "Please enter a remark" } };
            }
            if (expenceData?.[type]?.total_amount == "") {
                error = { ...error, [type]: { ...error?.[type], total_amount_error: "Please enter a total amount" } };
            }
            if (expenceData?.[type]?.upload_document_name == "Please Upload Document") {
                error = { ...error, [type]: { ...error?.[type], upload_document_error: "Please Upload Document" } };
            }
            form_data.append('expense_type', expenceData?.expense_type)
            form_data.append('travel_from', expenceData?.[type]?.travel_from)
            form_data.append('travel_to', expenceData?.[type]?.travel_to)
            form_data.append('total_kms', expenceData?.[type]?.total_kms)
            form_data.append('total_amount', expenceData?.[type]?.total_amount)
            form_data.append('date', expenceData?.[type]?.date)
            form_data.append('remark', expenceData?.[type]?.remark)
            form_data.append('upload_document', expenceData?.[type]?.upload_document)
            if (error?.[type]?.travel_from_error == null && error?.[type]?.travel_to_error == null && error?.[type]?.total_kms_error == null && error?.[type]?.remark_error == null && error?.[type]?.upload_document_error == null && error?.[type]?.total_amount_error == null) {
                is_error = true;
            }
        }
        if (type == "hospitality") {
            if (expenceData?.[type]?.total_amount == "") {
                error = { ...error, [type]: { ...error?.[type], total_amount_error: "Please enter a total amount" } };
            }
            if (expenceData?.[type]?.remark == "") {
                error = { ...error, [type]: { ...error?.[type], remark_error: "Please enter a remark" } };
            }
            if (expenceData?.[type]?.upload_document_name == "Please Upload Document") {
                error = { ...error, [type]: { ...error?.[type], upload_document_error: "Please Upload Document" } };
            }
            form_data.append('expense_type', expenceData?.expense_type)
            form_data.append('total_amount', expenceData?.[type]?.total_amount)
            form_data.append('date', expenceData?.[type]?.date)
            form_data.append('remark', expenceData?.[type]?.remark)
            form_data.append('upload_document', expenceData?.[type]?.upload_document)
            if (error?.[type]?.total_amount_error == null && error?.[type]?.remark_error == null && error?.[type]?.upload_document_error == null) {
                is_error = true;
            }
        }
        if (type == "other") {
            if (expenceData?.[type]?.type_of_expense == "") {
                error = { ...error, [type]: { ...error?.[type], type_of_expense_error: "Please enter a type of expense" } };
            }
            if (expenceData?.[type]?.reason == "") {
                error = { ...error, [type]: { ...error?.[type], reason_error: "Please enter a reason error" } };
            }
            if (expenceData?.[type]?.total_amount == "") {
                error = { ...error, [type]: { ...error?.[type], expense_amount_error: "Please enter a expense amount" } };
            }
            if (expenceData?.[type]?.remark == "") {
                error = { ...error, [type]: { ...error?.[type], remark_error: "Please enter a remark" } };
            }
            if (expenceData?.[type]?.upload_document_name == "Please Upload Document") {
                error = { ...error, [type]: { ...error?.[type], upload_document_error: "Please Upload Document" } };
            }
            form_data.append('expense_type', expenceData?.expense_type)
            form_data.append('type_of_expense', expenceData?.[type]?.type_of_expense)
            form_data.append('reason', expenceData?.[type]?.reason)
            form_data.append('total_amount', expenceData?.[type]?.total_amount)
            form_data.append('remark', expenceData?.[type]?.remark)
            form_data.append('date', expenceData?.[type]?.date)
            form_data.append('upload_document', expenceData?.[type]?.upload_document)
            if (error?.[type]?.type_of_expense_error == null && error?.[type]?.reason_error == null && error?.[type]?.expense_amount_error == null && error?.[type]?.remark_error == null && error?.[type]?.upload_document_error == null) {
                is_error = true;
            }
        }
        setExpenceData(error)
        if (is_error) {
            await ApiPost("miscellaneous/add-expense", form_data)
                .then((res: any) => {
                    let finalData = expence_data;
                    finalData = { ...finalData, expense_type: expenceData?.expense_type }
                    setExpenceData(finalData);
                    getExpenceData(activeTab, expenceData?.expense_type)
                    setExpencePopup(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const getRowData = (row: any, item: string) => {
        if (item == "upload_document") {
            return <img width="100px" height="100px" src={getImageUrl(row?.[item])} alt="" />
        }
        if (item == "type") {
            return expence_data_list[row?.[item]]
        }
        if (item == "date") {
            return moment(row?.[item]).format('MMMM, YYYY')
        }
        return row?.[item]
    }

    return (<div className="overflow-x-hidden">
        {/* <Sidebar />
    <Header /> */}

        {loader ?
            <>
                {/* START::OVERLAY */}
                <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
                    <img src="assets/logo.svg" />
                </div>
                {/* END::OVERLAY */}
            </>
            :
            <>

                {/* <main className="lg:pl-[280px] pt-[92px]"> */}
                <div className="p-4 sm:p-9 space-y-5">

                    {/* START::DASHBOARD HEADER */}
                    <div className="flex flex-wrap gap-5 items-center justify-between">
                        <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                            <h1 className="text-[22px] text-light-gray font-semibold">Miscellaneous</h1>

                            {/* 
                            if(tab active) 
                                Add class "bg-white rounded-[18px] text-primary"
                            else 
                                Remove class "bg-white rounded-[18px] text-primary"
                        */}
                            <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                                <li>
                                    <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "income" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("income")}>Income</button>
                                </li>
                                <li>
                                    <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "expence" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("expence")}>Expence</button>
                                </li>
                            </ul>
                        </div>

                        {/* START::EXPORT */}
                        <ul className="flex space-x-[18px]">
                            <li>
                                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                                    <img src="assets/pdf.svg" alt="" />
                                    <span>PDF</span>
                                </button>
                            </li>
                            <li>
                                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                                    <img src="assets/excel.svg" alt="" />
                                    <span>EXCEL</span>
                                </button>
                            </li>
                        </ul>
                        {/* END::EXPORT */}
                    </div>
                    {/* END::DASHBOARD HEADER */}

                    {
                        activeTab === "income" && (
                            <>
                                <div className="text-right">
                                    <button className="py-2 px-7 text-xs font-medium rounded-full bg-primary text-white" onClick={() => setIncomePopup(true)}>Add Income</button>
                                </div>
                                <TableComponent
                                    rows={userData ?? []}
                                    handlePageData={handlePageData}
                                    handlePage={handlePage}
                                    state={state}
                                    columns={columns_income}
                                    renderColumn={(column: any) => {
                                        return (
                                            <TableCell align="center"
                                                className="!bg-gray-200"
                                                key={column.id}
                                                style={{ background: "gray" }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        )
                                    }}
                                    renderRow={(row: any) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.SRNO}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.Income_Attributed}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.Income_Type}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.Amount}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }}
                                />
                                <div className={`tw-modal ${incomePopup ? 'flex' : 'hidden'} !items-start`}>
                                    <div className="tw-modal-content">
                                        <div className="tw-modal-title">
                                            <div>Add CodeList Data</div>
                                            <button onClick={() => setIncomePopup(false)}>
                                                <img src="assets/close-outline.svg" alt="" />
                                            </button>
                                        </div>

                                        <div className='tw-modal-body'>
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                                <div className="space-y-1">
                                                    <label htmlFor="income_attributed" className={`input-label ${incomeData.income_attributed_error ? "text-red-500" : ""}`}>Income Attributed</label>
                                                    <div id="income_attributed" className={`input-wrap ${incomeData.income_attributed_error ? "border-red-500" : ""}`}>
                                                        <img src="assets/product.svg" alt="" />
                                                        <select name="income_attributed" value={incomeData.income_attributed} onChange={(e) => setIncomeData({ ...incomeData, income_attributed: e.target.value, income_attributed_error: null })} className="w-full text-sm text-[#808080]">
                                                            <option value="" selected>Select Income Attributed</option>
                                                            <option value="User">User</option>
                                                            <option value="Borrower">Borrower</option>
                                                        </select>
                                                    </div>
                                                    <p className="text-red-500 text-sm">{incomeData.income_attributed_error}</p>
                                                </div>
                                                <div className="space-y-1">
                                                    <label htmlFor="income_type" className={`input-label ${incomeData.income_type_error ? "text-red-500" : ""}`}>Income Type</label>
                                                    <div id="income_type" className={`input-wrap ${incomeData.income_type_error ? "border-red-500" : ""}`}>
                                                        <img src="assets/product.svg" alt="" />
                                                        <select name="income_type" value={incomeData.income_type} onChange={(e) => setIncomeData({ ...incomeData, income_type: e.target.value, income_type_error: null })} className="w-full text-sm text-[#808080]">
                                                            <option value="" selected>Select Income Type</option>
                                                            <option value="Consultancy">Consultancy</option>
                                                            <option value="Field Work Charges">Field Work Charges</option>
                                                            <option value="CA Certificate">CA Certificate</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                    </div>
                                                    <p className="text-red-500 text-sm">{incomeData.income_type_error}</p>
                                                </div>
                                                <div className="space-y-1">
                                                    <label htmlFor="amount_income" className={`input-label ${incomeData.amount_income_error ? "text-red-500" : ""}`}>Amount</label>
                                                    <div id="amount_income" className={`input-wrap ${incomeData.amount_income_error ? "border-red-500" : ""}`}>
                                                        <img src="assets/user.svg" alt="" />
                                                        <input type="number" name="amount_income" value={incomeData.amount_income} onChange={(e: any) => {
                                                            setIncomeData({ ...incomeData, amount_income: e.target.value, amount_income_error: null });
                                                        }} className="w-full text-sm" placeholder="Enter Amount" />
                                                    </div>
                                                    <p className="text-red-500 text-sm">{incomeData.amount_income_error}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='tw-modal-footer'>
                                            <button className='tw-modal-footer-button bg-secondary text-white' onClick={addIncome}>Save</button>
                                            <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => setIncomePopup(false)}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        activeTab === "expence" && (
                            <>
                                <div className="space-y-1">
                                    <div id="expense_type" className={`input-wrap`}>
                                        <select name="expense_type"
                                            value={expenceData.expense_type}
                                            onChange={(e) => setExpenceData({ ...expenceData, expense_type: e.target.value })}
                                            className="w-full text-sm text-[#808080]">
                                            <option value="all" selected>All</option>
                                            <option value="agreement" selected>Agreement</option>
                                            <option value="petrol" selected>Petrol</option>
                                            <option value="hospitality" selected>Hospitality</option>
                                            <option value="other" selected>Other</option>
                                        </select>
                                    </div>
                                </div>
                                {expenceData.expense_type != "all" &&
                                    <div className="text-right">
                                        <button className="py-2 px-7 text-xs font-medium rounded-full bg-primary text-white" onClick={() => setExpencePopup(true)}>Add {expence_data_list[expenceData.expense_type]} Data</button>
                                    </div>
                                }
                                <div className={`tw-modal ${expencePopup ? 'flex' : 'hidden'} !items-start`}>
                                    <div className="tw-modal-content">
                                        <div className="tw-modal-title">
                                            <div>Add {expence_data_list[expenceData.expense_type]} Data</div>
                                            <button onClick={() => setExpencePopup(false)}>
                                                <img src="assets/close-outline.svg" alt="" />
                                            </button>
                                        </div>
                                        {expenceData.expense_type == "agreement" &&
                                            <div className='tw-modal-body'>
                                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                                    <div className="space-y-1">
                                                        <label htmlFor="first_party" className={`input-label ${expenceData?.[expenceData.expense_type]?.first_party_error ? "text-red-500" : ""}`}>First Party</label>
                                                        <div id="first_party" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.first_party_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="first_party" value={expenceData.first_party} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], first_party: e.target.value, first_party_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter first party" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.first_party_error}</p>

                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="second_party" className={`input-label ${expenceData?.[expenceData.expense_type]?.second_party_error ? "text-red-500" : ""}`}>Second Party</label>
                                                        <div id="second_party" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.second_party_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="second_party" value={expenceData.second_party} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], second_party: e.target.value, second_party_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter second party" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.second_party_error}</p>

                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="type_of_agreement" className={`input-label ${expenceData?.[expenceData.expense_type]?.type_of_agreement_error ? "text-red-500" : ""}`}>Type Of Agreement</label>
                                                        <div id="type_of_agreement" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.type_of_agreement_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="type_of_agreement" value={expenceData.type_of_agreement} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], type_of_agreement: e.target.value, type_of_agreement_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter type of agreement" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.type_of_agreement_error}</p>

                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="total_amount" className={`input-label ${expenceData?.[expenceData.expense_type]?.stamp_duty_amount_error ? "text-red-500" : ""}`}>Stamp Duty Amount</label>
                                                        <div id="total_amount" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.stamp_duty_amount_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="number" name="total_amount" value={expenceData.total_amount} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], total_amount: e.target.value, stamp_duty_amount_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter stamp duty amount" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.stamp_duty_amount_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="remark" className={`input-label ${expenceData?.[expenceData.expense_type]?.remark_error ? "text-red-500" : ""}`}>Remark</label>
                                                        <div id="remark" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.remark_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="remark" value={expenceData?.[expenceData.expense_type]?.remark} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], remark: e.target.value, remark_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Remark" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.remark_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="pincode" className={`input-label`}>Date</label>
                                                        <div id="date_of_joining" className={`input-wrap`}>
                                                            <img src="assets/date.svg" alt="" />
                                                            <DatePicker
                                                                selected={expenceData?.[expenceData.expense_type]?.date}
                                                                onChange={(date: any) => {
                                                                    setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], date: date } })
                                                                }}
                                                                dateFormat="MM/yyyy"
                                                                showMonthYearPicker
                                                                showFullMonthYearPicker
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="amount_income" className={`input-label ${expenceData?.[expenceData.expense_type]?.upload_document_error ? "text-red-500" : ""}`}>Upload Document</label>
                                                        <div id="amount_income" className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${expenceData?.[expenceData.expense_type]?.upload_document_error ? "border-red-500" : ""}`}>
                                                            <div className="w-full space-y-1">
                                                                <div className="input-wrap w-full">
                                                                    <label htmlFor="upload_document" className="block w-full text-sm text-[#808080]">{expenceData?.[expenceData.expense_type]?.upload_document_name}</label>
                                                                </div>
                                                                <input type="file" id="upload_document" onChange={(e: any) => {
                                                                    setExpenceData({
                                                                        ...expenceData, [expenceData.expense_type]: {
                                                                            ...expenceData?.[expenceData.expense_type],
                                                                            upload_document: e.target.files?.[0], upload_document_name: e.target.files?.[0]?.name, upload_document_error: null
                                                                        }
                                                                    })
                                                                }} className="text-sm hidden" />
                                                            </div>
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.upload_document_error}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {expenceData.expense_type == "petrol" &&
                                            <div className='tw-modal-body'>
                                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                                    <div className="space-y-1">
                                                        <label htmlFor="amount_income" className={`input-label ${expenceData?.[expenceData.expense_type]?.travel_from_error ? "text-red-500" : ""}`}>Travel From</label>
                                                        <div id="amount_income" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.travel_from_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="amount_income" value={expenceData?.[expenceData.expense_type]?.travel_from} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], travel_from: e.target.value, travel_from_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Travel From" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.travel_from_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="amount_income" className={`input-label ${expenceData?.[expenceData.expense_type]?.travel_to_error ? "text-red-500" : ""}`}>Travel To</label>
                                                        <div id="amount_income" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.travel_to_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="amount_income" value={expenceData?.[expenceData.expense_type]?.travel_to} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], travel_to: e.target.value, travel_to_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Travel To" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.travel_to_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="amount_income" className={`input-label ${expenceData?.[expenceData.expense_type]?.total_kms_error ? "text-red-500" : ""}`}>Total Kms</label>
                                                        <div id="amount_income" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.total_kms_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="amount_income" value={expenceData?.[expenceData.expense_type]?.total_kms} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], total_kms: e.target.value, total_kms_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Total Kms" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.total_kms_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="total_amount" className={`input-label ${expenceData?.[expenceData.expense_type]?.total_amount_error ? "text-red-500" : ""}`}>Total Amount</label>
                                                        <div id="total_amount" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.total_amount_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="total_amount" value={expenceData?.[expenceData.expense_type]?.total_amount} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], total_amount: e.target.value, total_amount_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Total Amount" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.total_amount_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="remark" className={`input-label ${expenceData?.[expenceData.expense_type]?.remark_error ? "text-red-500" : ""}`}>Remark</label>
                                                        <div id="remark" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.remark_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="remark" value={expenceData?.[expenceData.expense_type]?.remark} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], remark: e.target.value, remark_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Remark" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.remark_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="pincode" className={`input-label`}>Date</label>
                                                        <div id="date_of_joining" className={`input-wrap`}>
                                                            <img src="assets/date.svg" alt="" />
                                                            <DatePicker
                                                                selected={expenceData?.[expenceData.expense_type]?.date}
                                                                onChange={(date: any) => {
                                                                    setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], date: date } })
                                                                }}
                                                                dateFormat="MM/yyyy"
                                                                showMonthYearPicker
                                                                showFullMonthYearPicker
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="amount_income" className={`input-label ${expenceData?.[expenceData.expense_type]?.upload_document_error ? "text-red-500" : ""}`}>Upload Document</label>
                                                        <div id="amount_income" className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${expenceData?.[expenceData.expense_type]?.upload_document_error ? "border-red-500" : ""}`}>
                                                            <div className="w-full space-y-1">
                                                                <div className="input-wrap w-full">
                                                                    <label htmlFor="upload_document" className="block w-full text-sm text-[#808080]">{expenceData?.[expenceData.expense_type]?.upload_document_name}</label>
                                                                </div>
                                                                <input type="file" id="upload_document" onChange={(e: any) => {
                                                                    setExpenceData({
                                                                        ...expenceData, [expenceData.expense_type]: {
                                                                            ...expenceData?.[expenceData.expense_type],
                                                                            upload_document: e.target.files?.[0], upload_document_name: e.target.files?.[0]?.name, upload_document_error: null
                                                                        }
                                                                    })
                                                                }} className="text-sm hidden" />
                                                            </div>
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.upload_document_error}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {expenceData.expense_type == "hospitality" &&
                                            <div className='tw-modal-body'>
                                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                                    <div className="space-y-1">
                                                        <label htmlFor="total_amount" className={`input-label ${expenceData?.[expenceData.expense_type]?.total_amount_error ? "text-red-500" : ""}`}>Total Amount</label>
                                                        <div id="total_amount" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.total_amount_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="total_amount" value={expenceData?.[expenceData.expense_type]?.total_amount} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], total_amount: e.target.value, total_amount_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Amount" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.total_amount_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="remark" className={`input-label ${expenceData?.[expenceData.expense_type]?.remark_error ? "text-red-500" : ""}`}>Remark</label>
                                                        <div id="remark" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.remark_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="remark" value={expenceData?.[expenceData.expense_type]?.remark} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], remark: e.target.value, remark_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Remark" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.remark_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="pincode" className={`input-label`}>Date</label>
                                                        <div id="date_of_joining" className={`input-wrap`}>
                                                            <img src="assets/date.svg" alt="" />
                                                            <DatePicker
                                                                selected={expenceData?.[expenceData.expense_type]?.date}
                                                                onChange={(date: any) => {
                                                                    setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], date: date } })
                                                                }}
                                                                dateFormat="MM/yyyy"
                                                                showMonthYearPicker
                                                                showFullMonthYearPicker
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="amount_income" className={`input-label ${expenceData?.[expenceData.expense_type]?.upload_document_error ? "text-red-500" : ""}`}>Upload Document</label>
                                                        <div id="amount_income" className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${expenceData?.[expenceData.expense_type]?.upload_document_error ? "border-red-500" : ""}`}>
                                                            <div className="w-full space-y-1">
                                                                <div className="input-wrap w-full">
                                                                    <label htmlFor="upload_document" className="block w-full text-sm text-[#808080]">{expenceData?.[expenceData.expense_type]?.upload_document_name}</label>
                                                                </div>
                                                                <input type="file" id="upload_document" onChange={(e: any) => {
                                                                    setExpenceData({
                                                                        ...expenceData, [expenceData.expense_type]: {
                                                                            ...expenceData?.[expenceData.expense_type],
                                                                            upload_document: e.target.files?.[0], upload_document_name: e.target.files?.[0]?.name, upload_document_error: null
                                                                        }
                                                                    })
                                                                }} className="text-sm hidden" />
                                                            </div>
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.upload_document_error}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {expenceData.expense_type == "other" &&
                                            <div className='tw-modal-body'>
                                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                                    <div className="space-y-1">
                                                        <label htmlFor="type_of_expense" className={`input-label ${expenceData?.[expenceData.expense_type]?.type_of_expense_error ? "text-red-500" : ""}`}>Type Of Expense</label>
                                                        <div id="type_of_expense" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.type_of_expense_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="type_of_expense" value={expenceData?.[expenceData.expense_type]?.type_of_expense} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], type_of_expense: e.target.value, type_of_expense_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Expense" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.type_of_expense_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="reason" className={`input-label ${expenceData?.[expenceData.expense_type]?.reason_error ? "text-red-500" : ""}`}>Reason</label>
                                                        <div id="reason" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.reason_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="reason" value={expenceData?.[expenceData.expense_type]?.reason} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], reason: e.target.value, reason_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter reason" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.reason_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="total_amount" className={`input-label ${expenceData?.[expenceData.expense_type]?.expense_amount_error ? "text-red-500" : ""}`}>Expense Amount</label>
                                                        <div id="total_amount" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.expense_amount_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="total_amount" value={expenceData?.[expenceData.expense_type]?.total_amount} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], total_amount: e.target.value, expense_amount_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter amount" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.expense_amount_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="remark" className={`input-label ${expenceData?.[expenceData.expense_type]?.remark_error ? "text-red-500" : ""}`}>Remark</label>
                                                        <div id="remark" className={`input-wrap ${expenceData?.[expenceData.expense_type]?.remark_error ? "border-red-500" : ""}`}>
                                                            <img src="assets/user.svg" alt="" />
                                                            <input type="text" name="remark" value={expenceData?.[expenceData.expense_type]?.remark} onChange={(e: any) => {
                                                                setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], remark: e.target.value, remark_error: null } })
                                                            }} className="w-full text-sm" placeholder="Enter Remark" />
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.remark_error}</p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="pincode" className={`input-label`}>Date</label>
                                                        <div id="date_of_joining" className={`input-wrap`}>
                                                            <img src="assets/date.svg" alt="" />
                                                            <DatePicker
                                                                selected={expenceData?.[expenceData.expense_type]?.date}
                                                                onChange={(date: any) => {
                                                                    setExpenceData({ ...expenceData, [expenceData.expense_type]: { ...expenceData?.[expenceData.expense_type], date: date } })
                                                                }}
                                                                dateFormat="MM/yyyy"
                                                                showMonthYearPicker
                                                                showFullMonthYearPicker
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label htmlFor="amount_income" className={`input-label ${expenceData?.[expenceData.expense_type]?.upload_document_error ? "text-red-500" : ""}`}>Upload Document</label>
                                                        <div id="amount_income" className={`md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0 ${expenceData?.[expenceData.expense_type]?.upload_document_error ? "border-red-500" : ""}`}>
                                                            <div className="w-full space-y-1">
                                                                <div className="input-wrap w-full">
                                                                    <label htmlFor="upload_document" className="block w-full text-sm text-[#808080]">{expenceData?.[expenceData.expense_type]?.upload_document_name}</label>
                                                                </div>
                                                                <input type="file" id="upload_document" onChange={(e: any) => {
                                                                    setExpenceData({
                                                                        ...expenceData, [expenceData.expense_type]: {
                                                                            ...expenceData?.[expenceData.expense_type],
                                                                            upload_document: e.target.files?.[0], upload_document_name: e.target.files?.[0]?.name, upload_document_error: null
                                                                        }
                                                                    })
                                                                }} className="text-sm hidden" />
                                                            </div>
                                                        </div>
                                                        <p className="text-red-500 text-sm">{expenceData?.[expenceData.expense_type]?.upload_document_error}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className='tw-modal-footer'>
                                            <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => addExpence(expenceData.expense_type)}>Save</button>
                                            <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                                                setExpenceData({ ...expence_data, expense_type: expenceData?.expense_type })
                                                setExpencePopup(false)
                                            }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                <TableComponent
                                    rows={expenceList}
                                    columns={columns_expence[expenceData.expense_type]}
                                    handlePageData={handlePageData}
                                    handlePage={handlePage}
                                    state={state}
                                    renderColumn={(column: any) => {
                                        return (
                                            <TableCell align="center"
                                                className="!bg-gray-200"
                                                key={column.id}
                                                style={{ background: "gray" }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        )
                                    }}
                                    renderRow={(row: any) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {expenceTableList?.map((item: any) => (
                                                    <>
                                                        <TableCell align="center" component="th" scope="row">
                                                            {getRowData(row, item)}
                                                        </TableCell>
                                                    </>
                                                ))}
                                            </TableRow>
                                        )
                                    }}
                                />
                            </>
                        )
                    }
                </div>
            </>
        }
        {/* </main> */}
    </div>
    )
}

export default Miscellaneous