import { TableCell, TableRow, Tooltip, ClickAwayListener } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { userPermission } from '../../helper/permission';
import { numberFormat } from '../../helper/util';
import { RootState } from '../../redux/store';
import { PERMISSION_TYPE } from './../../helper/permission';

function Dashboard() {
    let documentData = {
        proof_document: "",
        proof_document_image: "",
        proof_document_link: "Choose Image",
        remark: "",
        adjustment_amount: "",
    }

    const [activeTab, setActiveTab] = useState("pending");
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    const [selectedFile, setSelectedFile] = useState<File | string>();
    const [loader, setLoader] = useState(true);
    const [dashboardData, setDashboardData] = useState([]);

    const [dashboard, setDashboard] = useState([]);

    const [permission, setPermission] = useState<any>({});
    const [open, setOpen] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [item, setItem] = useState<any>({
        gross_payout: 0,
        product: ""
    });


    const [viewData, setViewData] = useState<any>({
        remark: "",
        upload_proof_document: ""
    });

    const [selectedId, setSelectedId] = useState<string>("");
    const [documentPopup, setDocumentPopup] = useState<boolean>(false);
    const [documentPreview, setDocumentPreview] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>(documentData);
    const [dashboardFormData, setDashboardFormData] = useState<any>({});
    const [termsOpen, setTermsOpen] = useState<boolean[]>([]);
    const [bankData, setBankData] = useState<any>({});
    const [countData, setCountData] = useState<any>({});
    const [addOpen, setAddOpen] = useState<boolean>(false);

    const user = useSelector((state: RootState) => state?.authUser?.userData?.user)

    const columns = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'Borrower_Name', label: 'Borrower Name' },
        { id: 'Loan_Account_Number', label: 'Loan Account Number' },
        { id: 'Bank_Name', label: 'Bank/NBFC Name' },
        { id: 'Product', label: 'Product' },
        { id: 'Disburesement_Month', label: 'Disburesement Month' },
        { id: 'Loan_Amount', label: 'Loan Amount' },
        { id: 'Location', label: 'Location' },
        { id: 'Contact_Person', label: 'Contact Person Name' },
        { id: 'Mobile', label: 'Mobile Number' },
        { id: 'DSA', label: 'DSA Name' },
        { id: 'Payout_dsa', label: 'Payout in %(DSA)' },
        { id: 'Gross_Payout', label: 'Gross Payout in Amount(DSA)' },
        { id: 'Adjustment', label: 'Adjustment +/-' },
        { id: 'Less_TDS', label: 'Less:TDS @5%(DSA)' },
        { id: 'Add_GST', label: 'Add:GST @18%(DSA)' },
        { id: 'Net_Amount_Payable', label: 'Net Amount Payable (DSA)' },
        { id: 'Connector_name', label: 'Connector Name' },
        { id: 'Payout_Connectore', label: 'Payout in % (Connector)' },
        { id: 'Gross_Payout_Amount', label: 'Gross Payout in Amount (Connector)' },
        { id: 'Less_TDS_Connector', label: 'Less:TDS @5%(Connector)' },
        { id: 'Add_GST_Connector', label: 'Add:GST @18%(Connector)' },
        { id: 'Net_Amount_Payable_Connector', label: 'Net Amount Payable (Connector)' },
        { id: 'Payment_Status', label: 'Payment Status' },
        { id: 'Billing_Status', label: 'Billing Status' },
        { id: 'Payment_Date', label: 'Payment Date' },
        { id: 'Disburtial_Conformation', label: 'Disburtial Conformation' },
        { id: 'Loan_Amount_Bank', label: 'Loan Amount(Bank)' },
        { id: 'Payment_Bank', label: 'Payout in %(Bank)' },
        { id: 'Gross_Payout_Bank', label: 'Gross Payout in Amount(Bank)' },
        { id: 'Less_TDS_Bank', label: 'Less:TDS @5%(Bank)' },
        { id: 'Net_Amount_Receivable', label: 'Net Amount Receivable (Bank)' },
        { id: 'Gross_Margin_Booked', label: 'Gross Margin Booked (Cash Profit)' },
        { id: 'Mismatch', label: 'Mismatch' },
        { id: 'L1_Up', label: 'L1 Up Expenses' },
        { id: 'L2_Up', label: 'L2 Up Expenses' },
        { id: 'Net_Margin', label: 'Net Margin after L1 L2(Net Profit)' },
        { id: 'action', label: 'Action' },
    ];

    const columnuser = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'Case_Name', label: 'Case Name' },
        { id: 'Loan_Amount', label: 'Loan Amount' },
        { id: 'Banker', label: 'Banker' },
        { id: 'Product', label: 'Product' },
        { id: 'Gross_Payout', label: 'Gross Payout' },
        { id: 'Action', label: 'Action' },
    ]
    const columnuser2 = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'Case_Name', label: 'Case Name' },
        { id: 'Billing_Status', label: 'Billing Status' },
        { id: 'Loan_Amount', label: 'Loan Amount' },
        { id: 'Banker', label: 'Banker' },
        { id: 'Product', label: 'Product' },
        { id: 'Gross_Payout', label: 'Gross Payout' },
    ]

    const columnusers = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'Case_Name', label: 'Case Name' },
        { id: 'Loan_Amount', label: 'Loan Amount' },
        { id: 'Banker', label: 'Banker' },
        { id: 'Product', label: 'Product' },
        { id: 'Gross_Payout', label: 'Gross Payout' },
        { id: 'UTR_NO', label: 'UTR NO' },
    ]


    const handlePageData = (limit: number, page: number = 1) => {
        setState({
            ...state,
            limit,
            page
        })
    }

    const handlePage = (page: number) => {
        setState({
            ...state,
            page
        })
    }

    const getLoginCodeList = async () => {
        await ApiGet(`logincodelist`)
            .then((res: any) => {
                let temp = res.data.result.map((item: any, index: number) => {
                    return {
                        bank: item.bank_name,
                        product: item.product,
                        id: item._id
                    }
                })
                const bankData = _.groupBy(temp, function (b) { return b.bank })
                setBankData(bankData)
            }).catch((error) => {
                setBankData({})
            })
    }

    const getCountData = async () => {
        await ApiGet(`dashboard/statistics`)
            .then((res: any) => {
                setCountData(res?.data?.result ?? {})
            }).catch((error) => {
                setCountData({})
            })
    }

    const getDashboardData = async () => {
        await ApiGet(`dashboard?page=${state.page}&limit=${state.limit}&status=${activeTab}`)
            .then((res: any) => {
                let temp = res.data.result.docs.map((item: any, index: number) => {
                    return {
                        id: item?._id,
                        SRNO: (((state.page - 1) * state.limit) + index + 1),
                        Borrower_Name: item?.borrower_name ?? "-",
                        Loan_Account_Number: item?.lan_no ?? "-",
                        Bank_Name: item?.product?.bank_name ?? "-",
                        FinancerName: item?.financer_name ?? "-",
                        Product: item?.product?.product ?? "-",
                        ProductId: item?.product?._id ?? "",
                        Disburesement_Month: item?.month_of_disbursement ? moment(item?.month_of_disbursement).format("MMMM-YYYY") : "-",
                        Loan_Amount: item?.loan_amount ?? "-",
                        Location: item?.pincode ?? "-",
                        Contact_Person: item?.contact_person_name ?? "-",
                        Mobile: item?.mobile ?? "-",
                        DSA: item?.user?.name ?? "-",
                        Payout_dsa: item?.product?.normal_payout_slab ?? "-",
                        Gross_Payout: item?.gross_payout ?? "-",

                        Less_TDS: item?.tds_amount ?? "-",
                        Add_GST: item?.gst_amount ?? "-",
                        Adjustment: item?.adjustment_amount ?? "-",
                        Net_Amount_Payable: item?.net_amount_payable ?? "-",

                        //remaining start
                        Connector_name: item?.connector_name ?? "-",
                        Payout_Connectore: item?.connector_payout ? item?.connector_payout : 0,
                        Gross_Payout_Amount: item?.connectot_gross_payout ? item?.connectot_gross_payout : 0,
                        Less_TDS_Connector: item?.connector_tds ? item?.connector_tds : 0,
                        Add_GST_Connector: item?.connector_gst ? item?.connector_gst : 0,
                        Net_Amount_Payable_Connector: item?.connector_net_amount_payable ? item?.connector_net_amount_payable : 0,
                        // end

                        Payment_Status: item?.payment_status ?? "-",
                        Billing_Status: item?.billing_status ?? "-",
                        Payment_Date: item?.payment_date ? moment(item?.payment_date).format("MMMM-YYYY") : "-",
                        Disburtial_Conformation: item?.disburtial_conformation ?? "-",
                        Loan_Amount_Bank: item?.csv_loan_amount ?? "-",
                        Payment_Bank: item?.csv_payout ?? "-",
                        Gross_Payout_Bank: item?.csv_gross_payout ?? "-",

                        Less_TDS_Bank: item?.csv_tds_amount ?? "-",
                        Net_Amount_Receivable: item?.csv_net_amount ?? "-",
                        Gross_Margin_Booked: item?.gross_margin_booked ?? "-",
                        Mismatch: item?.is_mismatched === true ? item?.is_mismatched ? "true" : "false" : "-",
                        L1_Up: item?.l1_up_expense ?? "-",
                        L2_Up: item?.l2_up_expense ?? "-",
                        Net_Margin: item?.balance_amount ?? "-",
                        is_visible_icon: item?.is_visible_icon
                    }
                })

                let temps = res.data.result.docs.map((item: any, index: number) => {
                    return {
                        id: item._id,
                        SRNO: (((state.page - 1) * state.limit) + index + 1),
                        Case_Name: item?.borrower_name ?? "-",
                        Loan_Amount: item?.loan_amount ?? "-",
                        Bank_Name: item?.product?.bank_name ?? "-",
                        Product: item?.product?.product ?? "-",
                        Gross_Payout: item?.gross_payout ?? "-",
                        is_visible_icon: item?.is_visible_icon,
                        Utr_no: item?.UTR_NO ? item?.UTR_NO : "-"
                    }
                })
                setDashboardData(temp)
                setDashboard(temps)
                setTermsOpen(temp.map(() => { return false }))

                const tempPermission = {
                    [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
                }
                setPermission(tempPermission)

                setState({
                    ...state,
                    totalItems: res.data.result.total
                })
                // setDashboardData()
            }).catch((error) => {
                setDashboardData([])
                const tempPermission = {
                    [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
                }
                setPermission(tempPermission)
            })
    }

    const getTeamsAndCondition = (data: any) => {
        let temp: any = [];
        temp = [...temp, ...dashboardData];
        temp = [...temp, ...data];
        return temp.filter((value: any) => value != "")
    }

    const getReSubmission = async () => {
        await ApiGet("disbursement/re-submission")
            .then((res: any) => {
                let temps = res.data.result.docs.map((item: any, index: number) => {
                    return {
                        id: item._id,
                        SRNO: (((state.page - 1) * state.limit) + index + 1),
                        Case_Name: item?.borrower_name ?? "-",
                        Billing_Status: item?.billing_status ?? "-",
                        Loan_Amount: item?.loan_amount ?? "-",
                        Bank_Name: item?.product?.bank_name ?? "-",
                        Product: item?.product?.product ?? "-",
                        Gross_Payout: item?.gross_payout ?? "-",
                    }
                })
                setDashboard(temps)
            })
    }

    useEffect(() => {
        Promise.all([
            getCountData(),
            getDashboardData(),
            getReSubmission(),
            getLoginCodeList()
        ]).then(() => {
            setTimeout(() => { setLoader(false) }, 250)
        }).catch((error) => {
            setLoader(false)
        })
    }, [state.page, state.limit, activeTab]);

    const ltineraryRegBtn = async () => {
        let formData = new FormData()

        if (selectedFile) {
            formData.append("filedata", selectedFile);
        }

        await ApiPost(`disbursement/add-csv`, formData)
            .then((res: any) => {
                if (res?.data?.message == "upload successfully") {
                    toast.success("upload Successfully.", {
                        position: "top-right",
                        theme: "colored"
                    })
                    getDashboardData()
                }
            }).catch((error: any) => {
                toast.error(error?.error, {
                    position: "top-right",
                    theme: "colored"
                })
            })
        setSelectedFile("");
    }

    useEffect(() => {
        if (selectedFile) {
            ltineraryRegBtn()
        }
    }, [selectedFile])

    const actionYes = async () => {
        await ApiPut(`disbursement/${selectedId}`, {
            is_requested: true,
            is_visible_icon: true,
            cheker_type: "maker"
        }).then((res: any) => {
            setSelectedId("")
            getDashboardData()
            setOpen(false)
        }).catch((error: any) => {
            toast.error("Something went wrong!", {
                position: "top-right",
                theme: "colored"
            })
        })
    }

    const actionAdminYes = async () => {
        const form_data = new FormData();
        form_data.append('proof_document', formData.proof_document)
        form_data.append('remark', formData.remark)
        form_data.append('adjustment_amount', formData.adjustment_amount)
        await ApiPut(`disbursement/advance-payout-status/${selectedId}`, form_data).then((res: any) => {

        }).catch((error: any) => {
            toast.error("Something went wrong!", {
                position: "top-right",
                theme: "colored"
            })
        })
    }

    const handleEditData = (row: any) => {
        const { id,
            Borrower_Name,
            Loan_Amount,
            FinancerName,
            ProductId,
            Loan_Account_Number,
            // Bank_Name,
            // Product,
            // Disburesement_Month,
            // Location,
            // Contact_Person,
            // Mobile,
            // DSA,
            // Payout_dsa,
            Gross_Payout,
            Less_TDS,
            Add_GST,
            Adjustment,
            Net_Amount_Payable,
            // Connector_name,
            Payout_Connectore,
            Gross_Payout_Amount,
            Less_TDS_Connector,
            Add_GST_Connector,
            Net_Amount_Payable_Connector,
            // Payment_Status,
            // Billing_Status,
            // Payment_Date,
            Disburtial_Conformation,
            Loan_Amount_Bank,
            Payment_Bank,
            Gross_Payout_Bank,
            Less_TDS_Bank,
            Net_Amount_Receivable,
            Gross_Margin_Booked,
            // Mismatch,
            L1_Up,
            L2_Up,
            Net_Margin,
            // is_visible_icon,
         } = row

            


        setDashboardFormData({
            id, 
            borrower_name: Borrower_Name, 
            loan_amount: Loan_Amount, 
            financer_name: FinancerName, 
            product: ProductId,
            lan_no: Loan_Account_Number,
            // bank_name: Bank_Name,
            // month_of_disbursement: Disburesement_Month,
            // pincode: Location,
            // contact_person_name: Contact_Person,
            // mobile: Mobile,
            gross_payout: Gross_Payout,
            tds_amount: Less_TDS,
            gst_amount: Add_GST == '-' ? '' : Add_GST,
            adjustment_amount: Adjustment == '-' ? '' : Adjustment,
            net_amount_payable: Net_Amount_Payable,
            // connector_name: Connector_name,
            connector_payout: Payout_Connectore,
            connectot_gross_payout: Gross_Payout_Amount,
            connector_tds: Less_TDS_Connector,
            connector_gst: Add_GST_Connector,
            connector_net_amount_payable: Net_Amount_Payable_Connector,
            // payment_status: Payment_Status,
            // billing_status: Billing_Status,
            // payment_date: Payment_Date,
            disburtial_conformation: Disburtial_Conformation == '-' ? '' : Disburtial_Conformation,
            csv_loan_amount: Loan_Amount_Bank == '-' ? '' : Loan_Amount_Bank,
            csv_payout: Payment_Bank == '-' ? '' : Payment_Bank,
            csv_gross_payout: Gross_Payout_Bank == '-' ? '' : Gross_Payout_Bank,
            csv_tds_amount: Less_TDS_Bank == '-' ? '' : Less_TDS_Bank,
            csv_net_amount: Net_Amount_Receivable == '-' ? '' : Net_Amount_Receivable,
            gross_margin_booked: Gross_Margin_Booked == '-' ? '' : Gross_Margin_Booked,
            // Mismatch: Mismatch,
            l1_up_expense: L1_Up == '-' ? '' : L1_Up,
            l2_up_expense: L2_Up == '-' ? '' : L2_Up,
            balance_amount: Net_Margin == '-' ? '' : Net_Margin ,
        })
        setAddOpen(true)
    }

    const editDashboardFormData = async () => {
        const { id, ...payload } = dashboardFormData
        await ApiPut(`disbursement/admin/${id}`, dashboardFormData)
            .then((res: any) => {
                setDashboardFormData({});
                getDashboardData();
                setAddOpen(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (<div className="overflow-x-hidden">

        {/* <Sidebar />
        <Header /> */}

        {/* START::MAIN CONTENT */}
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

            {/* START::WIDGET */}
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4">
                <div className="dashboard-widget">
                    <h4 className="text-2xl text-[#CC99C9]">{userPermission(user, PERMISSION_TYPE.CONNECTOR) ? 0 : countData?.no_of_case_disbured}</h4>
                    <p className="text-xs text-light-gray">No. of Case Disbured</p>
                </div>
                <div className="dashboard-widget">
                    <h4 className="text-2xl text-[#9EC1CF]">{userPermission(user, PERMISSION_TYPE.CONNECTOR) ? 0 : countData?.disbursed_loan_amount}</h4>
                    <p className="text-xs text-light-gray">Disbursed Loan Amount</p>
                </div>
                <div className="dashboard-widget">
                    <h4 className="text-2xl text-[#9EE09E]">{userPermission(user, PERMISSION_TYPE.CONNECTOR) ? 0 : countData?.no_of_pending_cases}</h4>
                    <p className="text-xs text-light-gray">No. of Pending Case</p>
                </div>
                <div className="dashboard-widget">
                    <h4 className="text-2xl text-[#D5D538]">{userPermission(user, PERMISSION_TYPE.CONNECTOR) ? 0 : countData?.pending_loan_amount}</h4>
                    <p className="text-xs text-light-gray">Pending Loan Amount</p>
                </div>
                <div className="dashboard-widget">
                    <h4 className="text-2xl text-[#FEB144]">{userPermission(user, PERMISSION_TYPE.CONNECTOR) ? 0 : countData?.payment_Received}</h4>
                    <p className="text-xs text-light-gray">Payment Received</p>
                </div>
                <div className="dashboard-widget">
                    <h4 className="text-2xl text-[#FF6663]">{userPermission(user, PERMISSION_TYPE.CONNECTOR) ? 0 : countData?.pending_payment}</h4>
                    <p className="text-xs text-light-gray">Pending Payment</p>
                </div>
            </div>
            {/* END::WIDGET */}

            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
                <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                    <h1 className="text-[22px] text-light-gray font-semibold">{permission[PERMISSION_TYPE.ADMIN] ? "Master MIS" : "Dashboard"}</h1>

                    {/* 
                            if(tab active) 
                                Add class "bg-white rounded-[18px] text-primary"
                            else 
                                Remove class "bg-white rounded-[18px] text-primary"
                        */}
                    {!permission[PERMISSION_TYPE.ADMIN] &&
                        <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                            <li>
                                <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "pending" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("pending")}>Payment Pending</button>
                            </li>
                            <li>
                                <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "resubmition" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("resubmition")}>Re-Submission Case</button>
                            </li>
                            <li>
                                <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "completed" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("completed")}>Payment Completed</button>
                            </li>
                        </ul>
                    }
                </div>
                {/* END::DASHBOARD HEADER */}

                {/* START::EXPORT */}
                <ul className="flex space-x-[18px]">
                    {permission[PERMISSION_TYPE.ADMIN] &&
                        <li>
                            <input
                                id="fileChoosen"
                                type="file"
                                className="hidden"
                                onChange={(e: any) => {
                                    if (!e.target.files || e.target.files.length === 0) {
                                        window.location.reload();
                                        return;
                                    }
                                    setSelectedFile(e.target.files[0]);
                                }}
                                onClick={(event: any) => {
                                    event.target.value = null
                                }}
                            />
                            <label htmlFor="fileChoosen" className="flex items-center max-w-[200px] space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibolds">
                                Choose File
                            </label>
                        </li>
                    }
                    <li>
                        <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                            <img src="assets/pdf.svg" alt="" />
                            <span>PDF</span>
                        </button>
                    </li>
                    <li>
                        <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                            <img src="assets/excel.svg" alt="" />
                            <span>EXCEL</span>
                        </button>
                    </li>
                </ul>
                {/* ? activeTab === "completed" ? columnusers : columnuser : ''? */}
                {/* END::EXPORT */}
            </div>
            <TableComponent
                rows={permission[PERMISSION_TYPE.ADMIN] ? dashboardData : dashboard}
                columns={permission[PERMISSION_TYPE.ADMIN] ? columns : columnuser ? activeTab === "resubmition" ? columnuser2 : columnuser ? activeTab === "completed" ? columnusers : columnuser : '' : ''}
                handlePageData={handlePageData}
                handlePage={handlePage}
                state={state}
                renderColumn={(column: any, index: number) => {
                    if (index == 0) {
                        return (
                            <TableCell align="center"
                                className="!bg-gray-200"
                                key={column.id}
                                style={{
                                    background: "gray",
                                    position: 'sticky',
                                    left: 0,
                                    zIndex: 99,
                                }} width="150px"
                            >
                                {column.label}
                            </TableCell>
                        )
                    }
                    if (index == 1) {
                        return (
                            <TableCell align="center"
                                className="!bg-gray-200"
                                key={column.id}
                                style={{
                                    background: "gray",
                                    position: 'sticky',
                                    left: "150px",
                                    zIndex: 99,
                                }} width="150px"
                            >
                                {column.label}
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell align="center"
                            className="!bg-gray-200"
                            key={column.id}
                            style={{ background: "gray" }}
                        >
                            {column.label}
                        </TableCell>
                    )
                }}
                renderRow={(row: any, index: number) => {

                    return (
                        <>
                            {permission[PERMISSION_TYPE.ADMIN] ?
                                <TableRow hover role="checkbox" style={{
                                    background: 'white',
                                }} tabIndex={-1} key={row.code}>
                                    <TableCell
                                        style={{
                                            position: 'sticky',
                                            left: 0,
                                            background: 'white',
                                            zIndex: 99,
                                        }} width="150px" className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.SRNO}
                                    </TableCell>
                                    <TableCell style={{
                                        position: 'sticky',
                                        left: "150px",
                                        background: 'white',
                                        zIndex: 99,
                                    }} className="min-w-[200px]" align="center" component="th" scope="row">
                                        <span className='flex gap-2 items-center justify-center'>
                                            {row?.is_visible_icon && <img className='w-[10px]' src="assets/rupees-blue.svg" alt="" />}{row?.Borrower_Name}
                                        </span>
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Loan_Account_Number}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Bank_Name}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Product}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Disburesement_Month}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {numberFormat(row.Loan_Amount)}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Location}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Contact_Person}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Mobile}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.DSA}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Payout_dsa}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Gross_Payout}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Adjustment}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Less_TDS}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Add_GST}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Net_Amount_Payable}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Connector_name}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Payout_Connectore}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Gross_Payout_Amount}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Less_TDS_Connector}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Add_GST_Connector}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Net_Amount_Payable_Connector}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Payment_Status}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Billing_Status}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Payment_Date}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        <>
                                            {row.Disburtial_Conformation == "view" ?

                                                // <span className="relative">
                                                //     <span className='flex items-center justify-center space-x-2'
                                                //         onClick={(e) => {
                                                //             let tempClose: boolean[] = [...termsOpen];
                                                //             tempClose = tempClose.map(() => { return false })
                                                //             setTermsOpen(tempClose)
                                                //             let temp: boolean[] = [...termsHoverOpen];
                                                //             if (!temp[index]) {
                                                //                 temp = temp.map(() => { return false })
                                                //             }
                                                //             temp[index] = !temp[index]
                                                //             setTermsHoverOpen(temp)
                                                //         }}

                                                //          >{row.Disburtial_Conformation}</span>
                                                //     {(termsOpen[index] || termsHoverOpen[index]) && (
                                                //         <ClickAwayListener onClickAway={() => {
                                                //             let temp: boolean[] = [...termsHoverOpen];
                                                //             temp = temp.map(() => { return false })
                                                //             // setTermsAdvanceHoverOpen(temp)
                                                //         }}>
                                                //             <div className="aaaaaaaaaaaaa absolute top-[calc(100%+10px)] w-[300px] right-full shadow-lg z-[777] text-[#808080]">
                                                //                 <div className='flex justify-between bg-primary py-4 px-5'>
                                                //                     <p className="text-white text-sm">Terms</p>
                                                //                 </div>
                                                //                 <div className="py-4 px-5 bg-white">

                                                //                     <>
                                                //                         <h6 className="font-semibold py-4 text-light-gray text-left">Remark</h6>
                                                //                         <div className=''>{row.remark ?? "-"}</div>
                                                //                         <h6 className="font-semibold py-4 text-light-gray text-left">Image</h6>
                                                //                         <div><img src={row.upload_proof_document ?? "-"} className="w-full h-full object-center object-cover lg:w-full lg:h-full" /></div>

                                                //                     </>

                                                //                 </div>
                                                //             </div>


                                                //         </ClickAwayListener>
                                                //     )}
                                                // </span >


                                                <ul className="flex space-x-2 justify-center">
                                                    <li>
                                                        <button onClick={(e) => {
                                                            setSelectedId(row.id)
                                                            setViewData({ ...viewData, remark: row?.remark, upload_proof_document: row?.upload_proof_document })
                                                            setOpenView(true)
                                                        }} className="underline font-medium text-blue-500">{row.Disburtial_Conformation}</button>
                                                    </li>
                                                </ul>
                                                :
                                                <>{row.Disburtial_Conformation}</>
                                            }
                                        </>
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Loan_Amount_Bank}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Payment_Bank}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Gross_Payout_Bank}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Less_TDS_Bank}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Net_Amount_Receivable}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Gross_Margin_Booked}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Mismatch}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.L1_Up}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.L2_Up}
                                    </TableCell>
                                    <TableCell className="min-w-[200px]" align="center" component="th" scope="row">
                                        {row.Net_Margin}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row" className="cursor-pointer">
                                        <ul className="flex space-x-2 justify-center">
                                            <li>
                                                <Tooltip title="Edit Data">
                                                    <img className='mx-auto' onClick={() => {
                                                        // setSelectedId(row.id)
                                                        handleEditData(row)
                                                    }} src="assets/edit-connector.svg" alt="" />
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.SRNO}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <span className='flex gap-2 items-center justify-center'>
                                            {row?.is_visible_icon && <img className='w-[10px]' src="assets/rupees-blue.svg" alt="" />}{row?.Case_Name}
                                        </span>
                                    </TableCell>
                                    {activeTab === "resubmition" &&
                                        <TableCell align="center" component="th" scope="row">
                                            {row?.Billing_Status}
                                        </TableCell>
                                    }
                                    <TableCell align="center" component="th" scope="row">
                                        {numberFormat(row?.Loan_Amount)}
                                    </TableCell>

                                    <TableCell align="center" component="th" scope="row">
                                        {row?.Bank_Name}
                                    </TableCell>

                                    <TableCell align="center" component="th" scope="row">
                                        {row?.Product}
                                    </TableCell>

                                    <TableCell align="center" component="th" scope="row">
                                        {row?.Gross_Payout}
                                    </TableCell>

                                    {activeTab === "pending" ?
                                        <TableCell align="center" component="th" scope="row">
                                            <ul className="flex space-x-2 justify-center">
                                                <li>
                                                    <Tooltip title="Action">
                                                        <button onClick={(e) => {
                                                            setSelectedId(row.id)
                                                            setItem({ ...item, product: row?.Product, gross_payout: row?.Gross_Payout })
                                                            setOpen(true)
                                                        }}><img src="assets/money-gray.svg" alt="" /></button>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </TableCell>
                                        :
                                        activeTab === "completed" &&
                                        <TableCell align="center" component="th" scope="row">
                                            {row?.Utr_no}
                                        </TableCell>
                                    }
                                    {/* {activeTab === "completed" &&
                                        <TableCell align="center" component="th" scope="row">
                                            {row?.Utr_no}
                                        </TableCell>
                                    } */}
                                </TableRow>
                            }

                            <div className={`tw-modal ${addOpen ? 'flex' : 'hidden'} !items-start`}>
                                <div className="tw-modal-content">
                                    <div className="tw-modal-title">
                                        <div>Dashboard Data</div>
                                        <button onClick={() => setAddOpen(false)}>
                                            <img src="assets/close-outline.svg" alt="" />
                                        </button>
                                    </div>

                                    <div className='tw-modal-body'>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                                            <div className="space-y-1">
                                                <label htmlFor="borrower_name" className={`input-label `}>Borrower Name</label>
                                                <div id="borrower_name" className={`input-wrap `}>
                                                    <img src="assets/user.svg" alt="" />
                                                    <input type="text" name="borrower_name" value={dashboardFormData?.borrower_name} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, borrower_name: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Bank Name" />
                                                </div>
                                            </div>
                                            <div className="space-y-1">
                                                <label htmlFor="loan_amount" className={`input-label `}>Loan Amount</label>
                                                <div id="loan_amount" className={`input-wrap `}>
                                                    <img src="assets/user.svg" alt="" />
                                                    <input type="text" name="loan_amount" value={dashboardFormData?.loan_amount} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, loan_amount: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Bank Name" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="lan_no" className={`input-label `}>Loan Account Number</label>
                                                <div id="lan_no" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="text" name="lan_no" value={dashboardFormData?.lan_no} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, lan_no: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Loan Account Number" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="gross_payout" className={`input-label `}>Gross Payout in Amount(DSA)</label>
                                                <div id="gross_payout" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="gross_payout" value={dashboardFormData?.gross_payout} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, gross_payout: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Gross Payout in Amount(DSA)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="tds_amount" className={`input-label `}>Less:TDS @5%(DSA)</label>
                                                <div id="tds_amount" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="tds_amount" value={dashboardFormData?.tds_amount} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, tds_amount: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Less:TDS @5%(DSA)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="gst_amount" className={`input-label `}>Add:GST @18%(DSA)</label>
                                                <div id="gst_amount" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="gst_amount" value={dashboardFormData?.gst_amount} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, gst_amount: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Add:GST @18%(DSA)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="net_amount_payable" className={`input-label `}>Net Amount Payable (DSA)</label>
                                                <div id="net_amount_payable" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="net_amount_payable" value={dashboardFormData?.net_amount_payable} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, net_amount_payable: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Net Amount Payable (DSA)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="connectot_gross_payout" className={`input-label `}>Gross Payout in Amount (Connector)</label>
                                                <div id="connectot_gross_payout" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="connectot_gross_payout" value={dashboardFormData?.connectot_gross_payout} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, connectot_gross_payout: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Gross Payout in Amount (Connector)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="connector_tds" className={`input-label `}>Less:TDS @5%(Connector)</label>
                                                <div id="connector_tds" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="connector_tds" value={dashboardFormData?.connector_tds} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, connector_tds: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Less:TDS @5%(Connector)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="connector_gst" className={`input-label `}>Add:GST @18%(Connector)</label>
                                                <div id="connector_gst" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="connector_gst" value={dashboardFormData?.connector_gst} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, connector_gst: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Add:GST @18%(Connector)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="connector_net_amount_payable" className={`input-label `}>Net Amount Payable (Connector)</label>
                                                <div id="connector_net_amount_payable" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="connector_net_amount_payable" value={dashboardFormData?.connector_net_amount_payable} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, connector_net_amount_payable: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Net Amount Payable (Connector)" />
                                                </div>
                                            </div>


                                            <div className="space-y-1">
                                                <label htmlFor="csv_loan_amount" className={`input-label `}>Loan Amount(Bank)</label>
                                                <div id="csv_loan_amount" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="csv_loan_amount" value={dashboardFormData?.csv_loan_amount} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, csv_loan_amount: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Loan Amount(Bank)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="csv_payout" className={`input-label `}>Payout in %(Bank)</label>
                                                <div id="csv_payout" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="csv_payout" value={dashboardFormData?.csv_payout} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, csv_payout: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Payout in %(Bank)" />
                                                </div>
                                            </div>


                                            <div className="space-y-1">
                                                <label htmlFor="csv_gross_payout" className={`input-label `}>Gross Payout in Amount(Bank)</label>
                                                <div id="csv_gross_payout" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="csv_gross_payout" value={dashboardFormData?.csv_gross_payout} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, csv_gross_payout: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Gross Payout in Amount(Bank)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="csv_tds_amount" className={`input-label `}>Less:TDS @5%(Bank)</label>
                                                <div id="csv_tds_amount" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="csv_tds_amount" value={dashboardFormData?.csv_tds_amount} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, csv_tds_amount: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Less:TDS @5%(Bank)" />
                                                </div>
                                            </div>
                                            <div className="space-y-1">
                                                <label htmlFor="csv_net_amount" className={`input-label `}>Net Amount Receivable (Bank)</label>
                                                <div id="csv_net_amount" className={`input-wrap `}>
                                                    {/* <img src="assets/user.svg" alt="" /> */}
                                                    <input type="number" name="csv_net_amount" value={dashboardFormData?.csv_net_amount} onChange={(e: any) => {
                                                        setDashboardFormData({ ...dashboardFormData, csv_net_amount: e.target.value });
                                                    }} className="w-full text-sm" placeholder="Enter Net Amount Receivable (Bank)" />
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <label htmlFor="financer_name" className={`input-label `}>Financer Name</label>
                                                <div id="financer_name" className={`input-wrap `}>
                                                    <img src="assets/bank.svg" alt="" />
                                                    <select name="financer_name" value={dashboardFormData.financer_name} onChange={(e) => setDashboardFormData({ ...dashboardFormData, financer_name: e.target.value, financer_name_error: null, product: "" })} className="w-full text-sm text-[#808080]">
                                                        <option value="" selected>Select Bank/NBFC Name</option>
                                                        {Object.keys(bankData)?.map((bank: any, id: any) => (
                                                            <option value={bank}>{bank}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="space-y-1">
                                                <label htmlFor="product" className={`input-label `}>Product</label>
                                                <div id="product" className={`input-wrap `}>
                                                    <img src="assets/product.svg" alt="" />
                                                    <select name="product" value={dashboardFormData.product} onChange={(e) => setDashboardFormData({ ...dashboardFormData, product: e.target.value, product_error: null })} className="w-full text-sm text-[#808080]">
                                                        <option value="" selected>Select Product</option>
                                                        {bankData?.[dashboardFormData.financer_name]?.map((item: any) => (
                                                            <option value={item.id}>{item.product}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='tw-modal-footer'>
                                        <button className='tw-modal-footer-button bg-secondary text-white' onClick={editDashboardFormData}>Save</button>
                                        <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => setAddOpen(false)}>Cancel</button>
                                    </div>
                                </div>
                            </div>

                            {/* Start::Connector List Modal */}
                            <div className={`tw-modal ${open ? 'flex' : 'hidden'} !items-center`}>
                                <div className="tw-modal-content !max-w-[500px]">
                                    <div className="tw-modal-title py-6 justify-end">
                                        <button onClick={() => setOpen(false)}>
                                            <img src="assets/close-outline.svg" alt="" />
                                        </button>
                                    </div>
                                    <div className='tw-modal-body leading-[24px] py-8'>
                                        <span className="font-medium text-black">Please Note : </span>Advance payout grid for this bank & product is {item.product}
                                        .Your Gross Payout will be Rs. {item.gross_payout == "-" ? 0 : item.gross_payout}. You cannot opt for NORMAL GRID
                                        after requesting advance. Please confirm?
                                    </div>

                                    <div className='tw-modal-footer'>
                                        <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => setOpen(false)}>No</button>
                                        <button className='tw-modal-footer-button bg-secondary text-white' onClick={(e) => {
                                            actionYes()
                                        }}>Yes</button>
                                    </div>
                                </div>
                            </div>
                            {/* End::Connector List Modal */}


                            {/* Start::Connector List Modal */}
                            <div className={`tw-modals ${openView ? 'flex' : 'hidden'} !items-center`}>
                                <div className="tw-modal-content !max-w-[500px]">
                                    <div className="tw-modal-title py-6 justify-end">
                                        <button onClick={() => setOpenView(false)}>
                                            <img src="assets/close-outline.svg" alt="" />
                                        </button>
                                    </div>
                                    <div className='tw-modal-body leading-[24px] py-8'>
                                        <span className="font-medium text-black">Remark: </span>{viewData.remark ?? "-"}
                                    </div>
                                    <div className='tw-modal-body leading-[24px] py-8'>
                                        <span className="font-medium text-black">Image: </span><img src={viewData.upload_proof_document ?? "-"} />
                                    </div>
                                </div>
                            </div>
                            {/* End::Connector List Modal */}
                        </>
                    )
                }}
            />

            <div className={`tw-modal ${documentPopup ? 'flex' : 'hidden'} !items-start`}>
                <div className="tw-modal-content">
                    <div className="tw-modal-title">
                        <div>Document</div>
                        <button onClick={() => setDocumentPopup(false)}>
                            <img src="assets/close-outline.svg" alt="" />
                        </button>
                    </div>

                    <div className='tw-modal-body'>
                        <div className="space-y-1">
                            <p className="input-label">Cheque</p>
                            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                                <div className='w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4'>
                                    <div className="w-full space-y-1">
                                        <div id="remark" className="input-wrap w-full">
                                            <input type="text" className="block w-full text-sm text-[#808080]" placeholder="Remark" value={formData.remark} onChange={(e: any) => {
                                                setFormData({ ...formData, remark: e.target.value })
                                            }} />
                                        </div>
                                    </div>
                                    <div className="w-full space-y-1">
                                        <div id="adjustment_amount" className="input-wrap w-full">
                                            <input type="number" value={formData.adjustment_amount} onChange={(e: any) => {
                                                setFormData({ ...formData, adjustment_amount: e.target.value })
                                            }} className="block w-full text-sm text-[#808080]" placeholder="Adjustment Amount" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0'>
                                <div className="w-full space-y-1">
                                    <div id="email" className="input-wrap w-full">
                                        <label htmlFor="cheque_image" className="block w-full text-sm text-[#808080]">{formData.proof_document_link}</label>
                                    </div>
                                    <input type="file" id="cheque_image" onChange={(e: any) => {
                                        setFormData({ ...formData, proof_document: e.target.files[0], proof_document_link: e.target.files?.[0]?.name, proof_document_image: URL.createObjectURL(e.target.files[0]) })
                                    }} className="text-sm hidden" />
                                </div>
                                <button className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary' onClick={() => setDocumentPreview(!documentPreview)}>Preview</button>
                            </div>
                            {(documentPreview && formData.proof_document_image != "") && <img src={formData.proof_document_image} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                            }
                        </div>
                    </div>

                    <div className='tw-modal-footer'>
                        <button className='tw-modal-footer-button bg-secondary text-white' onClick={() => {
                            actionAdminYes();
                        }}>Submit</button>
                        <button className='tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                            setDocumentPopup(false)
                        }}>Cancle</button>
                    </div>
                </div>
            </div>

        </div>
        {/* </main> */}
        {/* END::MAIN CONTENT */}
    </div>)
}

export default Dashboard;
