import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authReducer'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'

const persistConfig = {
	key: 'root',
	storage,
}

const rootReducer = combineReducers({
	authUser: authReducer,
})

//redux persisted use
const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = configureStore({
	reducer: persistedReducer
}
)
export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch