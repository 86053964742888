import { TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TableComponent from '../../components/Tables';
import { STATE } from '../../constants';
import { ApiGet } from '../../helper/API/ApiData';

function SmList() {
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [loader, setLoader] = useState(true);
  const [smList, setSmList] = useState([]);


  const columns = [
    { id: 'SRNO', label: 'SR.NO.' },
    { id: 'Financer', label: 'Financer' },
    { id: 'product', label: 'Product' },
    { id: 'SMName', label: 'SM NAME' },
    { id: 'SMContact', label: 'SM Contact No.' },
  ];


  const handlePageData = (limit: number, page: number = 1) => {
    setState({
      ...state,
      limit,
      page
    })
  }

  const handlePage = (page: number) => {
    setState({
      ...state,
      page
    })
  }

  const getSmList = async () => {
    await ApiGet(`loginCodeList/sm-list?page=${state.page}&limit=${state.limit}`)
      .then((res: any) => {
        let temp = res.data.result.docs.map((item: any, index: number) => {
          return {
            SRNO: (((state.page - 1) * state.limit) + index + 1),
            Financer: item.bank_name,
            product: item.product,
            SMName: item.sm_name,
            Email: item.email,
            SMContact: item.phone,
          }
        })
        setSmList(temp)
        setState({
          ...state,
          totalItems: res.data.result.total
        })
      })
  }

  useEffect(() => {
    Promise.all([
      getSmList()
    ]).then(() => {
      setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
      setLoader(false)
    })
  }, [state.page, state.limit])
  return (<div className="overflow-x-hidden">

    {/* <Sidebar />
    <Header /> */}
    {loader ?
      <>
        {/* START::OVERLAY */}
        <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
          <img src="assets/logo.svg" />
        </div>
        {/* END::OVERLAY */}
      </>
      :
      <>
        {/* START::MAIN CONTENT */}
        {/* <main className="lg:pl-[280px] pt-[92px]"> */}
        <div className="p-4 sm:p-9 space-y-5">

          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
              <h1 className="text-[22px] text-light-gray font-semibold">SM List</h1>
            </div>

            {/* START::EXPORT */}
            <ul className="flex space-x-[18px]">
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/pdf.svg" alt="" />
                  <span>PDF</span>
                </button>
              </li>
              <li>
                <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold">
                  <img src="assets/excel.svg" alt="" />
                  <span>EXCEL</span>
                </button>
              </li>
            </ul>
            {/* END::EXPORT */}
          </div>
          {/* END::DASHBOARD HEADER */}
          <TableComponent
            rows={smList}
            columns={columns}
            handlePageData={handlePageData}
            handlePage={handlePage}
            state={state}
            renderColumn={(column: any) => {
              return (
                <TableCell align="center"
                  className="!bg-gray-200"
                  key={column.id}
                  style={{ background: "gray" }}
                >
                  {column.label}
                </TableCell>
              )
            }}
            renderRow={(row: any) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell align="center" component="th" scope="row">
                    {row.SRNO}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.Financer}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.product}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.SMName}<br />
                    {row.Email}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.SMContact}
                  </TableCell>
                </TableRow>
              )
            }}
          />
        </div>
        {/* </main> */}
        {/* END::MAIN CONTENT */}
      </>
    }
  </div>)
}

export default SmList