import { useState } from "react";
import { useNavigate } from "react-router-dom";
import STORAGEKEY from "../config/APP/app.config";
import { ApiPost } from "../helper/API/ApiData";
import AuthStorage from "../helper/AuthStorage";
import Register from "./register";

import { useDispatch } from 'react-redux'
import { Login_User } from '../redux/reducers/authReducer'

interface loginFormState {
    password: string;
    passwordConform: string;
    password_error: string,
    confirmpassword_error: string,
}

function ForgotPasswordLink() {

    let searchParams = new URLSearchParams(window.location.search)
    let param = searchParams.get('token')

    let navigate = useNavigate();
    const passwordLink: loginFormState = {
        password: "",
        passwordConform: "",
        password_error: "",
        confirmpassword_error: "",
    };

    const [loginData, setLoginData] = useState<any>(passwordLink);
    const [error, setError] = useState("");
    const [hide, setHide] = useState<any>(true);
    const [hides, setHides] = useState<any>(true);


    const handleChange = (e: any) => {

        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        })
    }

    const forgotpassword = () => {
        let error = { ...loginData };
        let passwordPatten = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (loginData.password == "") {
            error = { ...error, password_error: "Please enter a password" };
        }
        if (loginData.password != "" && !passwordPatten.test(loginData.password)) {
            error = { ...error, password_error: "Please enter Alphabet & Symbol" };
        }
        if (loginData.passwordConform == "") {
            error = { ...error, confirmpassword_error: "Please enter a confirm password" };
        }
        if (loginData.passwordConform != "" && (loginData.password != loginData.passwordConform)) {
            error = { ...error, confirmpassword_error: "confirm password not match" };
        }
        setLoginData(error)
        if (error.password_error == null &&
            error.confirmpassword_error == null) {
            const body = {
                password: loginData.password,
                token: param
            }
            ApiPost('user/forgot-password', body)
                .then((res: any) => {
                    if (res?.data?.message == "Password Changed") {
                        navigate("/login", { replace: true });
                    }
                })
        }
    }

    return (
        <>
            <section className="auth-page-section">

                {/* START::DIALOG */}
                <div className="popup-box max-w-[500px]">

                    {/* START::HEADER */}
                    <div className="flex items-center justify-between">
                        <h1 className="text-[22px] font-semibold ">Forgot Password</h1>
                        <button className="w-[30px] h-[30px]">
                            <img src="assets/close.svg" alt="" />
                        </button>
                    </div>
                    {/* END::HEADER */}

                    {/* START::FORM */}
                    <div className="space-y-[22px]">
                        <div className="space-y-1">
                            <label htmlFor="password" className="input-label">Password</label>
                            <div id="email" className="input-wrap">
                                <img src="assets/lock.svg" alt="" />
                                <input type={hides ? "password" : "text"} className="w-full text-sm" name="password" value={loginData.password} onChange={(e: any) => {
                                    setLoginData({ ...loginData, password: e.target.value, password_error: null });
                                }} placeholder="XXXXXXXXXX" />
                                <img src="assets/eyes.svg" onClick={() => setHides(!hides)} alt="" />
                            </div>
                            <p className={`${!loginData.password_error ? 'text-[12px] text-[#808080] mt-1' : 'text-red-500 text-sm'}`}>{!loginData.password_error ? "At least 8 characters, with at least 1 uppercase, 1 lowercase and 1 number" : loginData.password_error}</p>
                        </div>
                        <div className="space-y-1">
                            <label htmlFor="passwordConform" className="input-label">ConformPassword</label>
                            <div id="passwordConform" className="input-wrap">
                                <img src="assets/lock.svg" alt="" />
                                <input type={hide ? "password" : "text"} className="w-full text-sm" placeholder="XXXXXXXXXX" name="passwordConform" value={loginData.passwordConform} onChange={(e: any) => {
                                    setLoginData({ ...loginData, passwordConform: e.target.value, confirmpassword_error: null });
                                }} />
                                <img src="assets/eyes.svg" onClick={() => setHide(!hide)} alt="" />
                            </div>
                            {/* {error} */}
                            <p className="text-red-500 text-sm">{loginData.confirmpassword_error}</p>
                        </div>
                    </div>
                    <button className="w-full bg-[#008BD0] text-white p-[18px] font-semibold text-sm rounded-lg" onClick={forgotpassword}>Sign in</button>

                    {/* END::FORM */}

                </div>
                {/* END::DIALOG */}

            </section >

        </>
    )
}

export default ForgotPasswordLink